const BuildUserProps = (user, currentPractice, openKeys) => {
  const practiceList = Object.values(user.practices).map(obj => obj.practiceId);
  const practiceListLength = practiceList.length >= 1 || user.isGlobalAdmin || user.isPracticeAdmin;
  const showPracticeList = openKeys.includes('practices', 'users', 'portals');

  /* push access to common pages */
  practiceList.push('');
  practiceList.push('login');
  practiceList.push('404');

  /* push practices menu item if more than 1 practice */
  // if (practiceListLength) {
  //   practiceList.push('practices');
  // }

  /* if global admin, push users menu item for admin */
  if (user.isGlobalAdmin || user.isPortalAdmin) {
    practiceList.push('users');
  }

  /* determine if user can access practice, currentPractice === '' bypasses check directly to router */

  let isPracticeMember = false
  if(user.isGlobalAdmin || user.isPortalAdmin ) isPracticeMember = true
  if(currentPractice) {
    isPracticeMember = user.practices.some(p => p.practiceId === currentPractice)
   }

  /* get the user's role for the current practice */
  let userRole = null;
  if(user.id){
    if (user.isGlobalAdmin) {
      userRole = 1;
    } else if (user.isPortalAdmin) {
      userRole = 4;
    } else if (user.isPracticeAdmin) {
      userRole = 2;
    } else {
      userRole = 3;
    }
  }

  //  else if (['', 'login', '404'].includes(currentPractice)) {
  //   userRole = null;
  // } else if (!['', 'login', 'practices', 'users'].includes(currentPractice)) {
  //   const findUser = user.practices.find(obj => obj.practiceId === currentPractice);
  //   if (findUser) {
  //     userRole = findUser.userRole.roleId;
  //   } else {
  //     userRole = null;
  //   }
  // } else {
  //   userRole = null;
  // }

  return {
    isPracticeMember,
    practiceListLength,
    showPracticeList,
    userRole,
  };
};

export default BuildUserProps;
