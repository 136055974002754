import React, {useContext, useEffect, useState} from 'react';
import { useQuery } from 'react-apollo';
import {Badge, Button, Form, Icon, Input, Skeleton, Spin, Table} from 'antd';
//import {Table} from '../../../elements';
import {practiceConfig} from '../../../config';
import {Titlebar} from '../../../components';
import GenerateBadge from '../../../helpers/generateBadge';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import CustomButton from "../../../components/commons/CustomButton";
import _ from 'lodash';
import gql from 'graphql-tag';
import {AccountContext} from "../../../context/AccountContext";

const FormItem = Form.Item;
const InputSearch = Input.Search;

const { Search } = Input;

function PracticesList(props){
    const[state,setState] = useState({limit:practiceConfig.rowsPerPage,
              loading:false,order:'ASC',orderColumn:"",page:1,search:"",
              totalCount:null,sortDirection:''})

  const accountContext = useContext(AccountContext);

  let portalId = _.get(accountContext,'profile.portals[0].id',null)

  useEffect(reCall, [state.search,state.page]);

  const { loading, data, refetch } = useQuery(
      gql`
			query (
              $limit: Int
              $offset: Int
              $order: String
              $orderColumn: String
              $search: String
              $portalId :Int
            ){
              getAdminPractices(
                limit: $limit
                offset: $offset
                order: $order
                orderColumn: $orderColumn
                search: $search
                portalId: $portalId
              ){
                practices {
                  id
                  name
                  practiceId
                  portalId
                  company
                  cslug
                  address
                  status
                }
                pagination {
                  offset
                  totalCount
                }
              }
            }
		`,
      {
        variables: {
          page:state.page,
          offset:state.offset,
          limit:state.limit,
          order: state.order,
          orderColumn: state.orderColumn,
          search: state.search,
          portalId:portalId
        },
        fetchPolicy: 'network-only',
      }
  );


  // componentDidUpdate(prevProps){
  //   const userContext = this.context;
  //   if(this.props !== prevProps && _.get(userContext.account,'portalid[0]')){
  //
  //     this.fetchData(
  //         this.state.page,
  //         this.state.limit,
  //         this.state.order,
  //         this.state.orderColumn,
  //         this.state.search,
  //         _.get(userContext.account,'portalid[0]')
  //     );
  //   }
  //
  // }

  function handleSearch (searchParams) {

    let search = _.clone(searchParams);

    setState({...state,search:search});

  }


  function reCall() {
    refetch({
      page:state.page,
      offset:state.offset,
      limit:state.limit,
      order: state.order,
      orderColumn: state.orderColumn,
      search: state.search,
    });
  }

  function handleTableChange (pagination, filters, sorter) {
     const sorterOrder = sorter.order === 'descend' ? `DESC` : `ASC`;

    let x = (pagination.current -1) * state.limit;
     setState({
        ...state,
        offset:x,
        limit: pagination.pageSize,
        page: pagination.current,
        order: sorterOrder,
        orderColumn: sorter.field,
        [sorter.columnKey]:sorter.order
      });
    reCall();
    }

    const columns = [
      {
        dataIndex: 'name',
        sorter:true,
        title: 'Practice Name',
        sortOrder:state.name,
        render: (text,row) => {
          if([1,4,5].includes(props.gblUserRole))
            return <Button type={'link'} disabled={row.status === 'Inactive' } onClick={()=>{props.history.push(`/${row.practiceId}`);localStorage.setItem('current_practice',row.practiceId);localStorage.setItem('current_practice_id',row.id)}}>{text}</Button>
          else if([2,6].includes(props.gblUserRole))
            return text
          else
            return <></>
        }

      },
      {
        dataIndex: 'address',
        title: 'Location',
      },
      {
        dataIndex: 'status',
        render: text => <Badge status={GenerateBadge(text)} text={text} />,
        sortOrder:state.sortDirection,
        title: 'Status',
      },
      {
        dataIndex: 'company',
        title: 'Company',
        sorter: true,
        sortOrder:state.company,
        render: (text,row) =>
        {
          if([1,4,5].includes(props.gblUserRole))
            return <Button type={'link'}  onClick={()=>props.history.push(`/companies/${row.cslug}`)}>{row.company}</Button>
          else if([2,6].includes(props.gblUserRole))
            return row.company
          else
            return <></>
        }
      },
      {
        className: 'action-column',
        render: practice => {
          if([1,4,5].includes(props.gblUserRole))
            return <Button type={'link'} icon={'edit'} onClick={()=>props.history.push(`/practices/edit/${practice.portalId}/${practice.id}`)}/>
          else if([2,6].includes(props.gblUserRole))
            return <Button type={'link'} icon={'edit'} onClick={()=>props.history.push(`/${props.currentPractice}/practices/edit/${practice.portalId}/${practice.id}`)}/>
          else
            return <></>
        },
      },
    ];




    let practices = _.get(data,'getAdminPractices.practices',[]);
    let tableCount = _.get(data,'getAdminPractices.pagination.totalCount',0)



    let  tablePagination = {
        current: state.page,
        defaultPageSize: practiceConfig.rowsPerPage,
        pageSize: state.limit,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: tableCount,
      };


    const countStart = ((state.page - 1) * state.limit) + 1;
    const countEnd = state.page * state.limit > tableCount ? tableCount : state.page * state.limit;
    const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : 'No Records';

    return (
      <div>
        <Titlebar
          currentPractice={props.currentPractice}
          displayText="Practices"
        />
        <div className="button-bar-wrapper">
          <CustomButton
              rights={['superAdmin','createPractice']}
              icon="plus"
              href={`/practices/add/${localStorage.getItem('current_company_id') || ""}`}
              text="Add New"
              type={'primary'}
              disabled={props.location.pathname === '/practices'}
          >Add New</CustomButton>
          {/*<ButtonBar*/}
            {/*icon="plus"*/}
            {/*link="/practices/add"*/}
            {/*text="Add New"*/}
          {/*/>*/}
        </div>
        <LayoutContentWrapper>
          <div className="display-bar">
            {display}
          </div>
          <div className="search-bar-wrapper">
            <div className="search-bar-inner">
              <div className="search-bar">
                <Form>
                  <FormItem className="oka-list-search">
                    <Search
                        autoComplete="off"
                        // className={state.search ? 'has-search' : null}
                        enterButton
                        onSearch={val=>handleSearch(val)}
                        placeholder="Search for a practice..."
                        allowClear
                        initialValue={state.search}
                        />
                    {/*{getFieldDecorator('search',{*/}
                      {/*initialValue:state.search*/}
                    {/*})(*/}
                      {/*<Search*/}
                        {/*autoComplete="off"*/}
                        {/*// className={state.search ? 'has-search' : null}*/}
                        {/*enterButton*/}
                        {/*onSearch={val=>handleSearch(val)}*/}
                        {/*placeholder="Search for a practice..."*/}
                        {/*allowClear*/}
                        {/*// suffix={state.search*/}
                        {/*//   ? ( <Icon key="icon" onClick={() => handleSearch({ type: 'clear' })} style={{ color: '#bebebe' }} type="close-circle-o" />)*/}
                        {/*//   : <span />*/}
                        {/*// }*/}
                      {/*/>)}*/}
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
          <LayoutContent>
            <Skeleton loading={loading} active avatar>
              {loading ? null :
                  <Table
                      columns={columns}
                      loading={loading}
                      onChange={handleTableChange}
                      dataSource={practices}
                      rowKey={record => record.id + Math.floor(Math.random() * 10)}
                      scroll={{x: columns.length * 150}}
                      pagination={tablePagination}
                  />
              }
            </Skeleton>
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );

}

export default Form.create()(PracticesList);
