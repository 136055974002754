import React from 'react';
import {
  FlexibleXYPlot,
  HorizontalGridLines,
  MarkSeries,
  VerticalGridLines,
  XAxis,
} from 'react-vis';
import '../../../node_modules/react-vis/dist/style.css';
import SelfReportedMapWrapper from './index.style';

class SelfReportedMap extends React.Component {
  render() {
    const {
      data: insights,
      forPrint,
    } = this.props;

    const data = [
      {
        color: 'rgba(255,255,255,1)',
        y: insights.score.plScore >= 1
          ? (100 + insights.score.plScore) // logical
          : (100 - (insights.score.plScore * -1)), // passionate
      },
      {
        color: 'rgba(0,111,186,1)',
        y: insights.score.plScore >= 1
          ? (100 + insights.score.plScore) // logical
          : (100 - (insights.score.plScore * -1)), // passionate
      },
    ];

    data[0].x = insights.score.selfReportedScore;
    data[1].x = insights.score.riskBehaviorScore;

    return (
      <SelfReportedMapWrapper>
        <div className="chart">
          <FlexibleXYPlot
            height={507}
            margin={{ left: 10, right: 10 }}
            style={{ background: 'rgba(255,255,255,1)' }}
            {...forPrint && { width: 530 }}
            xDomain={[0, 100]}
            yDomain={[0, 200]}
          >
            <XAxis on0 />
            <HorizontalGridLines tickValues={[100]} />
            <VerticalGridLines
              animation
              style={{ stroke: 'rgba(230,230,233,1)', strokeDasharray: '4' }}
              tickValues={[insights.score.riskBehaviorScore, insights.score.selfReportedScore]}
            />
            <MarkSeries
              animation
              colorType="literal"
              data={data.map(row => ({
                color: row.color,
                x: row.x,
                y: row.y,
              }))}
              stroke="rgba(0,111,186,1)"
            />
          </FlexibleXYPlot>
          <div className="bottom-legend">
            <div>Conservative</div>
            <div>Moderately<br />Conservative</div>
            <div>Moderate</div>
            <div>Moderately<br />Aggressive</div>
            <div>Aggressive</div>
          </div>
        </div>
        <div className="bottom-wrapper">
          <div><span>Logical</span></div>
          <div><span>Passionate</span></div>
        </div>
      </SelfReportedMapWrapper>
    );
  }
}

export default SelfReportedMap;
