
import React, { useContext } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButtonsWrapper from './index.style';
import CustomButton from "../../../commons/CustomButton";
import { AccountContext } from '../../../../context/AccountContext';

function EditButtons(props) {
    const accountContext = useContext(AccountContext);
    const {account} = accountContext
    return (
        <ButtonsWrapper>
            <CustomButton
                style={{marginRight : 10}}
                rights={props.rights}
                className="form-button"
                loading={props.loading}
                onClick={props.onSubmit}
                type="primary">
                Save
            </CustomButton>
            <Link
                className="form-button"
                to={{
                    defaultActiveKey: props.defaultActiveKey,
                    pathname: props.cancelUrl,
                }}
            >
                <Button>Cancel</Button>
            </Link>
            {
                (account && 
                    <div className="form-button">
                        {(account.permissions.includes(props.deleteRights) || (account.permissions.includes('superAdmin'))) ?
                            <Button
                            onClick={props.onDelete}
                            type="danger"
                            >
                            Delete
                            </Button>
                            : null}
                    </div>
                )
            }
            
        </ButtonsWrapper>
    );
}
EditButtons.defaultProps = {
  defaultActiveKey: null,
  rights:[]
};

EditButtons.propTypes = {
  cancelUrl: PropTypes.string.isRequired,
  defaultActiveKey: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rights:PropTypes.any
};

export default EditButtons;
