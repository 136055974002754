import styled from 'styled-components';
import { palette } from 'styled-theme';

const UploadWrapper = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    height: 53px;
    margin-bottom: 0;
    margin-right: 0;
    width: 138px;

    &:hover {
      border-color: ${palette('primary', 0)};
    }

    .anticon {
      font-size: 32px;
    }

    .preview {
      height: 35px;
      width: 120px;

      .anticon {
        color: ${palette('base', 0)};
        left: 50px;
        position: absolute;
        top: 40px;
      }

      img {
        height: 35px;
        width: 120px;
      }
    }
  }
`;

export default UploadWrapper;
