import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { notification } from 'antd';
import { Redirect } from 'react-router';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import PortalsForm from '../form';

class PortalsAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect
        ? this.props.location.state.redirect
        : `/companies`,
      key: 1,
      loading: false,
      redirectTo: null,
      reset: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
    };
    //console.log('to be submittted variables: ', variables);

    this.props.mutate({ variables })
      .then(({ data }) => {
        if (data.createPortal.ok && !data.createPortal.errors) {
          this.handleSuccess(data.createPortal.portal.name, data.createPortal.portal.slug);
          return;
        }

        this.handleError(data.createPortal.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleReset() {
    this.setState({ reset: !this.state.reset });
  }

  handleSuccess(name, slug) {
    notification.success({
      message: 'Portal Created',
      description: `${name} has been successfully created.`,
    });

    if (this.state.reset === false) {
      this.setState({ redirectTo: '/companies' });
    } else {
      this.setState({
        key: this.state.key + 1,
        loading: false,
      });
    }
  }

  handleError(errors) {
    this.setState({ loading: false });
    console.log(errors,'errors')
    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {
    return (
      <div>
        <Titlebar
          displayText="Add Company"
        />
        <LayoutContentWrapper>
          <LayoutContent>
            <PortalsForm
              cancelUrl={this.state.cancelUrl}
              createAnother={this.state.reset}
              gblUserRole={this.props.gblUserRole}
              key={this.state.key}
              loading={this.state.loading}
              onCreateAnother={this.handleReset}
              onSubmit={this.handleSubmit}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={this.state.redirectTo} />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const addPortalQuery = gql`
mutation createPortal($id: Int, $name: String!, $slug: String!, $description: String,
      $status: String
      $siteLogo: String
      $siteLogoS3Token: String
      $primaryContactAddress: String
      $primaryContactZipCode: String
      $primaryContactCity: String
      $primaryContactCountry: String
      $primaryContactState: String
      $primaryContactSuite: String
      $primaryContactEmail: String
      $primaryContactFirstName: String
      $primaryContactLastName: String
      
){
  createPortal(id: $id, 
      name: $name, 
      slug: $slug, 
      description: $description
      status: $status
      siteLogo: $siteLogo
      siteLogoS3Token: $siteLogoS3Token
      primaryContactAddress: $primaryContactAddress
      primaryContactZipCode: $primaryContactZipCode
      primaryContactCity: $primaryContactCity
      primaryContactCountry: $primaryContactCountry
      primaryContactState: $primaryContactState
      primaryContactSuite: $primaryContactSuite
      primaryContactEmail: $primaryContactEmail
      primaryContactFirstName: $primaryContactFirstName
      primaryContactLastName: $primaryContactLastName
   ){
    ok
    portal {
      id
      name
      slug
      description
      status
      siteLogo
      siteLogoS3Token
      primaryContactAddress
      primaryContactZipCode
      primaryContactCity
      primaryContactCountry
      primaryContactState
      primaryContactSuite
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
    }
    errors {
      message
      path
    }
  }
}
`;

export default graphql(addPortalQuery)(PortalsAdd);
