import React from 'react';
import PropTypes from 'prop-types';
import { UploadLogo } from '../../../../components/upload';
import LogoUploadWrapper from './index.style';

class SiteLogo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      siteLogo: this.props.practice
        ? this.props.practice.siteLogo
        : null,
    };

    this.handleUploadParams = this.handleUploadParams.bind(this);
  }

  handleUploadParams(uploadParams) {
    this.setState({ siteLogo: uploadParams.blob });
    this.props.handleUploadParams(uploadParams);
  }

  render() {
    const { practice } = this.props;
    const { siteLogo } = this.state;

    return (
      <LogoUploadWrapper>
        {practice && practice.siteLogo
          ? (
            <div className="logo-upload-wrapper">
              <div className="logo-wrapper with-logo">
                <div className="title">Logo</div>
                <div className="logo-img-preview">
                  <img alt={`${practice.name}`} src={siteLogo} />
                </div>
              </div>
            </div>
          ) : (
            <div className="logo-upload-wrapper">
              <div className="logo-wrapper">
                <div className="title">Logo</div>
                <UploadLogo
                  data={practice}
                  folder="site-logos"
                  handleUploadParams={this.handleUploadParams}
                  listType="picture-card"
                  preview={siteLogo}
                />
              </div>
              <div className="logo-action">
                <span className="caption">
                  Logo must be 216px wide by 64px high.
                </span>
              </div>
            </div>
          )}
      </LogoUploadWrapper>
    );
  }
}

SiteLogo.defaultProps = {
  handleUploadParams: null,
};

SiteLogo.propTypes = {
  handleUploadParams: PropTypes.func,
};

export default SiteLogo;
