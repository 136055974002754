import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Form, Icon, Spin } from 'antd';
import { practiceConfig } from '../../../config';
import { Table } from '../../../elements';
import { ButtonBar, Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import CustomButton from '../../../components/commons/CustomButton';

class TeamsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: practiceConfig.rowsPerPage,
      loading: false,
      order: null,
      orderColumn: 'firstName',
      page: 1,
      search: null,
      totalCount: null,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleSearch(searchParams) {
    const { type } = searchParams;
    let search = searchParams;

    if (type === 'clear') {
      search = null;

      this.props.form.resetFields();
    }

    this.fetchData(
      this.state.page,
      this.state.limit,
      this.state.order,
      this.state.orderColumn,
      search,
    );

    this.setState({ search });
  }

  handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === 'descend' ? 'DESC' : 'ASC';

    this.fetchData(
      !pagination.current ? this.state.page : pagination.current,
      !pagination.pageSize ? this.state.limit : pagination.pageSize,
      sorter ? sorterOrder : this.state.order,
      sorter ? sorter.field : this.state.orderColumn,
      this.state.search,
    );

    this.setState({
      limit: !pagination.pageSize ? this.state.limit : pagination.pageSize,
      page: !pagination.current ? this.state.page : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
    });
  }

  fetchData(page, limit, order, orderColumn, search) {
    this.setState({ loading: true });

    const offset = (page - 1) * limit;
    this.props.data.fetchMore({
      variables: {
        limit,
        offset,
        order,
        orderColumn,
        search,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({ loading: false });
        // console.log('prev', previousResult)
        // console.log('new: ', fetchMoreResult)

        if (!fetchMoreResult) {
          // console.log('No fetch more result, show prev: ', previousResult)
          return previousResult;
        }
        this.setState({
          totalCount: fetchMoreResult.getAllClients.pagination.totalCount,
        });
        // console.log('show fetchmore: ', previousResult)
        return fetchMoreResult;
      },
    });
  }

  render() {
    const { currentPractice } = this.props;
    const {
      data: { loading, error, getTeams = null },
    } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            currentPractice={currentPractice}
            displayText="Teams"
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            currentPractice={currentPractice}
            displayText="Teams"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - clients didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        dataIndex: 'name',
        render: (text, row) => (
          <Link
            to={`/${currentPractice}/teams/view/${row.id}`}
          >
            {row.name}
          </Link>
        ),
        title: 'Name',
      },
      {
        dataIndex: 'advisors',
        render: (text, row) => (
          row.advisors.length
        ),
        title: 'Members',
      },
      {
        dataIndex: 'clients',
        render: (text, row) => (
          <Link
            to={`/${currentPractice}/teams/clients/${row.id}`}
          >
            {row.clients.length}
          </Link>
        ),
        title: 'Clients',
      },
    ];

    if (currentPractice) {
      columns.push({
        className: 'action-column',
        fixed: 'right',
        render: client => (
          <CustomButton
                rights={['superAdmin','editTeams']}
                icon="plus"
                // href={"/users/add"}
                onClick={()=>{this.props.history.push(`/${currentPractice}/teams/edit/${client.id}`)}}
                icon={'edit'}
                type={'link'}
          />
        ),
      });
    }

    return (
      <div>
        <Titlebar
          currentPractice={currentPractice}
          displayText="Teams"
        />
        {
          currentPractice ?
          <div className="button-bar-wrapper">
          <CustomButton
            rights={['superAdmin','createTeams']}
            icon="plus"
            onClick={()=>this.props.history.push(`/${currentPractice}/teams/add`)}
            text="Add New"
            type={'primary'}
          >Add New</CustomButton> </div>: null
        }
        <LayoutContentWrapper>

          <LayoutContent>
            <Table
              columns={columns}
              dataSource={getTeams}
              loading={this.state.loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }}
              locale={{ emptyText: 'No clients found.' }}
              onChange={this.handleTableChange}
              pagination={ false }
              rowKey={c => (c.id + "-" + c.practiceName)}
              scroll={{ x: columns.length * 150 }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const allTeamsQuery = gql`
  query (
    $practiceId: String!
  ){
    getTeams(practiceId: $practiceId){
      id
      name
      clients{
        id
      }
      practice{
        id
        practiceId
      }
      advisors{
        id
      }
    }
  }
`;

export default graphql(allTeamsQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      practiceId: props.currentPractice
    },
  }),
})(Form.create()(TeamsList));
