import styled from 'styled-components';
import { palette } from 'styled-theme';

const LayoutContentWrapper = styled.div`
  background: ${palette('base', 0)};
  border: 1px solid rgba(238,242,244,1);
  border-radius: 4px;
  margin: 24px 24px 0;
  padding: 24px;
  @media only screen and (max-width: 767px) {
    margin: 24px 0;
  }
  .display-bar {
    color: rgba(0,0,0,.85);
    font-size: 12px;
    padding-bottom: 24px;
    &.with-search {
      padding-bottom: 12px;
    }
  }
  .search-bar-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .search-bar-inner {
      width: calc(100% - 200px);
    }
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      .search-bar-inner {
        width: 100%;
      }
    }
  }
  .search-bar {
    max-width: 424px;
    .has-search {
      .ant-input-suffix .ant-input-search-button {
        right: 34px;
      }
    }
  }
  .show-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    .show-label {
      margin-right: 8px;
    }
  }
`;

export default LayoutContentWrapper;
