import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import {Button, Form, Icon, Input, Radio, Spin} from 'antd';
import { practiceConfig } from '../../../config';
import { Table } from '../../../elements';
import { ButtonBar, Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import { FormatPercent } from '../../../helpers/formatters';
import CustomButton from "../../../components/commons/CustomButton";
import {AccountContext} from "../../../context/AccountContext";
import _ from "lodash";

const FormItem = Form.Item;
const InputSearch = Input.Search;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class AdvisorsList extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      addPulse: false,
      currentPractice: this.props.currentPractice !== 'advisors'
        ? this.props.currentPractice
        : null,
      limit: practiceConfig.rowsPerPage,
      loading: false,
      order: null,
      orderColumn: 'firstName',
      page: 1,
      search: null,
      selectedColumnData: 'Counts',
      totalCount: null,
      roleId:null
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.updateColumnData = this.updateColumnData.bind(this);
  }

  componentDidUpdate(prevProps){

    if(this.props !== prevProps){
      const userContext = this.context;
      let roleId = _.get(userContext.account,'roleId');
      if (roleId){
        this.setState({roleId})
        this.fetchData(
            this.state.page,
            this.state.limit,
            this.state.order,
            this.state.orderColumn,
            "",
            roleId
        );
      }
    }

  }

  handleSearch(searchParams) {
    const { type } = searchParams;
    let search = searchParams;

    if (type === 'clear') {
      search = null;

      this.props.form.resetFields();
    }

    this.fetchData(
      this.state.page,
      this.state.limit,
      this.state.order,
      this.state.orderColumn,
      search,
     this.state.roleId
    );

    this.setState({ search });
  }

  handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === 'descend' ? 'DESC' : 'ASC';

    this.fetchData(
      !pagination.current ? this.state.page : pagination.current,
      !pagination.pageSize ? this.state.limit : pagination.pageSize,
      sorter ? sorterOrder : this.state.order,
      sorter ? sorter.field : this.state.orderColumn,
      this.state.search,
      this.state.roleId
    );

    this.setState({
      limit: !pagination.pageSize ? this.state.limit : pagination.pageSize,
      page: !pagination.current ? this.state.page : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field
    });
  }

  fetchData(page, limit, order, orderColumn, search,roleId) {
    this.setState({ loading: true });

    const offset = (page - 1) * limit;
    this.props.data.fetchMore({
      variables: {
        limit,
        offset,
        order,
        orderColumn,
        search,
        roleId
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({ loading: false });
        if (!fetchMoreResult) {
          return previousResult;
        }

        this.setState({
          totalCount: fetchMoreResult.getAllAdvisors.pagination.totalCount,
        });

        return fetchMoreResult;
      },
    });
  }

  updateColumnData(e) {
    this.setState({
      addPulse: true,
      selectedColumnData: e.target.value,
    });
  }

  render() {
    const { addPulse, currentPractice, limit, page, selectedColumnData } = this.state;
    const {
      data: { loading, error, getAllAdvisors = [] },
      form: { getFieldDecorator },
    } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Advisors"
          />
          <div className="loading-wrapper">
            <Spin indicator={
              <Icon
                type="loading"
                style={{ fontSize: 24 }}
                spin
              />
            }
            />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Advisors"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - advisors didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    //const { advisors, pagination: { totalCount = 0 } } = getAllAdvisors;
    let advisors = _.get(this.props.data,'getAllAdvisors.advisors',[])
    let totalCount = _.get(this.props.data,'getAllAdvisors.pagination.totalCount',0)
    const tableCount = this.state.totalCount > 0 ? this.state.totalCount : totalCount;
    //let tablePagination = false;

    // if (tableCount > practiceConfig.rowsPerPage) {
     let  tablePagination = {
        current: page,
        defaultPageSize: practiceConfig.rowsPerPage,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: tableCount,
      };
   // }

    const countStart = ((page - 1) * limit) + 1;
    const countEnd = page * limit > tableCount ? tableCount : page * limit;
    const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : 'No Records';

    const columns = [
      {
        dataIndex: 'firstName',
        render: (text, advisor) => (
          <Link
            className={advisor.status === 'Inactive' ? 'inactive' : null}
            to={currentPractice
              ? `/${currentPractice}/advisors/view/${advisor.id}`
              : `/advisors/view/${advisor.id}`}
          >
            {advisor.firstName} {advisor.lastName}
          </Link>
        ),
        fixed:'left',
        sorter: true,
        title: 'Advisor',
        width:200

      },
      {
        align: 'center',
        dataIndex: 'assessmentStats.totalClients',
        sorter: true,
        title: '# of Clients',
      },
      {
        title: 'Assessment',
        children: [{
          align: 'center',
          dataIndex: 'assessmentStats.completedAssessments',
          sorter: true,
          title: 'Completed Assessments',
        },
        {
          align: 'center',
          dataIndex: 'assessmentStats.uncompletedAssessments',
          sorter: true,
          title: 'Uncompleted Assessments',
        },
        {
          align: 'center',
          dataIndex: 'assessmentStats.completionRate',
          render: completionRate => FormatPercent(completionRate),
          sorter: true,
          title: 'Completion Rate',
        }],

      },

      {
        title: 'Behavior',

        children: [{
          align: 'center',
          className: addPulse ? 'pulseit' : null,
          dataIndex: selectedColumnData === 'Counts' ? 'assessmentStats.protectorCount' : 'assessmentStats.protectorPercent',
          render: text => (selectedColumnData === 'Counts' ? text : FormatPercent(text)),
          sorter: true,
          title: 'Protector',
        },
        {
          align: 'center',
          className: addPulse ? 'pulseit' : null,
          dataIndex: selectedColumnData === 'Counts' ? 'assessmentStats.observerCount' : 'assessmentStats.observerPercent',
          render: text => (selectedColumnData === 'Counts' ? text : FormatPercent(text)),
          sorter: true,
          title: 'Observer',
        },
        {
          align: 'center',
          className: addPulse ? 'pulseit' : null,
          dataIndex: selectedColumnData === 'Counts' ? 'assessmentStats.liberatorCount' : 'assessmentStats.liberatorPercent',
          render: text => (selectedColumnData === 'Counts' ? text : FormatPercent(text)),
          sorter: true,
          title: 'Liberator',
        },
        {
          align: 'center',
          className: addPulse ? 'pulseit' : null,
          dataIndex: selectedColumnData === 'Counts' ? 'assessmentStats.energizerCount' : 'assessmentStats.energizerPercent',
          render: text => (selectedColumnData === 'Counts' ? text : FormatPercent(text)),
          sorter: true,
          title: 'Energizer',
        }],
      },
      {
        title: 'Decision Making Style',

        children: [{
          align: 'center',
          className: addPulse ? 'pulseit' : null,
          dataIndex: selectedColumnData === 'Counts' ? 'assessmentStats.logicalCount' : 'assessmentStats.logicalPercent',
          render: text => (selectedColumnData === 'Counts' ? text : FormatPercent(text)),
          sorter: true,
          title: 'Logical',
        },
        {
          align: 'center',
          className: addPulse ? 'pulseit' : null,
          dataIndex: selectedColumnData === 'Counts' ? 'assessmentStats.passionateCount' : 'assessmentStats.passionatePercent',
          render: text => (selectedColumnData === 'Counts' ? text : FormatPercent(text)),
          sorter: true,
          title: 'Passionate',
        },
        {
          align: 'center',
          className: addPulse ? 'pulseit' : null,
          dataIndex: selectedColumnData === 'Counts' ? 'assessmentStats.balancedCount' : 'assessmentStats.balancedPercent',
          render: text => (selectedColumnData === 'Counts' ? text : FormatPercent(text)),
          sorter: true,
          title: 'Balanced',
        }],
      },
      {
        align: 'center',
        dataIndex: 'city',
        render: (text, row) => (row.city && row.state ? `${row.city}, ${row.state}` : ''),
        sorter: true,
        title: 'Location',

      },
      {
        align: 'center',
        className: 'action-column',
        width:100,
        fixed:'right',
        render: (text, advisor) => {
            //  <Link to={`/${currentPractice}/advisors/edit/${advisor.id}`}>
            //   <Icon type="edit" />
            // </Link>
            if([3].includes(advisor.roleId))
              return <CustomButton
                  rights={['superAdmin','editAdviser']}
                  icon="plus"
                  // href={"/users/add"}
                  onClick={()=>{this.props.history.push(`/${currentPractice}/advisors/edit/${advisor.id}`)}}
                  icon={'edit'}
                  type={'link'}
              />
            // <Button type={'link'} icon={'edit'} onClick={()=>{this.props.history.push(`/${currentPractice}/advisors/edit/${advisor.id}`)}}/>
        },
      }

    ];

    // if (currentPractice && advisors.length > 0) {
    //   columns.push({
    //     align: 'center',
    //     className: 'action-column',
    //     fixed: 'right',
    //     render: (text, advisor) => (
    //     //  <Link to={`/${currentPractice}/advisors/edit/${advisor.id}`}>
    //      //   <Icon type="edit" />
    //      // </Link>
    //         <Button type={'link'} icon={'edit'} onClick={()=>{this.props.history.push(`/${currentPractice}/advisors/edit/${advisor.id}`)}}/>
    //     ),
    //   });
    // }

    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText="Advisors"
        />
        { currentPractice ?
          <div className="button-bar-wrapper">
            <CustomButton
                rights={['superAdmin','createAdviser']}
                icon="plus"
                // href={"/users/add"}
                onClick={()=>this.props.history.push(`/${currentPractice}/advisors/add`)}
                text="Add New"
                type={'primary'}
            >Add New</CustomButton>
            {/*<ButtonBar*/}
              {/*icon="plus"*/}
              {/*link={`/${currentPractice}/advisors/add`}*/}
              {/*text="Add New"*/}
            {/*/>*/}
          </div> : null}
        <LayoutContentWrapper>
          <div className="display-bar with-search">
            {display}
          </div>
          <div className="search-bar-wrapper">
            <div className="search-bar-inner">
              <div className="search-bar">
                <Form>
                  <FormItem className="oka-list-search">
                    {getFieldDecorator('search')(
                      <InputSearch
                        autoComplete="off"
                        className={this.state.search ? 'has-search' : null}
                        enterButton
                        onSearch={this.handleSearch}
                        placeholder="Search for an advisor..."
                        suffix={this.state.search
                          ? ( <Icon key="icon" onClick={() => this.handleSearch({ type: 'clear' })} style={{ color: '#bebebe' }} type="close-circle-o" />)
                          : <span />
                        }
                      />)}
                  </FormItem>
                </Form>
              </div>
            </div>
            <div className="show-wrapper">
              <div className="show-label">Show:</div>
              <div>
                <RadioGroup defaultValue={selectedColumnData} onChange={this.updateColumnData}>
                  <RadioButton value="Counts">Counts</RadioButton>
                  <RadioButton value="Percents">Percents</RadioButton>
                </RadioGroup>
              </div>
            </div>
          </div>
          <LayoutContent>
            <Table
              bordered={true}
              columns={columns}
              dataSource={advisors}
              loading={this.state.loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }}
              locale={{ emptyText: 'No advisors found.' }}
              onChange={this.handleTableChange}
             pagination={tablePagination ? { ...tablePagination } : false}
              rowKey="id"
              // size="middle"Edit Portal
              scroll={{ x: 'max-content' }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const allAdvisorsQuery = gql`
  query(
    $limit: Int
    $offset: Int
    $order: String
    $orderColumn: String
    $practiceId: String
    $search: String
    $roleId:Int
  ) {
    getAllAdvisors(
      limit: $limit
      offset: $offset
      order: $order
      orderColumn: $orderColumn
      practiceId: $practiceId
      search: $search
      roleId:$roleId
    ) {
      advisors {
        id
        city
        email
        firstName
        lastName
        phoneNumber
        practiceName
        state
        status
        roleId
        assessmentStats {
          completionRate
          completedAssessments
          uncompletedAssessments
          energizerCount
          energizerPercent
          liberatorCount
          liberatorPercent
          observerCount
          observerPercent
          protectorCount
          protectorPercent
          totalClients
          logicalCount
          passionateCount
          balancedCount
          logicalPercent
          passionatePercent
          balancedPercent
        }
      }
      pagination {
        totalCount
      }
    }
  }
`;

export default graphql(allAdvisorsQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      limit: practiceConfig.rowsPerPage,
      practiceId: props.currentPractice !== 'advisors'
        ? props.currentPractice
        : null,
    },
  }),
})(Form.create()(AdvisorsList));
