import React from 'react'
import { Link } from 'react-router-dom'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { practiceConfig } from '../../config'
import desktopLogo from '../../assets/img/unitifi.svg'

const getPracticeDetailsQuery = gql`
  query($practiceId: String) {
    getPracticeDetails(practiceId: $practiceId) {
      name
      siteLogo
    }
  }
`

class Logo extends React.Component {
  shouldComponentUpdate(nextProps) {
    // only update logo on practice change
    if (this.props.practiceId === null) {
      return false
    }

    if (this.props.practiceId === '') {
      return false
    }

    if (nextProps.practiceId === '') {
      return false
    }

    if (
      ['advisors', 'clients', 'users'].includes(this.props.practiceId) &&
      nextProps.practiceId === 'practices'
    ) {
      return false
    }

    return (
      this.props.practiceId !== nextProps.practiceId &&
      !['advisors', 'clients', 'users'].includes(nextProps.practiceId) &&
      nextProps.practiceId !== null
    )
  }

  render() {
    const LogoImg = ({ practiceId }) => (
      <Query
        query={getPracticeDetailsQuery}
        fetchPolicy='network-only'
        variables={{ practiceId }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return null
          }

          if (error) {
            return (
              <Link to={practiceId ? `/${practiceId}` : '/'}>
                <img alt={practiceConfig.practiceName} src={desktopLogo} />
              </Link>
            )
          }

          const logoAlt =
            data.getPracticeDetails && data.getPracticeDetails.name
              ? data.getPracticeDetails.name
              : practiceConfig.practiceName

          const logoSrc =
            data.getPracticeDetails && data.getPracticeDetails.siteLogo
              ? data.getPracticeDetails.siteLogo
              : desktopLogo

          return <img alt={logoAlt} src={logoSrc} />
        }}
      </Query>
    )

    return (
      <Link to={this.props.practiceId ? `/${this.props.practiceId}` : '/'}>
        <LogoImg practiceId={this.props.practiceId} />
      </Link>
    )
  }
}

export default Logo
