import React from 'react';
import { practiceConfig } from '../../config';
import logo from '../../assets/img/unitifi.svg';
import PublicContentWrapper from './index.style';

export default ({ children, className }) => (
  <PublicContentWrapper className={className}>
    <div
      className="public-wrapper"
      {...window.location.pathname.includes('/confirm') ? { style: { minHeight: 'calc(100vh - 45px)' } } : null}
    >
      <div className="public-main">
        <div className="public-logo">
          <img alt={practiceConfig.practiceName} src={logo} style={{height:'47px'}} />
        </div>
        {children}
      </div>
    </div>
    <div className="public-footer">
      {practiceConfig.footerText}
    </div>
  </PublicContentWrapper>
);
