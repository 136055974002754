import styled from 'styled-components';

const DashboardWrapper = styled.div`
  .dashboard-filter-bar {
    align-items: center;
    background: rgba(0,111,186,1);
    color: rgba(255,255,255,1);
    display: flex;
    justify-content: space-between;
    padding: 24px;
    transition: all .2s;
    .date-label {
      font-size: 12px;
    }
    .date-select-wrapper {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      .ant-dropdown-link {
        border-bottom: solid 1px rgba(255,255,255,1);
        color: rgba(255,255,255,1);
        cursor: pointer;
        padding-bottom: 2px;
      }
      .select-label {
        margin-right: 8px;
      }
    }
  }
  .dashboard-wrapper {
    display: flex;
    .main-wrapper {
      padding: 24px 24px 0;
      transition: all .2s;
      width: 100%;
      @media only screen and (max-width: 767px) {
        padding: 24px 0;
      }
    }
    .sidebar-wrapper {
      background: rgba(240,242,245,1);
      height: 100vh;
      margin-top: -6px;
      min-width: 200px;
      padding-left: 24px;
      position: fixed;
      right: 24px;
      transition: all .2s;
      width: 200px;
      z-index: 2;
      .sidebar-inner-wrapper {
        height: 100%;
        overflow-x: scroll;
        padding-bottom: 132px;
        .button-wrapper {
          display: flex;
          justify-content: flex-end;
          button {
            &:first-child {
              margin-right: 8px;
            }
          }
        }
      }
      &.collapsed {
        right: -200px;
        transition: all .2s;
      }
    }
    .ant-skeleton-content {
      ul {
        padding-left: 0;
      }
    }
    .dashboard-info-wrapper {
      border: 1px solid rgba(238,242,244,1);
      border-left: none;
      border-right: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 24px;
      .dashboard-info-item {
        background: rgba(255,255,255,1);
        border-right: 1px solid rgba(238,242,244,1);
        flex-direction: column;
        display: flex;
        padding: 24px;
        text-align: center;
        width: 25%;
        @media only screen and (max-width: 550px) {
          width: 50%;
          &:nth-child(-n+2) {
            border-bottom: 1px solid rgba(238,242,244,1);
          }
          &:nth-child(2) {
            border-right: none;
          }
        }
        &:last-child {
          border-right: none;
        }
        .dashboard-info-item-inner {
          padding: 24px;
        }
        .dashboard-info-title {
          color: rgba(0,111,186,1);
          font-size: 16px;
          font-weight: 600;
        }
        .dashboard-info-detail {
          color: rgba(0,0,0,.65);
          font-size: 40px;
          font-weight: 600;
          line-height: 40px;
        }
        .dashboard-info-average {
          color: rgba(0,0,0,.65);
          font-weight: 600;
          &.dimmed {
            color: rgba(0,0,0,.2);
          }
        }
        &.dimmed {
          .dashboard-info-title {
            color: rgba(0,0,0,.2);
          }
          .dashboard-info-detail {
            color: rgba(0,0,0,.2);
          }
          .dashboard-info-average {
            color: rgba(0,0,0,.2);
          }
        }
        .ant-skeleton-content {
          .ant-skeleton-title {
            margin-bottom: 2px;
            margin-top: 14px;
          }
          .ant-skeleton-paragraph {
            margin-top: 0;
            li {
              margin-bottom: 4px;
              &:first-child {
                height: 22px;
              }
            }
            > li + li {
              margin-top: 0;
            }
          }
        }
      }
    }
    .dashboard-chart-wrapper {
      background: rgba(255,255,255,1);
      border: 1px solid rgba(238,242,244,1);
      border-radius: 2px;
      padding: 0 0 24px;
      &.no-clients {
        .no-client-message-wrapper {
          border: 1px solid rgba(238,242,244,1);
          border-left: none;
          border-right: none;
          padding: 24px;
          p {
            &:first-child {
              padding-bottom: 8px;
            }
            span {
              color: rgba(0,0,0,.85);
            }
          }
        }
      }
      .dashboard-chart-title {
        color: rgba(0,0,0,.85);
        font-size: 20px;
        line-height: 22px;
        padding: 24px;
        @media only screen and (max-width: 767px) {
          padding: 24px 12px;
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }
  }
`;

export default DashboardWrapper;
