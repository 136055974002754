import styled from 'styled-components';
import { palette } from 'styled-theme';

const TotalClientMapWrapper = styled.div`
  color: ${palette('text', 1)};
  padding: 0 12px;
  .top-wrapper {
    width: 100%;
    .top-bar {
      color: rgba(0,0,0,.65);
      display: flex;
      font-weight: 600;
      div {
        display: flex;
        justify-content: center;
        padding: 0 0 12px;
        width: 50%;
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-left: none;
          border-right: none;
        }
      }
    }
    .bottom-bar {
      color: rgba(0,0,0,.65);
      display: flex;
      font-weight: 600;
      position: absolute;
      width: calc(100% - 330px);
      z-index: 2;
      @media only screen and (max-width: 1023px) {
        width: calc(100% - 74px);
      }
      @media only screen and (max-width: 767px) {
        width: calc(100% - 25px);
      }
      .bottom-item {
        width: 50%;
        .inner {
          display: flex;
          div {
            border-top: none;
            display: flex;
            justify-content: center;
            padding: 8px 0;
            width: 50%;
            &:last-child {
              border-left: none;
            }
          }
        }
        &:last-child {
          div {
            border-left: none;
          }
        }
      }
    }
  }
  .chart-wrapper {
    display: flex;
    flex-direction: row;
    .chart {
      width: 100%;
      z-index: 3;
    }
    .label-wrapper {
      color: rgba(0,0,0,.65);
      font-weight: 600;
      height: 500px;
      position: absolute;
      right: 37px;
      width: 40px;
      z-index: 0;
      @media only screen and (max-width: 767px) {
        right: 12px;
      }
      div {
        align-items: center;
        color: rgba(0,0,0,.65);
        display: flex;
        height: 50%;
        justify-content: center;
        width: 40px;
        &:last-child {
          border-top: none;
        }
        span {
          transform: rotate(90deg);
        }
      }
    }
  }
  .bottom-wrapper {
    display: flex;
    margin-top: -40px;
    position: absolute;
    width: calc(100% - 330px);
    @media only screen and (max-width: 1023px) {
      width: calc(100% - 74px);
    }
    @media only screen and (max-width: 767px) {
      width: calc(100% - 25px);
    }
    .item {
      align-items: center;
      color: rgba(0,0,0,.65);
      display: flex;
      font-weight: 600;
      height: 40px;
      justify-content: center;
      text-align: center;
      width: 20%;
      z-index: 1;
    }
  }
`;

export default TotalClientMapWrapper;
