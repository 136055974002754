import moment from 'moment';

/* takes an object array like [{id, name}] and returns sorted alphabetically by name */
/* assits in building human friendly select dropdowns */
export const BuildOptions = (arr) => {
  /* remove empties & nulls */
  const optionsArr = arr.filter(a => a && a.id !== null);

  const sorted = optionsArr.reduce((acc, cur) => {
    if (!acc.some(obj => obj.id === cur.id)) {
      acc.push(cur);
    }
    return acc.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }, []);

  return sorted;
};

/* returns formatted phone number */
export const FormatPhoneNumber = (phoneNumber) => {
  /* remove everything but digits */
  const split = ('' + phoneNumber).replace(/\D/g, '');
  /* group the digits into buckets of 3, 3, 4 */
  const group = split.match(/^(\d{3})(\d{3})(\d{4})$/);
  /* return the buckets like 111-222-3333 */
  return (!group) ? null : group[1] + '-' + group[2] + '-' + group[3];
};

/* returns only digits */
export const FormatClickToCallPhoneNumber = phoneNumber => (('' + phoneNumber).replace(/\D/g, ''));

/* date/time formatters */
export const FormatDate = momentObj => (moment(momentObj, 'ddd MMM DD YYYY HH:mm:ss').format('M/D/YYYY'));
export const FormatTime = momentObj => (moment(momentObj, 'ddd MMM DD YYYY HH:mm:ss').format('h:mm a'));
export const FormatDateTime = momentObj => (moment(momentObj, 'ddd MMM DD YYYY HH:mm:ss').format('M/D/YYYY h:mm a'));

/* combine seperate date & time into single moment object */
export const DateTimeCombine = (momentObj1, momentObj2) => (
  moment(momentObj1.format('MM/DD/YYYY') + ' ' + momentObj2.format('HH:mm'), 'MM/DD/YYYY HH:mm')
    .utc()
    .format());

/* get the current time rounded to the nearest 15 minute interval */
export const GetCurrentTimeToFifteen = moment()
  .minute(Math.round(moment().minute() / 15) * 15)
  .second(0);

/* get 12:00 AM */
export const GetTwelveAM = moment('00:00:00', 'HH:mm:ss');

/* DatePicker & TimePicker formats */
export const FormatDateForDatePicker = momentObj => (moment(momentObj, 'ddd MMM DD YYYY'));
export const FormatTimeForTimePicker = momentObj => (moment(momentObj, 'ddd MMM DD YYYY HH:mm:ss'));

export const FormatPercent = number => (number * 100).toFixed(2) + '%';
