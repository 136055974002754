import React, {useContext, useEffect} from 'react';
import gql from 'graphql-tag';
import {AutoComplete, Badge, Form, Icon, Input, Select, Spin} from 'antd';
import {AddButtons, EditButtons, FormWrapper} from '../../../components/form';
import StateList from '../../../helpers/statesDataList';
import { useQuery } from 'react-apollo';
import _ from 'lodash';
import {AccountContext} from "../../../context/AccountContext";

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

export default Form.create()(ClientForm);

function ClientForm(props){

    const accountContext = useContext(AccountContext);
    const { getFieldError, getFieldProps, getFieldDecorator } = props.form;

    const { currentPractice } = props
    const { loading,error, data, refetch } = useQuery(
    gql`
		    query($practiceId: String) {
            getAllAdvisorsPerPracticesForForm(practiceId: $practiceId) {
              advisors {
                id
                firstName
                lastName
                practiceId
      }
    }
  }
		`,
    {
        variables: {
            practiceId: currentPractice
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    }
);
    useEffect(() => {
        if(accountContext && _.get(accountContext,'account.portalid[0]')) {
            recall()
        }
    }, [accountContext.account])


    function recall(){
        refetch({
            practiceId:currentPractice
        })


    }

    function handleSubmit(event) {
        event.preventDefault();

        props.form.validateFields((err, values) => {
            if (!err) {
                props.onSubmit(values);
            }
        });
    }


        if (loading) {
            return (
                <div className="loading-wrapper">
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
                </div>
            );
        }

        if (error) {
            return (
                <div className="loading-wrapper">
                    <p>Whoopsie - client didn&apos;t load.</p>
                </div>
            );
        }

        // const { practices } = getAllPracticesForForm;
        let advisors  = _.get(data.getAllAdvisorsPerPracticesForForm,'advisors');

        return (
            <FormWrapper>
                <Form layout="vertical" onSubmit={handleSubmit}>
                    {props.gblUserIsGlobalAdmin || props.gblUserIsPortalAdmin || props.gblUserIsPracticeAdmin ?
                        <FormItem
                            label="Advisor"
                        >
                            {getFieldDecorator('advisorId', {
                                initialValue: props.client ? props.client.advisorId : undefined,
                                rules: [{
                                    message: 'A client advisor is required.',
                                    required: true,
                                }],
                            })(
                                <Select
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    optionFilterProp="children"
                                    placeholder="Advisor"
                                    showSearch
                                >
                                    {_.map(advisors,(advisor,x) => (
                                        <Option key={x} value={advisor.id}>{advisor.firstName} {advisor.lastName}</Option>
                                    ))}
                                </Select>)}
                        </FormItem>
                        : null}
                    <FormItem
                        label="Client Email"
                    >
                        {getFieldDecorator('email', {
                            initialValue: props.client ? props.client.email : null,
                            rules: [{
                                message: 'A client email is required.',
                                required: true,
                            }],
                        })(<Input placeholder="Client Email" />)}
                    </FormItem>
                    <FormItem
                        className="input-small"
                        label="First Name"
                    >
                        {getFieldDecorator('firstName', {
                            initialValue: props.client ? props.client.firstName : null,
                            rules: [{
                                message: 'A client first name is required.',
                                required: true,
                            }],
                        })(
                            <Input
                                placeholder="First Name"
                            />)}
                    </FormItem>
                    <FormItem
                        className="input-small"
                        label="Last Name"
                    >
                        {getFieldDecorator('lastName', {
                            initialValue: props.client ? props.client.lastName : null,
                            rules: [{
                                message: 'A client last name is required.',
                                required: true,
                            }],
                        })(
                            <Input
                                placeholder="Last Name"
                            />)}
                    </FormItem>
                    <FormItem
                        className="input-small"
                        label="Address"
                    >
                        {getFieldDecorator('address', {
                            initialValue: props.client ? props.client.address : null,
                        })(<Input placeholder="Address" />)}
                    </FormItem>
                    <FormItem
                        className="input-small"
                        label="City"
                    >
                        {getFieldDecorator('city', {
                            initialValue: props.client ? props.client.city : null,
                        })(<Input placeholder="City" />)}
                    </FormItem>

                    <FormItem
                        className="input-xsmall"
                        label="State"
                    >
                        {getFieldDecorator('state', {
                            initialValue: props.user && props.user.state ? props.user.state : undefined,
                        })(<AutoComplete
                            placeholder="Select a State"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {(StateList || []).map(state => (
                                <AutoCompleteOption key={state.abbr} value={state.abbr}>{state.name}</AutoCompleteOption>
                            ))}
                        </AutoComplete>)}
                    </FormItem>
                    <FormItem
                        className="input-xsmall"
                        label="Zip Code"
                    >
                        {getFieldDecorator('zipCode', {
                            initialValue: props.client ? props.client.zipCode : null,
                        })(<Input placeholder="Zip Code" />)}
                    </FormItem>
                    <FormItem
                        className="input-phone__wrapper"
                        label="Phone Number"
                    >
                        <InputGroup>{getFieldDecorator('phoneNumber', {
                            initialValue: props.client ? props.client.phoneNumber : null,
                        })(
                            <div className="input-phone__input">
                                {getFieldDecorator('p1', {
                                    initialValue: props.client && props.client.phoneNumber ? props.client.phoneNumber.substring(0, 3) : null,
                                })(<Input maxLength={3} placeholder="867" />)}
                                <span>&#8211;</span>
                                {getFieldDecorator('p2', {
                                    initialValue: props.client && props.client.phoneNumber ? props.client.phoneNumber.substring(3, 6) : null,
                                })(<Input maxLength={3} placeholder="530" />)}
                                <span>&#8211;</span>
                                {getFieldDecorator('p3', {
                                    initialValue: props.client && props.client.phoneNumber ? props.client.phoneNumber.substring(6, 10) : null,
                                })(<Input maxLength={4} placeholder="9999" />)}
                            </div>)}
                        </InputGroup>
                    </FormItem>
                    <FormItem
                        className="input-small"
                        label="Add Client to the Team"
                    >
                        {getFieldDecorator('addClientToTeam', {
                            initialValue: props.client? props.client.addClientToTeam : true,
                        })(
                            <Select
                                placeholder="Add to team"
                            >
                                <Option value={true}>
                                    <Badge status="success" text="Yes" />
                                </Option>
                                <Option value={false}>
                                    <Badge status="error" text="No" />
                                </Option>
                            </Select>)}
                    </FormItem>
                    <div className="form-toolbar">
                        {props.client ?
                            <EditButtons
                                deleteRights={'deleteClients'}
                                cancelUrl={props.cancelUrl}
                                loading={props.loading}
                                onDelete={props.onDelete}
                                onSubmit={handleSubmit}
                                rights={['superAdmin','editClients']}
                            /> :
                            <AddButtons
                                cancelUrl={props.cancelUrl}
                                createAnother={props.createAnother}
                                loading={props.loading}
                                onCreateAnother={props.onCreateAnother}
                                onSubmit={handleSubmit}
                                rights={['superAdmin','createClients']}
                            />}
                    </div>
                </Form>
            </FormWrapper>
        );

}
//
// ClientForm.propTypes = {
//     createAnother: PropTypes.bool,
//     loading: PropTypes.bool.isRequired,
//     onCreateAnother: PropTypes.func,
//     onDelete: PropTypes.func,
//     onSubmit: PropTypes.func.isRequired,
// };
//
// ClientForm.defaultProps = {
//     createAnother: false,
//     onCreateAnother: null,
//     onDelete: null,
// };
//
