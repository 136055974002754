import { Popconfirm, Form , Input , Select , AutoComplete, Icon, Result, Typography, Col, Row, List, Avatar, Button, Dropdown, Menu,  Modal, Card, message, Tag} from "antd";
import gql from "graphql-tag";
import React, { useState, useMemo } from "react";
import { Titlebar } from "../../../components";
import { FormWrapper } from "../../../components/form";
import { graphql } from 'react-apollo';
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper.style";
import LayoutContent from '../../../layouts/app/layoutContent';
import _, { flowRight as compose, upperCase } from 'lodash';
import moment from 'moment';
import ButtonGroup from "antd/lib/button/button-group";

import { Elements, CardElement } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import useResponsiveFontSize from '../useResponsiveFontSize'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const useOptions = () => {	
  const fontSize = useResponsiveFontSize();	
  const options = useMemo(	
    () => ({	
      style: {	
        base: {	
          fontSize,	
          color: "#424770",	
          letterSpacing: "0.025em",	
          fontFamily: "Source Code Pro, monospace",	
          "::placeholder": {	
            color: "#aab7c4"	
          }	
        },	
        invalid: {	
          color: "#9e2146"	
        }	
      }	
    }),	
    [fontSize]	
  );	

  return options;	
};	

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />
const {Title, Paragraph} = Typography


const StripeAccount = (props) => {
  const { userSubscription : {loading, subscription}, cards, paymentIntent} = props
  const [processing,setProcessing] = useState(false)
  function onPay (cards) {
    console.log(cards.paymentMethod.length,'paymentMethod')
      if(cards.paymentMethod.length > 0){
        setProcessing(true)
        paymentIntent({
          variables:{
            proceed:true,
            type:'repayment'
          }
        }).then(function(result){
          console.log(result,'result')
          if(result.data.stripePaymentIntent){
            if(["requires_action","requires_source_action"].includes(result.data.stripePaymentIntent.status)){
              window.location.replace(result.data.stripePaymentIntent.redirect_to_url);
            }
            else if (result.data.stripePaymentIntent.status === "succeeded"){
              props.history.push('/settings/subscription')
            }
            else{
              message.error("Your card was declined, please use another one or try again.")
              setProcessing(false)
            }
          }
          else
          {
            message.error('Something went wrong.')
            setProcessing(false)
          }
        })
      }
      else{
        message.error('Please add payment method first.')
      }
  }

  console.log(subscription,'subscription')
  if(loading)
    return <Result
                  icon={antIcon}
                  title="Loading ..."
                  />

  return <FormWrapper>
            <Typography>
              <Title level={4} className={'form-title'}>Membership</Title>
              {(subscription.status == 'incomplete') && <>
                <Paragraph style={{marginBottom:0}}>
                 <b style={{color:'rgb(245, 106, 0)'}}>{upperCase(subscription.plan.interval) == 'MONTH' ?  'Monthly ' : 'Annually '} Subscription </b> is incomplete.
                </Paragraph>  
                <Button type={'primary'} loading={processing} onClick={()=>onPay(cards)}>Pay subscription</Button>  
              </>}
              {(subscription.status !== 'incomplete') && <>
                <Paragraph style={{marginBottom:0}}>
                  <b style={{color:'rgb(245, 106, 0)'}}>Subscription</b>{` since ${moment(new Date(subscription.start_date * 1000)).format('MMMM D, YYYY')}`}
                </Paragraph>
                <Paragraph>
                  Your anual membership will automatically renew on {moment(new Date(subscription.current_period_end * 1000)).format('MMMM D, YYYY')}
                </Paragraph>
              </>}
            </Typography>
  </FormWrapper>

}


const stripePaymentIntent = gql`
  mutation($proceed:Boolean,$type:String)
  {
    stripePaymentIntent(proceed:$proceed,type:$type) {
      id
      status
      redirect_to_url
    }
  }
`;


const getUserSubscription = gql`
  query {
    subscription:getUserSubscription {
      id
      status
      start_date
      current_period_end
      current_period_start
      plan{
        interval
      }
    }
  }
`;
export default compose(
  graphql(getUserSubscription,{
    name: 'userSubscription',
  }),
  graphql(stripePaymentIntent,{
    name: 'paymentIntent',
  })
)(Form.create()(StripeAccount));