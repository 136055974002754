import React from 'react';
import { Redirect } from 'react-router';
import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Dropdown, Icon, Menu, notification, Popover, Card, Divider, Button } from 'antd';
import { format } from 'date-fns';
import IntlMessages from '../../helpers/intlMessages';
import {UserMenuWrapper, UserCardWrapper} from './index.style';
import {AccountContext} from "../../context/AccountContext";
import _ from 'lodash';
// import { Alert } from 'antd';

function GetInitials(name) {
  const nameArray = name.split(' ');
  const initialsArray = [];

  nameArray.forEach(i => initialsArray.push(i.charAt(0).toUpperCase()));

  return initialsArray.join('');
}

class UserMenu extends React.Component {

  static contextType = AccountContext

  constructor(props) {
    super(props);

    this.state = {
      logout: false,
      visible: false,
    };

    this.handleUserTempClick = this.handleUserTempClick.bind(this);
    this.handleUserLogout = this.handleUserLogout.bind(this);
    this.handleUserProfile = this.handleUserProfile.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
  }


  handleUserTempClick(){
    console.log('No page provided yet');
  }

  handleUserLogout() {
    localStorage.setItem('token', null);
    localStorage.setItem('refreshToken', null);
    this.setState({
      logout: true,
    });
  }

  handleUserProfile() {
    this.props.history.push("/sun-life-insurance/users")
  }
  
  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };
  
  updateNotifications(options) {
    const { clearAll, clientId, practiceId } = options;
    const variables = {
      practiceId,
    };

    if (!clearAll) {
      variables.clientId = clientId;
    }

    this.props.updateNotifications({ variables })
      .then(({ data }) => {
        if (data.updateNotifications.ok && !data.updateNotifications.errors) {
          this.props.data.refetch();

          return;
        }

        this.handleError(data.updateNotifications.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleError(errors) {
    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {


    const { data: { loading, me } } = this.props;
    const { logout } = this.state;

    console.log(me,'me')
    let name = null;

    if (logout) {
      return <Redirect to="/login" />;
    }

    if (loading) {
      return (
        <UserMenuWrapper>
          <div className="user-menu">
            <Icon type="loading" />
          </div>
        </UserMenuWrapper>
      );
    }

    if (me) {
      name = me.firstName && me.lastName ? `${me.firstName} ${me.lastName}` : 'My Account';
      const {customerStripeId} = me
      const notificationMenuContent = (
        <Menu className="user-notification-menu">
          {me.notifications.clientDetails.map(client => (
            <Menu.Item key={client.id}>
              <div className="menu-item-wrapper">
                <Link to={`/${client.practiceId}/clients/view/${client.clientId}`} onClick={()=>{localStorage.setItem('current_practice',client.practiceId);this.setState({ visible: false });}}>
                  <div>{client.name}</div>
                  <div className="note">
                    <span style={{ color: client.transferredClient ? '#faad14' : '#008000' }}>
                      {client.transferredClient ? 'Transferred Client' : 'New Assessment'}
                    </span> - {format(client.dateCollected, 'M/D/YYYY')}
                  </div>
                </Link>
                <div>
                  <Icon
                    onClick={() => this.updateNotifications({ clientId: client.id,...client })}
                    type="close"
                  />
                </div>
              </div>
            </Menu.Item>
          ))}
          {me.notifications.clientDetails.length > 0 ?
            <Menu.Item onClick={() => this.updateNotifications({ clearAll: true })}>
              <span>Clear All Notifications</span>
            </Menu.Item> : null}
        </Menu>
      );

      // Dropdown content (profile)
        // const userMenuContent = (
          // <Menu>
          //   <Menu.Item>

          //     <Row gutter={16}>
          //       <Col className="gutter-row" span={8}>
          //       <div className="gutter-box">col-6</div>
          //     </Col>
          //   </Row>
          //     <Link to={`/users/account/${me.id}`}>
          //       <div>
          //         <Avatar size={64} icon="user" />
          //       </div>
          //       <IntlMessages id="topbar.userMenuMyAccount" />
          //     </Link>
          //   </Menu.Item>
          //   <Menu.Item onClick={this.handleUserLogout}>
          //     <IntlMessages id="topbar.userMenuLogout" />
          //   </Menu.Item>
          // </Menu>
        // );
      const profileContent = (
        <Card bordered={false} bodyStyle={{padding: 0,}}>
          <Avatar
            size={64}
            style={{ margin: 'auto', display:'block', backgroundColor: 'rgba(101,122,133,.3)',}}
          >
            {GetInitials(name)}
          </Avatar>
          <div style={{textAlign: 'center'}}>
            <strong>
              {name}
            </strong>
          </div>
          <Divider style={{margin: '10px 0'}}/>
          <UserCardWrapper >
          {(customerStripeId && (
            <Button className={'profile-link'} type="danger" block  
                    style={{boxShadow:'none',backgroundColor:'transparent',borderColor:'transparent',color:'black'}}>
              <Link to={"/settings"}>
              <Icon type="setting" />  <IntlMessages id="topbar.userMenuProfile" />
              </Link>
            </Button>
          ))}
          
          <Button className={'profile-link'} type="danger" block onClick={this.handleUserLogout}
                  style={{boxShadow:'none',backgroundColor:'transparent',borderColor:'transparent',color:'black'}}>
           <Icon type="logout" />  <IntlMessages id="topbar.userMenuLogout" />
          </Button>
          </UserCardWrapper>
        </Card>
      );

      return (
        <UserMenuWrapper>
          {me.notifications.clientDetails.length > 0 ?
            <Dropdown
              overlay={notificationMenuContent}
              placement="bottomRight"
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.visible}
              trigger={['click']}
            >
              <div className="user-menu notification">
                <Badge count={me.notifications.count}>
                  <Icon
                    type="bell"
                  />
                </Badge>
              </div>
            </Dropdown> :
            <Dropdown
              overlay={(
                <Menu className="user-notification-menu">
                  <Menu.Item>
                    <div
                      className="menu-item-wrapper"
                      style={{ cursor: 'default', fontSize: '14px', justifyContent: 'center' }}
                    >
                      No New Notifications
                    </div>
                  </Menu.Item>
                </Menu>
              )}
              placement="bottomRight"
              trigger={['click']}
            >
              <div className="user-menu">
                <Icon
                  type="bell"
                />
              </div>
            </Dropdown>}
            <Popover placement="bottomRight" content={profileContent} trigger="click">
              <div className="user-menu">
                <div className="user-menu-dropdown-link">
                  <Avatar
                    style={{
                      backgroundColor: 'rgba(101,122,133,.3)',
                      fontWeight: '600',
                    }}
                  >
                    {GetInitials(name)}
                  </Avatar>
                </div>
              </div>
            </Popover>

          { /****
            <Dropdown
              overlay={userMenuContent}
              placement="bottomRight"
              trigger={['click']}
            >
              <div className="user-menu">
                <div className="user-menu-dropdown-link">
                  <Avatar
                    style={{
                      backgroundColor: 'rgba(101,122,133,.3)',
                      fontWeight: '600',
                    }}
                  >
                    {GetInitials(name)}
                  </Avatar>
                </div>
              </div>
            </Dropdown>
           */}
       </UserMenuWrapper>
      );
    }

    return <Redirect to="/login" />;
  }
}

const currentUserQuery = gql`
  {
    me {
      id
      firstName
      lastName
      permissions
      portalid
      practiceId
      customerStripeId
      notifications {
        clientDetails {
          id
          clientId
          dateCollected
          name
          practiceId
          transferredClient
        }
        count
      }
    }
  }
`;

const updateNotificationsMutation = gql`
  mutation updateNotifications(
    $clientId: Int
    $practiceId: String
  ) {
    updateNotifications(
      clientId: $clientId
      practiceId: $practiceId
    ) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(currentUserQuery, {
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(updateNotificationsMutation, { name: 'updateNotifications' }),
)(UserMenu);
