import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import GeneralPage from '../../../containers/settings/general';
import AccountPage from '../../../containers/settings/account';
import PaymentMethod from '../../../containers/settings/subscription';

class SettingsRoute extends React.Component {
  render() {
    const {
      gblUserId, gblUserIsPortalAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice,userPortals
    } = this.props;

    return (
      <Switch>
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect
            exact
            from="/settings"
            to="/settings/general"
            strict
        />

        <Redirect
            exact
            from="/settings/account/"
            to="/settings/account"
            strict
        />

        <Redirect
            exact
            from="/settings/subscription/"
            to="/settings/subscription"
            strict
        />

        {/* admin routes */}
          <Route
              exact
              path="/settings/general"
              render={props => (
                  <GeneralPage />
              )}
          />

          <Route
              exact
              path="/settings/account"
              render={props => (
                  <AccountPage />
              )}
          />

          <Route
              exact
              path="/settings/subscription"
              render={props => (
                  <PaymentMethod />
              )}
          />

          
      </Switch>
    );
  }
}

export default SettingsRoute;
