import styled from 'styled-components';

const LogoUploadWrapper = styled.div`
  .logo-upload-wrapper {
    .title {
        color: rgba(0,0,0,.85);
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 8px;
      }
    .logo-action {
      width: 200px;
      .ant-upload.ant-upload-select {
        margin-bottom: 8px;
      }
    }
    .logo-wrapper {
      margin-bottom: 8px;
      &.with-logo {
        margin-bottom: 0;
      }
      .logo-img-preview {
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        height: 53px;
        margin-bottom: 0;
        margin-right: 0;
        width: 138px;
        img {
          height: 35px;
          margin: 8px;
          width: 120px;
        }
      }
    }
  }
`;

export default LogoUploadWrapper;
