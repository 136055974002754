import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { notification } from 'antd';
import { Redirect } from 'react-router';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import ClientForm from '../form';

class ClientAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect
        ? this.props.location.state.redirect
        : `/${this.props.currentPractice}/clients`,
      key: 1,
      loading: false,
      redirectTo: null,
      reset: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      phoneNumber: `${values.p1}${values.p2}${values.p3}`,
      practiceId: this.props.currentPractice,
    };

    this.props.mutate({ variables })
      .then(({ data }) => {
        if (data.createClient.ok && !data.createClient.errors) {
          this.handleSuccess(`${data.createClient.client.firstName} ${data.createClient.client.lastName}`, data.createClient.client.id);
          return;
        }

        this.handleError(data.createClient.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleReset() {
    this.setState({ reset: !this.state.reset });
  }

  handleSuccess(clientName, clientId) {
    notification.success({
      message: 'Client Created',
      description: `${clientName} has been successfully created.`,
    });

    if (this.state.reset === false) {
      this.setState({ redirectTo: `/${this.props.currentPractice}/clients/view/${clientId}` });
    } else {
      this.setState({
        key: this.state.key + 1,
        loading: false,
      });
    }
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {
    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText="Add Client"
        />
        <LayoutContentWrapper>
          <LayoutContent>
            <ClientForm
              cancelUrl={this.state.cancelUrl}
              createAnother={this.state.reset}
              currentPractice={this.props.currentPractice}
              gblUserRole={this.props.gblUserRole}
              key={this.state.key}
              loading={this.state.loading}
              onCreateAnother={this.handleReset}
              onSubmit={this.handleSubmit}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={{
                pathname: this.state.redirectTo,
                state: { newClient: true },
              }}
              />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const addClientMutation = gql`
  mutation createClient(
    $address: String
    $advisorId: Int
    $city: String
    $email: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $practiceId: String
    $state: String
    $zipCode: String
  ) {
    createClient(
      address: $address
      advisorId: $advisorId
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      practiceId: $practiceId
      state: $state
      zipCode: $zipCode
    ) {
      ok
      client {
        id
        firstName
        lastName
      }
      errors {
        message
        path
      }
    }
  }
`;

export default graphql(addClientMutation)(ClientAdd);
