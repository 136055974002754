import styled from 'styled-components';
// import { palette } from 'styled-theme';

const MenuWrapper = styled.div`
  .menuItem {
    border-color : transparent
  }
 .ant-menu-item-selected  > .menuItem {
    background: #F2F3F5!important
  }
`;

export default MenuWrapper;
