import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import {  graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { Link } from 'react-router-dom';
import decode from 'jwt-decode';
import { Button, Form, Icon, Input, Spin } from 'antd';
import IntlMessages from '../../../../helpers/intlMessages';
import { FormWrapper, PasswordFeedbackWrapper, PasswordInputWrapper } from '../../../../components/form';
import PublicContent from '../../../../layouts/public';
/**
 * handles user password reset &
 * logs user in if successful
 * falls back to sending user to
 * login page
 */
const FormItem = Form.Item;

class UsersPasswordForgot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: false,
      loading: false,
      value: null,
    };

    this.handleConfirmNewPassword = this.handleConfirmNewPassword.bind(this);
    this.handlePasswordFeedback = this.handlePasswordFeedback.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePasswordFeedback = (rule, value, callback) => {
    const { form } = this.props;
    /**
     * handles feedback provided as a user types
     * password - sets state to pass as props to the
     * PasswordFeedbackWrapper component
     * form.validateFields(['confirm'], { force: true });
     * ^ that function surpresses the validating state
     * provided by antd
     * https://ant.design/components/form/#components-form-demo-validate-static
     */
    if (value) {
      this.setState({
        input: true,
        value,
      });
    } else {
      this.setState({ input: false });
    }

    form.validateFields(['confirm'], { force: true });
    callback();
  }

  handleConfirmNewPassword = (rule, value, callback) => {
    const { form } = this.props;
    /**
     * check for matching password confirmation
     * as user types
     */
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords do not match.');
    } else {
      callback();
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const { form } = this.props;

    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });

        const { id, email } = this.props.data.checkToken.user;
        /**
         * check password for minimum 8 characters,
         * 1 uppercase, 1 lowercase, 1 special character & 1 number
         * if password does not meet requirements,
         * send back an error and empty the confirm
         * password input
         */
        const check = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9@$!%*#?&]{8,}$');
        const { password } = values;

        if (check.test(password)) {
          const updatePasswordResponse = await this.props.updatePassword({
            variables: {
              id,
              email,
              password,
              passwordResetToken: null,
              passwordResetTokenExpires: null,
              status: 'Active',
            },
          });

          if (updatePasswordResponse.data.editUser.ok) {
            /**
             * if user's password was reset successfully,
             * log the user in
             * fallback to sending user to the login page
             */
            const loginUserResponse = await this.props.loginUser({
              variables: { email, password },
            });

            const {
              ok, token, refreshToken,
            } = loginUserResponse.data.loginUser;

            /**
             ** if the user exists store a token & refreshToken in the user's localStorage
             ** if user does not exist send errors to form based on type
             ** if user belongs to more than one practice or is a global admin,
             ** send to practice list else to practice dashboard
             */

            if (ok) {
              localStorage.setItem('token', token);
              localStorage.setItem('refreshToken', refreshToken);

              const { user } = decode(token);
              const { isGlobalAdmin, isPracticeAdmin, practices } = user;

              if (isGlobalAdmin || isPracticeAdmin || practices.length > 1) {
                this.props.history.push('/');
              } else {
                this.props.history.push(`/${practices[0].practiceId}`);
              }
            } else {
              /* blank both tokens in local storage to prevent spoofing */
              localStorage.setItem('token', null);
              localStorage.setItem('refreshToken', null);

              this.props.history.push('/login');
            }
          } else {
            /* blank both tokens in local storage to prevent spoofing */
            localStorage.setItem('token', null);
            localStorage.setItem('refreshToken', null);

            this.props.history.push('/login');
          }
        } else {
          this.setState({ loading: false });

          /* blank both tokens in local storage to prevent spoofing */
          localStorage.setItem('token', null);
          localStorage.setItem('refreshToken', null);

          form.setFields({
            password: {
              value: password,
              errors: [new Error('Please enter a valid password.')],
            },
            confirmPassword: {
              value: null,
            },
          });
        }
      }
    });
  }

  render() {
    const {
      data: { loading, error, checkToken = null },
      form: { getFieldDecorator },
    } = this.props;
    const { input, value } = this.state;

    if (loading) {
      return (
        <PublicContent className="stretch-content">
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </PublicContent>
      );
    }

    if (error) {
      return (
        <PublicContent className="stretch-content">
          <div className="public-error-message">
            <p className="message">This password reset request is not valid.</p>
            <p>Please contact your practice administrator for assistance.</p>
          </div>
        </PublicContent>
      );
    }

    const { user } = checkToken;

    return (
      <PublicContent className="stretch-content">
        <div className="public-form-wrapper">
          <div className="public-message">
            <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '16px', paddingBottom: '8px' }}>Hello {user.firstName}!</p>
            <p>Please enter your new password.</p>
          </div>
          <FormWrapper className="public-form">
            <Form layout="vertical" onSubmit={this.handleSubmit}>
              <FormItem label="Email Address">
                {getFieldDecorator('email', {
                  initialValue: user.email,
                  rules: [{
                    message: 'Email is required.',
                    required: true,
                  }],
                })(
                  <Input disabled />)}
              </FormItem>
              <PasswordInputWrapper>
                <PasswordFeedbackWrapper input={input} value={value} />
              </PasswordInputWrapper>
              <FormItem className="show-required" label="Password">
                {getFieldDecorator('password', {
                  rules: [{
                    message: 'Password is required.',
                    required: true,
                    validator: this.handlePasswordFeedback,
                  }],
                })(<Input type="password" />)}
              </FormItem>
              <FormItem className="show-required" label="Confirm Password">
                {getFieldDecorator('confirmPassword', {
                  rules: [{
                    required: true,
                    validator: this.handleConfirmNewPassword,
                  }],
                })(<Input type="password" />)}
              </FormItem>
              <FormItem>
                <Button
                  htmlType="submit"
                  loading={this.state.loading}
                  type="primary"
                >
                  Submit
                </Button>
              </FormItem>
            </Form>
            <div className="uni-back">
              <Link to="/login">
                <Icon className="uni-back-icon" type="arrow-left" />
                <IntlMessages id="login.backToLogin" />
              </Link>
            </div>
          </FormWrapper>
        </div>
      </PublicContent>
    );
  }
}

UsersPasswordForgot.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
};

const checkConfirmToken = gql`
  query checkToken(
    $token: String!
    $tokenType: String!
  ) {
    checkToken(
      token: $token
      tokenType: $tokenType
    ) {
      ok
      user {
        id
        email
        firstName
      }
      errors {
        message
      }
    }
  }
`;

const updatePasswordMutation = gql`
  mutation editUser(
    $id: Int!
    $passwordResetToken: String
    $passwordResetTokenExpires: String
    $email: String!
    $password: String
    $status: String!
  ) {
    editUser(
      id: $id
      passwordResetToken: $passwordResetToken
      passwordResetTokenExpires: $passwordResetTokenExpires
      email: $email
      password: $password
      status: $status
    ) {
      ok
      errors {
        message
      }
    }
  }
`;

const loginUserMutation = gql`
  mutation(
    $email: String!
    $password: String!
  ) {
    loginUser(
      email: $email
      password: $password
    ) {
      ok
      token
      refreshToken
      errors {
        path
      }
    }
  }
`;

export default compose(
  graphql(checkConfirmToken, {
    fetchPolicy: 'network-only',
    options: props => ({
      variables: {
        token: props.match.params.token,
        tokenType: 'passwordResetToken',
      },
    }),
  }),
  graphql(loginUserMutation, { name: 'loginUser' }),
  graphql(updatePasswordMutation, { name: 'updatePassword' }),
)(Form.create()(UsersPasswordForgot));
