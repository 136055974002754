import {Result, Button} from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';

const Done = () => {

    return (
        <Result
            status="success"
            title="This assessment has been submitted.!"
            subTitle="Please check your email or contact your financial professional to review your personalized results."
            extra={[
            <Button type="primary" key="console" href={'https://unitifi.com/'} >
                Ok
            </Button>,
            ]}
        />
    )
}

export default Done;