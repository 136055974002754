import React from 'react';
import { Layout } from 'antd';
import { Logo, UserMenu } from '../../components';
import HeaderBarWrapper from './index.style';

const { Header } = Layout;

const HeaderBar = props => (
  <HeaderBarWrapper>
    <Header>
      <Logo practiceId={props.currentPractice} />
      <div className="user-menu-item">
        <UserMenu currentPractice={props.currentPractice} />
      </div>
    </Header>
  </HeaderBarWrapper>
);

export default HeaderBar;
