import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AdvisorRoutes, AssessmentRoutes, ClientRoutes, PracticeRoutes, UserRoutes, TeamRoutes, PortalRoutes } from './routes';
import Dashboard from '../../containers/dashboard';
import PageNotFound from '../../containers/404';
import { UsersAdd } from '../../containers/users';
import SettingsRoute from './routes/settings';

class Router extends React.Component {
  render() {

    const {
      currentPractice,
      gblUserId,
      gblUserIsGlobalAdmin,
      gblUserIsPracticeAdmin,
      gblUserIsPortalAdmin,
      gblUserRole,
      sidebarCollapsed,
      userPracticeList,
      userPortals,
      firstLogin,
      firstName
    } = this.props;

    return (
      <Switch>
        <Route
          path="/settings"
          render={props => (
            <SettingsRoute
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}

            />
          )}
        />

        <Route
          path="/advisors"
          render={props => (
            <AdvisorRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}

            />
          )}
        />

        <Route
          path="/assessments"
          render={props => (
            <AssessmentRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
            />
          )}
        />

        <Route
          path="/clients"
          render={props => (
            <ClientRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
            />
          )}
        />
        <Route
          exact
          path="/"
          render={props => (
            <Dashboard
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
            />
          )}
        />
        <Route
          path="/companies"
          render={props => (
            <PortalRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
            />
          )}
        />
        <Route
          path="/practices"
          render={props => (
            <PracticeRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
            />
          )}
        />
          <Route
              exact
              path="/:company/practices"
              render={props => (
                  <PracticeRoutes
                      {...props}
                      currentPractice={currentPractice}
                      gblUserId={gblUserId}
                      gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                      gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                      gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
                      gblUserRole={gblUserRole}
                      userPracticeList={userPracticeList}
                      userPortals={userPortals}
                  />
              )}
          />
        <Route
          path="/users"
          render={props => (
            <UserRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
            />
          )}
        />
          <Route
              path="/:company/users"
              render={props => (
                  <UserRoutes
                      {...props}
                      currentPractice={currentPractice}
                      gblUserId={gblUserId}
                      gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                      gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                      gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
                      gblUserRole={gblUserRole}
                      userPracticeList={userPracticeList}
                      userPortals={userPortals}
                  />
              )}
          />
        {/* practice routes */}
        <Route
          exact
          path="/:practiceId"
          render={props => (
            <Dashboard
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              practiceDashboard={true}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
            />
          )}
        />
        <Route
          path="/:practiceId/practices"
          render={props => (
            <PracticeRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
            />
          )}
        />
        <Route
          path="/:practiceId/advisors"
          render={props => (
            <AdvisorRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
            />
          )}
        />
        <Route
          path="/:practiceId/clients"
          render={props => (
            <ClientRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
            />
          )}
        />
        <Route
          path="/:practiceId/teams"
          render={props => (
            <TeamRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
            />
          )}
        />

        
        {/* <Route
          path="/:practiceId/client-teams"
          render={props => (
            <ClientTeamRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
            />
          )}
        /> */}
        <Route
          component={PageNotFound}
          exact
          path="/404"
        />
        
        {/* redirect authenticated user to either practice list or practice */}
        {gblUserIsGlobalAdmin || gblUserIsPortalAdmin ?
          <Redirect from="/" to="/" /> :
          <Redirect from="/" to={`/${userPracticeList[0]}`} />}
        {/* 404 redirect */}
        {/* <Redirect to="/404" /> */}
      </Switch>
    );
  }
}

export default Router;
