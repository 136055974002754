import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Upload } from 'antd';
import UploadWrapper from './index.style';

class uploadLogo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blob: null,
      icon: this.props.listType === 'picture-card' ? 'plus' : 'upload',
      overlay: false,
    };

    this.stageUpload = this.stageUpload.bind(this);
  }

  stageUpload({ file }) {
    const { folder } = this.props;

    this.setState({
      icon: 'loading',
      overlay: true,
    });
    /**
     * read upload to blob for previewing
     */
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        blob: reader.result,
      });
    };
    /**
     * give a second for the blob to create
     * & then send uploadParams to the parent
     */
    setTimeout(() => {
      const uploadParams = {
        blob: this.state.blob,
        file,
        folder,
      };

      this.props.handleUploadParams(uploadParams);

      this.setState({
        icon: this.props.listType === 'picture-card' ? 'plus' : 'upload',
        overlay: false,
      });
    }, 1000);
  }

  render() {
    const { listType, preview } = this.props;

    if (listType === 'picture-card') {
      return (
        <UploadWrapper>
          <Upload customRequest={this.stageUpload} fileList={[]} listType={listType}>
            {preview ?
              <div className="preview">
                {this.state.overlay ? <Icon type="loading" /> : null}
                <img alt="avatar-preview" src={preview} />
              </div> :
              <div>
                <Icon type={this.state.icon} />
              </div>}
          </Upload>
        </UploadWrapper>
      );
    }

    return (
      <UploadWrapper>
        <Upload customRequest={this.stageUpload} fileList={[]}>
          <Button>
            <Icon type={this.state.icon} /> Upload
          </Button>
        </Upload>
      </UploadWrapper>
    );
  }
}

uploadLogo.defaultProps = {
  folder: null,
  listType: null,
};

uploadLogo.propTypes = {
  folder: PropTypes.string,
  listType: PropTypes.string,
};

export default uploadLogo;
