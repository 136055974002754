import React from 'react';
import {  graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { format } from 'date-fns';
import moment from 'moment';
import { Badge, Button, Dropdown, Form, Icon, Menu, Spin, Typography, Tooltip, Alert } from 'antd';
import { ClientInfoBar, Titlebar, TotalClientMap, DashboardFilter } from '../../components';
import datePresets from './components/datePresets';
import DashboardWrapper from './index.style';
import {AccountContext} from "../../context/AccountContext";
import _ from 'lodash';
import { envUrl } from '../../config';

const { Paragraph, Title } = Typography;
class Dashboard extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      dateRange: {
        endDate: moment().format('YYYY-MM-DD'),
        startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      },
      loading: false,
      filters: null,
      applyFilterButtonDisabled: true,
      filterCount: 0,
      menuKey: 'Last 30 Days',
      visible: false,
    };

    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleDrawer = this.handleDrawer.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);

    this.onDateMenuClick = this.onDateMenuClick.bind(this);
  }

  // componentDidMount() {
  //   const {profile} = this.context;
  //   console.log("userContext", profile);
  //   let company = _.get(profile,'portals',[]);
  //   if(!_.isEmpty(company)){
  //     this.pushUrl(`/company/${company[0].slug}`)
  //   }
  // }


  pushUrl = (path) => {
    this.props.history.push(path)
  };

  onSelectChange(param, value) {
    const { filters: filtersFromState } = this.state;

    const filters = {
      ...filtersFromState,
      [param]: value,
    };

    // remove empties from filter object
    Object.keys(filters)
      .forEach(key => (filters[key] === null) && delete filters[key]);

    this.setState({
      applyFilterButtonDisabled: false,
      filters,
    });
  }

  onDatesChange({ startDate, endDate }, close) {
    this.setState({
      dateRange: { startDate, endDate },
      ...close && { focusedInput: false },
    });

    if (endDate) {
      this.fetchData({ clear: false });
    }
  }

  onDateMenuClick({ key }) {
    const { filters } = this.state;

    const dates = datePresets.find(p => p.key === key).dateRange;

    this.setState({
      dateRange: {
        endDate: moment().utc(dates.endDate).local().format(),
        startDate: moment().utc(dates.startDate).local().format(),
      },
      menuKey: key,
    });

    this.fetchData({
      filters: {
        ...filters,
        ...{ dateRange: datePresets.find(p => p.key === key).dateRange },
      },
    });
  }

  handleDrawer = () => {
    const { visible } = this.state;

    this.setState({
      visible: !visible,
    });
  };

  // always get all time when doing filter
  applyFilters() {
    const {filters } = this.state;
    // console.log('filters', filters);
    const key = 'All Time';
    const dates = datePresets.find(p => p.key === key).dateRange;

    this.setState({
      dateRange: {
        endDate: moment().utc(dates.endDate).local().format(),
        startDate: moment().utc(dates.startDate).local().format(),
      },
      menuKey: key,
    });

    // get date and filter
    this.fetchData({
      filters: {
        ...filters,
        ...{ dateRange: datePresets.find(p => p.key === key ).dateRange },
      },
    });
  }

  clearFilters() {
    // const { dateRange } = this.state;
    const key = 'All Time';

    this.setState({ filters: null, menuKey: key });

    this.fetchData({
      filters: {
        ...{ dateRange: datePresets.find(p => p.key === key ).dateRange },
       },
    });
  }

  fetchData(params) {
    this.setState({ loading: true });

    const { match: { params: { practiceId } } = null } = this.props;
    const { filters } = params;
    let practiceIds = filters.practiceId || [];
    this.props.data.fetchMore({
      variables: {
        filters:
        {
          ...filters,
          practiceId: practiceId? [...practiceIds, practiceId] : practiceIds,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({
          applyFilterButtonDisabled: true,
          filterCount: filters
            ? Object.keys(filters)
              .filter(k => k !== 'dateRange').length
            : 0,
          loading: false,
        });

        if (!fetchMoreResult) {
          return previousResult;
        }

        return fetchMoreResult;
      },
    });
  }

  render() {
    const {
      currentPractice,
      data: {
        loading: dataLoading,
        error: dataError,
        getClientMap,
      },
      getAdvisorsForDashboard: {
        loading: advisorsLoading,
        error: advisorsError,
        getAdvisorsForDashboard,
      },
    } = this.props;

    console.log(this.props,'this.props')
    const {
      applyFilterButtonDisabled,
      dateRange: {
        endDate,
        startDate,
      },
      filterCount,
      loading,
      menuKey,
      visible,
    } = this.state;

    const formatEndDate = moment(endDate).format('M/D/YYYY');
    const formatStartDate = moment(startDate).format('M/D/YYYY');
    const dateLabel = formatStartDate !== 'Invalid date' && formatEndDate !== 'Invalid date'
      ? `${formatStartDate} - ${formatEndDate}`
      : 'All Time';

    if (dataLoading || advisorsLoading) {
      return (
        <div>
          <Titlebar
            currentPractice={currentPractice}
            displayText="Dashboard"
          />
          <div className="loading-wrapper">
            <Spin
              indicator={
                <Icon
                  spin
                  style={{ fontSize: 24 }}
                  type="loading"
                />
              }
            />
          </div>
        </div>
      );
    }

    if (dataError || advisorsError) {
      return (
        <div>
          <Titlebar
            currentPractice={currentPractice}
            displayText="Dashboard"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - dashboard didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const { advisors } = getAdvisorsForDashboard;
    const {
      clients,
      pagination: {
        totalCount = 0,
      },
    } = getClientMap;

    if (totalCount === 0) {
      return (
        <div>
          <Titlebar currentPractice={currentPractice} displayText="Dashboard" />
          <DashboardWrapper style={{ padding: '24px' }}>
            <div className="main-wrapper">
              <div className="dashboard-chart-wrapper" style={loading ? { opacity: '.5' } : null}>
                <div className="dashboard-chart-title">Happy {format(Date.now(), 'dddd')}!</div>
                <div style={{ padding: '0 24px' }}>
                  <p>No clients have submitted an assessment.</p>
                  <p style={{ marginBottom: '24px' }}>Let&#39;s get started by adding a client and sending an assessment.</p>
                  <Link to={`${currentPractice}/clients/add`}>
                    <Button type="primary">
                      Add A Client
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </DashboardWrapper>
        </div>
      );
    }

    return (
      <DashboardWrapper>
        <Titlebar currentPractice={currentPractice} displayText={this.props.practiceDashboard? "Practice Dashboard" :"Dashboard"} />
        <DashboardFilter
          applyFilters={this.applyFilters}
          applyFilterButtonDisabled={applyFilterButtonDisabled}
          clearFilters={this.clearFilters}
          data={{ advisors }}
          filterCount={filterCount}
          onClose={this.handleDrawer}
          onSelectChange={this.onSelectChange}
          visible={visible}
        />
        <div className="dashboard-filter-bar">
          <div>
            <div className="date-label">
              {dateLabel}
            </div>
            <div className="date-select-wrapper">
              <div className="select-label">Date Range:</div>
              <div>
                <Dropdown
                  overlay={(
                    <Menu onClick={this.onDateMenuClick} selectedKeys={[menuKey]}>
                      {datePresets.map(({ key }) => (
                        <Menu.Item key={key}>
                          {key}
                        </Menu.Item>
                      ))}
                    </Menu>
                  )}
                  trigger={['click']}
                >
                  <div className="ant-dropdown-link">
                    {menuKey} <Icon type="down" />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
          <div>
            <Badge count={filterCount}>
              <Button
                icon="filter"
                ghost
                onClick={this.handleDrawer}
                size="large"
              >
                Filters
              </Button>
            </Badge>
          </div>
        </div>
        {clients.length === 0 ?
          <>
          {(this.props.match.params.practiceId && (
              <div className="dashboard-wrapper">
                <div className="main-wrapper">
                  
                  {(this.props.firstLogin === moment().startOf('day').format('YYYY-MM-DD') && 
                    <Alert
                      message={`Hi, ${this.props.firstName}. Let's get started.`}
                      description={<p>Click <a href={`${envUrl}/settings/general`}>here</a>  to enter your company details.</p>}
                      type="info"
                      showIcon
                      style={{marginBottom:20}}
                    />
                  )}

                  <div
                    className="dashboard-chart-wrapper no-clients"
                    style={loading ? { opacity: '.5', paddingBottom: '0' } : { paddingBottom: '0' }}
                  >
                      <div className="no-client-message-wrapper">
                        <Paragraph copyable={{ text: `${envUrl}/survey/${this.props.currentPractice}/${this.props.gblUserId}` }}>
                          <Title level={4}>Group/Audience Assessment Link : </Title>
                          <Tooltip title="Use for general email campaigns, social posts, or website use.">
                            {`${envUrl}/survey/${this.props.currentPractice}/${this.props.gblUserId}`}
                          </Tooltip>
                          </Paragraph>
                      </div> 
                  </div>
                </div>
              </div>
          ))}
          

          <div className="dashboard-wrapper">
            <div className="main-wrapper">
              <div
                className="dashboard-chart-wrapper no-clients"
                style={loading ? { opacity: '.5', paddingBottom: '0' } : { paddingBottom: '0' }}
              >
                <div className="dashboard-chart-title">Your Clients</div>
                {filterCount === 0 ?
                  <div className="no-client-message-wrapper">
                    <p>No clients have submitted an assessment within the <span>{menuKey}</span>.</p>
                    <p>Select a different date range.</p>
                  </div> :
                  <div className="no-client-message-wrapper">
                    <p>No submitted client assessments match the <span>selected date range and applied filters</span>.</p>
                    <p>Update the selected date range and applied filters.</p>
                  </div>}
              </div>
            </div>
          </div>
          </>
           :
          
          <>
          {(this.props.match.params.practiceId && (
              <div className="dashboard-wrapper">
                <div className="main-wrapper">
                
                  {(this.props.firstLogin === moment().startOf('day').format('YYYY-MM-DD') && 
                    <Alert
                      message={`Hi, ${this.props.firstName}. Let's get started.`}
                      description={<p>Click <a href={`${envUrl}/settings/general`}>here</a>  to enter your company details.</p>}
                      type="info"
                      showIcon
                      style={{marginBottom:20}}
                    />
                  )}

                  <div
                    className="dashboard-chart-wrapper no-clients"
                    style={loading ? { opacity: '.5', paddingBottom: '0' } : { paddingBottom: '0' }}
                  >
                  
                      <div className="no-client-message-wrapper">

                        <Paragraph copyable={{ text: `${envUrl}/survey/${this.props.currentPractice}/${this.props.gblUserId}` }}>
                        <Title level={4}>Group/Audience Assessment Link : </Title>
                        <Tooltip title="Use for general email campaigns, social posts, or website use.">
                        {`${envUrl}/survey/${this.props.currentPractice}/${this.props.gblUserId}`}
                        </Tooltip>
                        </Paragraph>
                      </div> 
                  </div>
                </div>
              </div>
          ))}

          <div className="dashboard-wrapper">
            <div className="main-wrapper">
              <div className="dashboard-chart-wrapper" style={loading ? { opacity: '.5' } : null}>
                <div className="dashboard-chart-title">Your Clients</div>
                <ClientInfoBar data={clients} loading={loading} />
                <TotalClientMap data={clients} />
              </div>
            </div>
          </div>
          
          </>}
      </DashboardWrapper>
    );
  }
}

const getClientMapQuery = gql`
  query ($filters: JSON) {
    getClientMap(filters: $filters ) {
      clients {
        id
        dateCollected
        firstName
        insights
        lastName
        practice
        practiceName
      }
      pagination {
        totalCount
      }
    }
  }
`;

const getAdvisorsForDashboardQuery = gql`
  {
    getAdvisorsForDashboard {
      advisors {
        id
        firstName
        lastName
        practiceId
        practiceName
      }
    }
  }
`;

export default compose(
  graphql(getClientMapQuery, {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          ...props.match.params.practiceId
            ? { practiceId: [props.match.params.practiceId] }
            : null,
        },
      },
    }),
  }),
  graphql(getAdvisorsForDashboardQuery, {
    name: 'getAdvisorsForDashboard',
    options: {
      fetchPolicy: 'network-only',
    },
  }),
)(Form.create()(Dashboard));
