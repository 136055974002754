import React, {useContext, useEffect, useMemo, useState} from 'react';
import gql from 'graphql-tag';
import {Typography, Icon, Row, Col, Layout, Menu, Form, Input, List, Divider, Button, Tag, Result, message, Modal, Dropdown, Avatar, Card} from 'antd';
import { useQuery } from 'react-apollo';
import _, { flowRight as compose } from 'lodash';
import logo from '../../../assets/img/unitifi.svg';
import CustomInput from '../../../components/customInput';
import { graphql } from 'react-apollo';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import decode from 'jwt-decode';	
import ButtonGroup from "antd/lib/button/button-group";
import moment from 'moment';

import ContentWrapper from './stripe.payment.style';	
import { Elements, CardElement } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import useResponsiveFontSize from './useResponsiveFontSize'
import { apiUrl } from '../../../config';
import { func } from 'prop-types';

const { Content, Header, Footer } = Layout;
const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Title, Paragraph, Text } = Typography;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />
const { confirm } = Modal;

const radioStyle = {
  display: 'block',
  height: '160px',
  lineHeight: '30px',
};

const useOptions = () => {	
  const fontSize = useResponsiveFontSize();	
  const options = useMemo(	
    () => ({	
      style: {	
        base: {	
          fontSize,	
          color: "#424770",	
          letterSpacing: "0.025em",	
          fontFamily: "Source Code Pro, monospace",	
          "::placeholder": {	
            color: "#aab7c4"	
          }	
        },	
        invalid: {	
          color: "#9e2146"	
        }	
      }	
    }),	
    [fontSize]	
  );	

  return options;	
};	


const SplitForm = (props) => {	
  const { create , refetchCustomer, refetch, setAdd, add, form } = props 	
	const { getFieldProps, getFieldDecorator } = form;
  const stripe = useStripe();	
  const elements = useElements();	
  const options = useOptions();	
  const [loading , setLoading] = useState(false)

  const handleSubmit = async event => {	
    event.preventDefault();	
    if (!stripe || !elements) {	
      // Stripe.js has not loaded yet. Make sure to disable	
      // form submission until Stripe.js has loaded.	
      return;	
    }	

    const token = localStorage.getItem('token');	
    const { user: { customerStripeId } } = decode(token);	
    
    form.validateFields(async(err, values) => {
      if (!err) {
        setLoading(true)
        const {error,paymentMethod} = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: values
        });
        console.log(error,'errorss')
        if(error){
          setLoading(false)
          message.error(error.message)
        }
        else {
          await create({	
              variables: { paymentMethod : JSON.stringify(paymentMethod) },	
          }).then(function(result){

            if(result.data.createPaymentMethod){
              if(result.data.createPaymentMethod.ok){
                setAdd(false)
                refetch()
                refetchCustomer()
                setLoading(false)
              }
              else {
                if(result.data.createPaymentMethod.error == 'Not authenticated'){
                  message.error(result.data.createPaymentMethod.errors.message)
                  props.history.push('/login')
                }
                setLoading(false)
                message.error(result.data.createPaymentMethod.stripeErrors.message)
              }
            }
            else{
              setLoading(false)
              message.error('Unexpected error.')
            }

          })	
        }
    
        

      }
    });
  };	

  return (<form onSubmit={handleSubmit}>	
      <CustomInput
          label={'Name on Card'}
          style={{marginBottom:0}}
          {...getFieldProps('name', {
            initialValue:  '',
            rules: [
              {
                required: true,
                message: 'Name is required!',
              },
            ],
          })}
          size={'large'}
      />
      <CustomInput
          label={'Email'}
          style={{marginBottom:0}}
          {...getFieldProps('email', {
            initialValue:  '',
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ],
          })}
          size={'large'}
      />
      <label>
        <Title level={4}>Card details</Title> 
        <CardElement
          options={options}
          onReady={() => {
            // console.log("CardElement [ready]");
          }}
          onChange={event => {
            // console.log("CardElement [change]", event);
          }}
          onBlur={() => {
            // console.log("CardElement [blur]");
          }}
          onFocus={() => {
            // console.log("CardElement [focus]");
          }}
        />
      </label>
      <Divider />
      <Button type="primary" disabled={!stripe} htmlType="submit" loading={loading}>	
        Submit	
      </Button>	

      <Button type={'danger'} style={{marginLeft:10}} onClick={()=>setAdd(!add)} disabled={loading}>	
        cancel	
      </Button>	

    </form>	
  );	
};	

const PaymentProcessing = () => {

  return (
    <Row >
    <Col span={24} >
      <Result
        icon={antIcon}
        title="Please wait ..."
      />
    </Col>
    </Row>
  )
}

function PaymentMethod (props) {
  const {
    history,
    paymentMethod: {viewUserPaymentMethod, loading, refetch},
    paymentIntent,
    customer:{viewCustomer, refetch:refetchCustomer}
  } = props;

  const [error,setError] = useState({
    email:false
  })
  const [processing,setProcessing] = useState(false)
  const [state,setState]= useState({
    visible:false,
    src:""
  })
  const [add,setAdd] = useState(false)

  async function confirmDelete(id) {
    confirm({
      title: 'Do you want to delete these card?',
      onOk() {
        props.delete({	
          variables: {
            id
          }
        }).then(function(result){
          message.success('Successfully removed.');
          refetch()
        })	
      },
      onCancel() {},
    });
  }

  async function confirmDefault(id) {
    confirm({
      title: 'Do you want to set it as default payment card?',
      onOk() {
        props.setDefault({	
          variables: {
            id
          }
        }).then(function(result){
          message.success('Successfully set to default.');
          refetch()
          refetchCustomer()
        })	
      },
      onCancel() {},
    });
  }

  const menu = (id) => (
    <Menu>
      <Menu.ItemGroup key="g1" title="ACTION">
      <Menu.Item key="0" onClick={()=>confirmDefault(id)}>
        Set as  Default
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={()=>confirmDelete(id)}>
        Delete
      </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  const onProceed = async () => {
    setProcessing(true)
    paymentIntent({
      variables:{
        proceed:true
      }
    }).then(function(result){
      // console.log(stripePaymentIntent.status,'stripePaymentIntent')
      if(["requires_action","requires_source_action"].includes(result.data.stripePaymentIntent.status)){
        window.location.replace(result.data.stripePaymentIntent.redirect_to_url);
      }
      else if (result.data.stripePaymentIntent.status === "succeeded"){
        props.history.push(`payment/success?payment_intent=${result.data.stripePaymentIntent.id}`)
      }
      else if (result.data.stripePaymentIntent.status === "no_default_status"){
        message.error('Select a default card.')
        setProcessing(false)
      }
      else{
        message.error(result.data.stripePaymentIntent.stripeErrors.message)
        setProcessing(false)
      }
    })
  }


  return (
    <Layout className="layout" style={{height:'100vh'}} >
        <Header style={{background:'rgba(240,242,245,1)'}}>
            <div className={'logo-signup'} style={{textAlign:'center',background:'rgba(240,242,245,1)'}}>
                <img src={logo} style={{height:'47px'}}/>
            </div>
        </Header>
        {/* <Header style={{background:'white'}}>
            <div className={'logo-signup'} style={{textAlign:'center'}}>
                <img src={logo} style={{height:'47px'}}/>
            </div>
        </Header> */}
        <Content style={{ padding: '30px 10px 30px 10px' }}>
        <Row gutter={[{lg:8,xs:0}]}>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 9, offset: 8 }} xl={{ span: 6 , offset: 9}}>
        {
          processing && (
            <PaymentProcessing />
          )
        }
        {
          !processing && (
            <Card>
              <Row >
              <Col span={24}>
                <Title style={{textAlign:'left'}} level={2}>Payment Method</Title>
                {(
                    !add &&
                  <Button type={'primary'} onClick={()=>setAdd(!add)}>Add</Button>
                )}
              </Col>
              <Col span={24}>
                  {(
                    add &&
                    <div className={!add ? 'addForm hide' :'addForm'} >
                    <Divider />
                      <Elements stripe={stripePromise}><SplitForm add={add} setAdd={setAdd} refetchCustomer={refetchCustomer} refetch={refetch} {...props} /></Elements>	
                    </div>
                  )}
              </Col>
              <Col span={24}>
              <Divider />
              {((viewUserPaymentMethod && viewUserPaymentMethod.paymentMethod) && 
                <List
                  itemLayout="horizontal"
                  dataSource={viewUserPaymentMethod.paymentMethod}
                  renderItem={item => (
                    <List.Item
                    actions={[
                      
                      <ButtonGroup>
                        <Button type={'danger'} onClick={()=>confirmDelete(item.id)}>
                            <Icon type="delete" />
                        </Button>
                        <Button  onClick={()=>confirmDefault(item.id)}>
                            Set as default
                        </Button>
                        {/* <Dropdown  overlay={()=>menu(item.id)} trigger={['click']} placement={'bottomRight'}>
                        <Button ><Icon type="ellipsis" /></Button>
                        </Dropdown> */}
                      </ButtonGroup>]}
                    >
                      <List.Item.Meta
                        className={'stripe-card-item'}
                        avatar={<Avatar shape={'square'} icon="credit-card" style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}/>}
                        title={<>{`${item.card.brand.toUpperCase()} •••• ${item.card.last4} ` }&nbsp;
                                  {viewCustomer ?
                                      viewCustomer.customer.invoice_settings.default_payment_method  == item.id ? <Tag color={'blue'} >Default</Tag>:""
                                    :
                                    ""
                                  }
                              </>}
                        description={`Expires: ${moment(`${item.card.exp_year}-${item.card.exp_month}-01`).format('MMM  YYYY')}`}
                      />
                    </List.Item>
                  )}
                />
              )}
              
              </Col>
              </Row>
            
              <Divider dashed={true} />
              <Row gutter={[{lg:8,xs:0}]}>
              <Col span={24}>
                <form action={`${apiUrl}/create-checkout-session`} method={"POST"}>
                  <input type="hidden" name="customerEmail" value={viewCustomer ? viewCustomer.user.email : ""} />
                  <input type="hidden" name="priceId" value={viewCustomer ?viewCustomer.user.price_id : ""} /> 
                  <Button 
                    disabled={(viewUserPaymentMethod && viewUserPaymentMethod.paymentMethod) ? viewUserPaymentMethod.paymentMethod.length > 0 ? false : true : true}
                    loading={processing} 
                    block 
                    type='primary' 
                    size='large' 
                    style={{marginBottom:10}} 
                    onClick={()=>onProceed()}>
                    Proceed</Button>
                </form>
              {/* <Button block type='primary' htmlType="submit"  size='large' style={{marginBottom:10}} >Proceed</Button> */}
              <Button block type='default' htmlType="submit"  size='large'  onClick={()=> history.push(`/subscription`)}>Cancel</Button>
              </Col>
              </Row>
            </Card>
          )
        }
        </Col>
      </Row>
      </Content>
      <Footer style={{ textAlign: 'center'}}>Copyright ©2021 Unitifi</Footer>
    </Layout>
  )
}


const paymentMethodCreate = gql`
  mutation(
    $paymentMethod: String
  ){
    createPaymentMethod(
      paymentMethod : $paymentMethod
    ){
      ok
      errors {
        message
        path
      }
      stripeErrors {
        code
        decline_code
        message
      }
    }
  }
`
;

const queryPaymentMethod = gql`
  query{
    viewUserPaymentMethod{
      paymentMethod  {
        #billing_details
        card  {
          brand
          #checks
          country
          exp_month
          exp_year
          funding
          generated_from
          last4
          #networks
          #three_d_secure_usage
          wallet
        }
        created
        customer
        id
        livemode
        object
        type
      }
      
    }
  }
`
;

const stripeCustomer = gql`
  query{
    viewCustomer {
      customer {
        id
        address
        balance
        currency
        default_source
        delinquent
        description
        email
        discount
        created
        invoice_settings {
          custom_fields
          default_payment_method
          footer
        }
      }
      user{
        price_id
        email
      }
      payment_method
      {
        card {
          brand
          checks {
            address_line1_check
            address_postal_code_check
            cvc_check
          }
          country
          exp_month
          exp_year
          funding
          generated_from
          last4
          fingerprint
          networks{
            available
            preferred
          }
          three_d_secure_usage{
            supported
          }
          wallet
        }
      }
    }
  }
`;

const stripePaymentIntent = gql`
  mutation($proceed:Boolean)
  {
    stripePaymentIntent(proceed:$proceed) {
      id
      status
      redirect_to_url
      stripeErrors {
        code
        decline_code
        message
      }
    }
  }
`;

const paymentMethodRemove = gql`
  mutation(
    $id: String
  ){
    removePaymentMethod(
      id : $id
    ){
      ok
    }
  }
`
;

const paymentMethodSetDefault = gql`
  mutation(
    $id: String
  ){
    setDefaultPaymentMethod(
      id : $id
    ){
      ok
    }
  }
`
;


export default compose(
  graphql(paymentMethodCreate,{
    name: 'create',
  }),
  graphql(paymentMethodRemove,{
    name:'delete',
  }),
  graphql(queryPaymentMethod,{
    name:'paymentMethod',
  }),
  graphql(paymentMethodSetDefault,{
    name:'setDefault',
  }),
  graphql(stripeCustomer,{
    name:'customer',
  }),
  graphql(stripePaymentIntent,{
    name:'paymentIntent',
  })
)(Form.create()(PaymentMethod));