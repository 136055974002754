import { Form , Input , Select , AutoComplete, Icon, Result, Typography, message} from "antd";
import gql from "graphql-tag";
import React from "react";
import { Titlebar } from "../../components";
import CustomInput from "../../components/customInput";
import { EditButtons, FormWrapper } from "../../components/form";
import { graphql } from 'react-apollo';
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from '../../layouts/app/layoutContent';
import StateList from "../../helpers/statesDataList";
import { Divider } from "rc-menu";
import TextArea from "antd/lib/input/TextArea";
import _, { flowRight as compose } from 'lodash';

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />
const {Title, Paragraph} = Typography

const AccountForm = (props) => {
  const {form, user: {currentLogin, loading, refetch},update} = props
  const { getFieldProps, getFieldDecorator } = form;

  const handleSubmit =  (e) => {
    e.preventDefault();	
    form.validateFields(async(err, values) => {
      if (!err) {
        await update({	
          variables: { ...values },	
        }).then(function(result){
          message.success("Successfully updated.")
          refetch()
        })	
      }
      else {

      }
    })
  }
  
  return <FormWrapper>
      <Form layout="vertical" onSubmit={handleSubmit}>
        
      <Typography>
        <Title level={4} className={'form-title'}>User details</Title>
          <Paragraph>
            This information is presented to your clients.
          </Paragraph>
      </Typography>

        <FormItem
          label="Email"
        >
          {getFieldDecorator('email', {
            initialValue: currentLogin.email,
            rules: [{
              message: 'A user email is required.',
              required: true,
            }],
          })(<Input readOnly placeholder="Email" />)}
        </FormItem>
        <FormItem
          className="input-small"
          label="First Name"
        >
          {getFieldDecorator('firstName', {
            initialValue: currentLogin.firstName,
            rules: [{
              message: 'A user first name is required.',
              required: true,
            }],
          })(<Input placeholder="First Name"/>)}
        </FormItem>
        <FormItem
          className="input-small"
          label="Last Name"
        >
          {getFieldDecorator('lastName', {
            initialValue: currentLogin.lastName,
            rules: [{
              message: 'A user last name is required.',
              required: true,
            }],
          })(<Input placeholder="Last Name" />)}
        </FormItem>
        <FormItem
          className="input-small"
          label="Address"
        >
          {getFieldDecorator('address', {
            initialValue: currentLogin.address,
          })(<Input placeholder="Address" />)}
        </FormItem>
        <FormItem
          className="input-small"
          label="City"
        >
          {getFieldDecorator('city', {
            initialValue: currentLogin.city,
          })(<Input placeholder="City" />)}
        </FormItem>
        <FormItem
          className="input-xsmall"
          label="State"
        >
          {getFieldDecorator('state', {
            initialValue: currentLogin.state,
          })(<AutoComplete
            placeholder="Select a State"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {(StateList || []).map(state => (
              <AutoCompleteOption key={state.abbr} value={state.abbr}>{state.name}</AutoCompleteOption>
            ))}
          </AutoComplete>)}
        </FormItem>
        <FormItem
          className="input-xsmall"
          label="Zip Code"
        >
          {getFieldDecorator('zipCode', {
            initialValue: currentLogin.zipCode,
          })(<Input placeholder="Zip Code" />)}
        </FormItem>
        <FormItem
          className="input-phone__wrapper"
          label="Phone Number"
        >
          <InputGroup>{getFieldDecorator('phoneNumber', {
            initialValue: "",
          })(<div className="input-phone__input">
            {getFieldDecorator('p1', {
              initialValue: currentLogin.phoneNumber ? currentLogin.phoneNumber.substring(0, 3) : ""
            })(<Input maxLength={3} placeholder="867" />)}
            <span>&#8211;</span>
            {getFieldDecorator('p2', {
              initialValue: currentLogin.phoneNumber ? currentLogin.phoneNumber.substring(3, 6) : ""
            })(<Input maxLength={3} placeholder="530" />)}
            <span>&#8211;</span>
            {getFieldDecorator('p3', {
              initialValue: currentLogin.phoneNumber ? currentLogin.phoneNumber.substring(6, 10) : ""
            })(<Input maxLength={4} placeholder="9999" />)}
          </div>)}
          </InputGroup>
        </FormItem>
        <div className="form-toolbar">
            <EditButtons
              cancelUrl={null}
              loading={loading}
              onSubmit={handleSubmit}
              rights={['superAdmin','EditUser']}
              deleteRights={'NoDelete'}
            />
        </div>
        </Form>
      </FormWrapper>

}

const AccountPage = (props) => {
    const {form, user: {currentLogin, loading}} = props
    const { getFieldProps, getFieldDecorator } = form;


 
      return <div>
        <Titlebar
          displayText="Account"
        />
        <LayoutContentWrapper>
          <LayoutContent >
            {(loading && 
              <Result
                icon={antIcon}
                title="Loading ..."
              />
            )}
            {!loading && 
              <AccountForm {...props} />
            }
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    // return 
    
}




const userQuery = gql`
  query {
    currentLogin {
      id
      accountConfirmToken
      accountConfirmTokenExpires
      address
      city
      email
      firstName
      isGlobalAdmin
      isPracticeAdmin
      isPortalAdmin
      lastLogin
      lastName
      phoneNumber
      practice
      portalid
      practiceId
      role
      roleId
      state
      status
      zipCode
      assessmentNotification
      paid
      customerStripeId
    }
  }
`
;

const updateAccount = gql`
  mutation(
    $address: String
    $city: String
    $email: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $state: String
    $zipCode: String
    $subscriptions: String
  ){
    editAccount(
      address: $address
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      state: $state
      zipCode: $zipCode
      subscriptions: $subscriptions
    ){
      ok
    }
  }
`
;

export default compose(
  graphql(userQuery,{
    name:'user',
  }),
  graphql(updateAccount,{
    name:'update',
  })
)(Form.create()(AccountPage));