import styled from 'styled-components';
import { palette } from 'styled-theme';

const ContentWrapper = styled.div`
  min-height: 100vh;

  .button-bar-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 24px 40px 0 0;
    width: 100%;
    @media only screen and (max-width: 767px) {
      padding-right: 24px;
    }

    &.has-dropdown {
      .ant-dropdown-trigger .anticon:not(.anticon-ellipsis),
      .ant-dropdown-link .anticon:not(.anticon-ellipsis) {
        font-size: 14px;
        transform: scale(1);
      }
      &.download {
        .ant-dropdown-trigger .anticon:not(.anticon-ellipsis),
        .ant-dropdown-link .anticon:not(.anticon-ellipsis) {
          font-size: 16px;
        }
      }
    }
  }

  .layout {
    background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
    padding-left: 256px;
    transition: all .2s;
    &.collapsed {
      padding-left: 0;
      transition: all .2s;
    }
    /* @media only screen and (max-width: 767px) { */
    @media only screen and (max-width: 1023px) {
      padding-left: 0;
      &.collapsed {
        padding-left: 0;
      }
    }

    .ant-layout-content {
      .content-wrapper {
        margin-top: 64px;
        min-height: calc(100vh - 108px);
        /* padding: 24px; */
        padding: 0 0 24px 0;
        /* margin: 64px 40px 40px; */
        /* min-height: calc(100vh - 98px - 85px - 24px); */
      }
    }
    .ant-layout-footer {
      background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
      color: rgba(0,0,0,0.45);
      /* padding: 24px; */
      padding: 0 24px 24px;
      text-align: center;
    }
    /* .ant-layout-header {
      align-items: center;
      background: ${palette('base', 0)};
      border-bottom: 1px solid rgb(232, 232, 232);
      display: flex;
      height: 64px;
      justify-content: space-between;
      line-height: 32px;
      padding: 0 40px;
    } */
    /* .ant-menu {
      border-bottom: none;
      line-height: 62px;

      .ant-menu-item {

        >a {
          &:hover {
            color: ${palette('primary', 0)};
          }
        }
      }

      .ant-menu-item-selected {
        >a {
          color: ${palette('primary', 0)};
          &:hover {
            color: ${palette('primary', 0)};
          }
        }
      }
    } */

    /* .ant-menu-horizontal {
      >.ant-menu-item {
        border-bottom: none;
        font-weight: 600;
        &:hover {
          border-bottom: none;
        }
      }
      >.ant-menu-item-selected {
        border-bottom: none;
      }
    } */

    .loading-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      /* min-height: calc(100vh - 98px - 58px); */
      min-height: calc(100vh - 98px - 140px);
      .ant-spin-dot {
        color: rgba(0,111,186,1);
      }
    }
  }

  .menu-trigger-wrapper {
    align-items: center;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    color: rgba(101,122,133,1);
    display: flex;
    height: 40px;
    justify-content: center;
    position: fixed;
    transition: all .2s;
    top: 136px;
    z-index: 1;
    &.collapsed {
      /* background: rgba(188,198,218,1); */
      background: rgba(255,255,255,1);
      left: 0;
      width: 40px;
    }
    i {
      &.trigger {
        cursor: pointer;
        font-size: 18px;
        padding: 11px;
        transition: all .2s;
        &.collapsed {
          /* color: rgba(255,255,255,1); */
          margin-left: -4px;
        }
      }
    }
  }
`;

export default ContentWrapper;
