import styled from 'styled-components';

export const  UserMenuWrapper = styled.div`
  align-items: center;
  display: flex;
  .user-menu {
    &:not(:last-child) {
      cursor: pointer;
      font-size: 18px;
      margin-right: 16px;
      margin-top: 6px;
      transition: all .2s;
      &.notification {
        margin-right: 24px;
        margin-top: 12px;
        transition: all .2s;
        svg {
          font-size: 18px;
        }
      }
    }
    .user-menu-dropdown-link {
      cursor: pointer;
    }
    i {
      color: rgba(101,122,133,1);
    }
  }
`;

export const UserCardWrapper = styled.div`
  .profile-link {
    text-align:left;
    box-shadow:none;
    background-color:transparent;
    border-color:transparent;
    color:black
  }

  .profile-link:hover {
    background-color:rgba(0,111,186,1)!important;
    border-color:transparent;
    color:white!important
  }
  


`;
