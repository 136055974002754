import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import decode from 'jwt-decode';
import App from './layouts/app';
import {
  UsersAccountConfirm,
  UsersLogin,
  UsersPasswordForgot,
  UsersPasswordReset,
  UsersSignup,
  Where,
  PaymentMethod,
  AssessmentLink
} from './containers/users';
import Subscriptions from './containers/stripe/subscriptions';
import SuccessPage from './containers/stripe/successPage';

/* check for authenticated user based on token & refresh token status */
let currentUser = null;

const isAuthenticated = (Component,props) => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  /* check for token */
  if (token) {
    try {
      /* catch for no user practices - send to login */
      const { user: { practices, paid, id, customerStripeId } } = decode(token);
      const { exp } = decode(refreshToken);


      if (Date.now() / 1000 > exp) {
        return <Redirect to={{ pathname: '/login' }} />
      }

      // if(!paid){
      //   return <Redirect to={{ pathname: `/subscription`}} />
      // }

      if (!practices) {
        return <Redirect to={{ pathname: '/login' }} />
      }

      /* catch for expired token - send to login */
      
    } catch (err) {
      return <Redirect to={{ pathname: '/login' }} />
    }

    currentUser = decode(localStorage.getItem('token')).user;

    return <Component {...props} user={currentUser} />
  }

  return <Redirect to={{ pathname: '/login' }} />
};

/* private route template, props current user from confirmed token */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (isAuthenticated(Component,props)
        // ? (<Component {...props} user={currentUser} />)
        // : (<Redirect to={{ pathname: '/login' }} />)
      )
    }
  />
);

/* react router setup for routing before user is authenticated */
const PublicRoutes = () => (
  <BrowserRouter>
    <Switch>
      <Route
        component={UsersLogin}
        exact
        path="/login"
      />
      <Route
        component={AssessmentLink}
        exact
        path="/survey/:practiceId/:userId"
      />
      <Route
        component={UsersSignup}
        exact
        path="/signup"
      />
      <Route
        component={PaymentMethod}
        exact
        path="/paymentmethod"
      />
      <Route
        component={Subscriptions}
        exact
        path="/subscription"
      />
      <Route
        component={SuccessPage}
        exact
        path="/payment/success"
      />
      
      <Route
        component={Where}
        exact
        path="/where"
      />
      <Route
        component={UsersAccountConfirm}
        exact
        path="/account/confirm/:token"
      />
      <Route
        component={UsersPasswordForgot}
        exact
        path="/password/forgot"
      />
      <Route
        component={UsersPasswordReset}
        exact
        path="/password/reset/:token"
      />
      <PrivateRoute
        component={App}
        path="/"
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);

export default PublicRoutes;
