const Admin = ({ user, location }) => {
  let urlValidation = false
  let returnLogin = false
  let returnUrl = '/'

  const top = ['practices', 'users', 'companies']
  const bottom = [
    'advisors',
    'assessments',
    'clients',
    'practices',
    'users',
    'teams',
  ]
  const crud = ['add', 'edit', 'view']
  const pathname = location.pathname.split('/')
  const company = localStorage.getItem('current_company')
  const practice = localStorage.getItem('current_practice')

  if (company && company !== undefined && !(company == 'null')) {
    returnUrl = `/`
  } else {
    if (user.role[0].id != 1) {
      returnUrl = `/login`
    }
  }

  if (pathname[1] && pathname[1] !== undefined) {
    if (top.includes(pathname[1])) {
      if (pathname[2] && pathname[2] !== undefined) {
        if (crud.includes(pathname[2]) || company === pathname[2]) {
          urlValidation = true
        } else {
          urlValidation = false
        }
      } else {
        urlValidation = true
      }
    } else if (practice && practice !== undefined) {
      if (practice === pathname[1]) {
        if (pathname[2] && pathname[2] !== undefined) {
          if (bottom.includes(pathname[2])) {
            urlValidation = true
          } else {
            urlValidation = false
          }
        } else {
          urlValidation = true
        }
      } else {
        urlValidation = false
      }
    } else {
      urlValidation = false
    }
  } else {
    if (user.role[0].id == 1) {
      localStorage.setItem('current_company', null)
      localStorage.setItem('current_practice', null)
    }
    urlValidation = true
  }

  if (pathname[2] === 'clients' && pathname[3] === 'print') {
    urlValidation = true
    returnLogin = false
  }

  return {
    urlValidation,
    returnLogin,
    returnUrl,
  }
}

const Users = ({ user, location }) => {
  let urlValidation = false
  let returnLogin = false
  let returnUrl = '/login'

  const bottom = [
    'advisors',
    'assessments',
    'clients',
    'practices',
    'users',
    'teams',
    '',
    'account',
    'general',
    'subscription',
  ]
  const crud = ['add', 'edit', 'view']
  const pathname = location.pathname.split('/')
  const practice = localStorage.getItem('current_practice')

  if (practice && practice !== undefined && !(practice == 'null')) {
    returnUrl = `/${practice}`
    if (pathname[1] && pathname[1] !== undefined) {
      if (practice === pathname[1] || pathname[1] == 'settings') {
        if (pathname[2] && pathname[2] !== undefined) {
          if (bottom.includes(pathname[2])) {
            if (pathname[3] && pathname[3] !== undefined) {
              if (crud.includes(pathname[3])) {
                urlValidation = true
              } else {
                urlValidation = false
              }
            } else {
              urlValidation = true
            }
          } else {
            urlValidation = false
          }
        } else {
          urlValidation = true
        }
      } else {
        urlValidation = false
      }
    } else {
      urlValidation = false
    }
  } else {
    returnLogin = true
    urlValidation = false
  }

  if (pathname[2] === 'clients' && pathname[3] === 'print') {
    urlValidation = true
    returnLogin = false
  }

  return {
    urlValidation,
    returnLogin,
    returnUrl,
  }
}

const SecurityChecker = ({ user, location }) => {
  let response = {
    urlValidation: false,
    returnLogin: true,
    returnUrl: '/login',
  }

  if (user.role) {
    switch (user.role[0].id) {
      case 1: //Global Admin
        response = Admin({ user, location })
        break
      case 2: //Practice Admin
        response = Users({ user, location })
        break
      case 3: //Advisor
        response = Users({ user, location })
        break
      case 4: //Company Admin
        response = Admin({ user, location })
        break
      case 5: //Company Support
        response = Admin({ user, location })
        break
      case 6: //Practice Support
        response = Users({ user, location })
        break
      default:
        response = {
          urlValidation: true,
        }
        break
    }
  }

  return response
}

export default SecurityChecker
