import React from 'react';
import LayoutContent from '../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../layouts/app/layoutWrapper';

class PageNotFound extends React.Component {
  render() {
    return (
      <LayoutContentWrapper>
        <LayoutContent>
          <div>
            Itza 404.
          </div>
        </LayoutContent>
      </LayoutContentWrapper>
    );
  }
}

export default PageNotFound;
