import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Form, Icon, Input, Spin } from 'antd';
import { practiceConfig } from '../../../config';
import { Table } from '../../../elements';
import { ButtonBar, Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import { FormatPhoneNumber } from '../../../helpers/formatters';

const FormItem = Form.Item;
const InputSearch = Input.Search;

class ClientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPractice: this.props.currentPractice !== 'clients'
        ? this.props.currentPractice
        : null,
      limit: practiceConfig.rowsPerPage,
      loading: false,
      order: null,
      orderColumn: 'firstName',
      page: 1,
      search: null,
      totalCount: null,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleSearch(searchParams) {
    const { type } = searchParams;
    let search = searchParams;

    if (type === 'clear') {
      search = null;

      this.props.form.resetFields();
    }

    this.fetchData(
      this.state.page,
      this.state.limit,
      this.state.order,
      this.state.orderColumn,
      search,
    );

    this.setState({ search });
  }

  handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === 'descend' ? 'DESC' : 'ASC';

    this.fetchData(
      !pagination.current ? this.state.page : pagination.current,
      !pagination.pageSize ? this.state.limit : pagination.pageSize,
      sorter ? sorterOrder : this.state.order,
      sorter ? sorter.field : this.state.orderColumn,
      this.state.search,
    );

    this.setState({
      limit: !pagination.pageSize ? this.state.limit : pagination.pageSize,
      page: !pagination.current ? this.state.page : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
    });
  }

  fetchData(page, limit, order, orderColumn, search) {
    this.setState({ loading: true });

    const offset = (page - 1) * limit;
    this.props.data.fetchMore({
      variables: {
        limit,
        offset,
        order,
        orderColumn,
        search,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({ loading: false });
        // console.log('prev', previousResult)
        // console.log('new: ', fetchMoreResult)

        if (!fetchMoreResult) {
          // console.log('No fetch more result, show prev: ', previousResult)
          return previousResult;
        }
        this.setState({
          totalCount: fetchMoreResult.getAllClients.pagination.totalCount,
        });
        // console.log('show fetchmore: ', previousResult)
        return fetchMoreResult;
      },
    });
  }

  render() {
    const { currentPractice, limit, page } = this.state;
    const {
      data: { loading, error, getAllClients = null },
      form: { getFieldDecorator },
    } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Clients"
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Clients"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - clients didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        dataIndex: 'firstName',
        render: (text, client) => (
          <Link
            className={client.status === 'Inactive' ? 'inactive' : null}
            to={`/${client.practiceName}/clients/view/${client.id}`}
          >
            {client.firstName} {client.lastName}
          </Link>
        ),
        sorter: true,
        title: 'Client',
      },
      {
        dataIndex: 'city',
        render: (text, row) => (row.city && row.state ? `${row.city}, ${row.state}` : ''),
        sorter: true,
        title: 'Location',
      },
      {
        dataIndex: 'email',
        sorter: true,
        title: 'Email',
      },
      {
        dataIndex: 'phoneNumber',
        render: text => FormatPhoneNumber(text),
        sorter: true,
        title: 'Phone',
      },
    ];

    if (currentPractice) {
      columns.push({
        className: 'action-column',
        fixed: 'right',
        render: client => (
          <Link to={`/${currentPractice}/clients/edit/${client.id}`}>
            <Icon type="edit" />
          </Link>
        ),
      });
    }

    const { clients, pagination: { totalCount = 0 } } = getAllClients;
    const tableCount = this.state.totalCount > 0 ? this.state.totalCount : totalCount;
    let tablePagination = false;

    if (tableCount > practiceConfig.rowsPerPage) {
      tablePagination = {
        current: page,
        defaultPageSize: practiceConfig.rowsPerPage,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: tableCount,
      };
    }

    const countStart = ((page - 1) * limit) + 1;
    const countEnd = page * limit > tableCount ? tableCount : page * limit;
    const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : 'No Records';

    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText="Clients"
        />
        {currentPractice ?
          <div className="button-bar-wrapper">
            <ButtonBar
              icon="plus"
              link={`/${currentPractice}/clients/add`}
              text="Add New"
            />
          </div> : null}
        <LayoutContentWrapper>
          <div className="display-bar with-search">
            {display}
          </div>
          <div className="search-bar">
            <Form>
              <FormItem className="oka-list-search">
                {getFieldDecorator('search')(
                  <InputSearch
                    autoComplete="off"
                    className={this.state.search ? 'has-search' : null}
                    enterButton
                    onSearch={this.handleSearch}
                    placeholder="Search..."
                    suffix={this.state.search
                      ? <Icon key="icon" onClick={() => this.handleSearch({ type: 'clear' })} type="close-circle-o" />
                      : <span />}
                  />)}
              </FormItem>
            </Form>
          </div>
          <LayoutContent>
            <Table
              columns={columns}
              dataSource={clients}
              loading={this.state.loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }}
              locale={{ emptyText: 'No clients found.' }}
              onChange={this.handleTableChange}
              pagination={tablePagination ? { ...tablePagination } : false}
              rowKey={c => (c.id + "-" + c.practiceName)}
              scroll={{ x: columns.length * 150 }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const allClientsQuery = gql`
  query (
    $limit: Int
    $offset: Int
    $order: String
    $orderColumn: String
    $practiceId: String
    $search: String
  ){
    getAllClients(
      limit: $limit
      offset: $offset
      order: $order
      orderColumn: $orderColumn
      practiceId: $practiceId
      search: $search
    ){
      clients {
        id
        city
        email
        firstName
        lastName
        phoneNumber
        practiceName
        state
      }
      pagination {
        totalCount
      }
    }
  }
`;

export default graphql(allClientsQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      limit: practiceConfig.rowsPerPage,
      practiceId: props.currentPractice !== 'clients'
        ? props.currentPractice
        : null,
    },
  }),
})(Form.create()(ClientList));
