import React, {useContext} from 'react';
import {AccountConsumer, AccountContext} from "./AccountContext";
import * as PropTypes from "prop-types";
import _ from 'lodash'


AccessControl.propTypes = {
	rights: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.node,
	renderNoAccess: PropTypes.node,
};

AccessControl.defaultProps = {
	rights: [],
	renderNoAccess: null,

};


export default function AccessControl({ rights, children, renderNoAccess }) {

	const accountContext = useContext(AccountContext);
	let permissions = _.get(accountContext.account,'permissions',[]);
	return (
		<>
			{ checkPermissions(rights,permissions) ? children : renderNoAccess }
		</>
	);
}

export function checkPermissions(allowedPermissions, data) {
	if (allowedPermissions.length === 0) {
		return false;
	}
	return data.some(permission => allowedPermissions.includes(permission));
}
