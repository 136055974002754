import React from 'react';
import { Redirect } from 'react-router-dom';
import { Drawer, Icon, Layout } from 'antd';
import { debounce } from 'lodash';
import { practiceConfig } from '../../config';
import { HeaderBar, Sidebar } from '../../components';
import ScrollToTop from './helpers/scrollToTop';
import Router from './router';
import BuildUserProps from '../../helpers/buildUserProps';
import RouteConverter from '../../helpers/routeConverter';
import ContentWrapper from './index.style';
import {  graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import gql from 'graphql-tag';
import {AccountContext} from "../../context/AccountContext";
import decode from "jwt-decode";
import _ from 'lodash';
import SecurityChecker from '../../helpers/securityChecker';
import moment from 'moment';

const { Content, Footer } = Layout;

class App extends React.Component {
  static contextType = AccountContext

  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      width: window.innerWidth,
    };

    this.menuToggle = this.menuToggle.bind(this);
    this.resize = this.resize.bind(this);
    this.resizeToggle = this.resizeToggle.bind(this);
  }

  componentDidMount() {
    this.resizeToggle();
    window.addEventListener('resize', debounce(this.resizeToggle, 200));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeToggle);
  }

  componentDidUpdate(prevProps){

    const userContext = this.context;
    if(!_.isEmpty(this.props.data.me)){
      let account = _.get(this.props.data,'me',[])
      userContext.setAccount(account);
    }

    if(this.props !== prevProps){
      let currentUser = decode(localStorage.getItem('token')).user;
      if(currentUser){
        userContext.setProfile(currentUser);
      }
    }

  }

  menuToggle() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  resize(innerWidth) {
    if (innerWidth < 1024) {
      return true;
    }

    return false;
  }

  resizeToggle() {
    const { innerWidth } = window;

    this.setState({
      collapsed: this.resize(innerWidth),
      width: innerWidth,
    });
  }

  render() {
    /* prop the pathname for setting Titlebar component state */
    const {
      currentPractice, defaultTitle, openKeys, selectedKeys,
    } = RouteConverter(this.props.location.pathname);

    /* generate global user props */
    const { user, location, userSubscription: {getUserSubscription} , data : {me} } = this.props;
    // console.log(user,'user')
    // console.log(me,'me')

    if(getUserSubscription){

      if(getUserSubscription.status == 'incomplete' && location.pathname !== '/settings/subscription'){
        return <Redirect from="/" to={`/settings/subscription`} />;
      }

    }

    const {urlValidation,returnUrl,returnLogin} = SecurityChecker({user,location});

    if(!urlValidation)
    {
        return <Redirect from="/" to={`${returnUrl}`} />;
    }

    const {
      isPracticeMember, showPracticeList, userRole,
    } = BuildUserProps(user, currentPractice, openKeys);
    /* handle redirect if trying to access practice user is not a member of */
    // if (!isPracticeMember && !(user.isGlobalAdmin || user.isPortalAdmin)) {
    //   return <Redirect from="/" to='/login' />;
    // }
    /* send requests for pdf/print view of events/activities to full screen */
    if (defaultTitle.includes('titlebar.clientsPrint')) {
      return (
        <Router
          currentPractice={currentPractice}
          gblUserId={user.id}
          gblUserIsGlobalAdmin={user.isGlobalAdmin}
          gblUserIsPracticeAdmin={user.isPracticeAdmin}
          gblUserIsPortalAdmin={user.isPortalAdmin}
          gblUserRole={user.role[0].id}
          userPracticeList={user.practices}
          userPortals={user.portals}
        />
      );
    }

    return (
      <ContentWrapper>
        <HeaderBar
          collapsed={this.state.collapsed}
          currentPractice={currentPractice}
        />
        {/* show trigger outside drawer when menu sidebar is collapsed */}
        {this.state.width < 1024 && this.state.collapsed ?
          <div className="menu-trigger-wrapper collapsed">
            <Icon
              className="trigger collapsed"
              onClick={this.menuToggle}
              type="menu-unfold"
            />
          </div> : null}
        <Layout>
          {this.state.width > 1023 ?
            <Sidebar
                {...this.props}
              collapsed={this.state.collapsed}
              currentPractice={currentPractice}
              gblUserId={user.id}
              gblUserIsGlobalAdmin={user.isGlobalAdmin}
              gblUserIsPracticeAdmin={user.isPracticeAdmin}
              gblUserIsPortalAdmin={user.isPortalAdmin}
              gblUserRole={user.role[0].id}
              menuToggle={this.menuToggle}
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              showPracticeList={showPracticeList}
            /> :
            <Drawer
              bodyStyle={{ background: 'rgb(255,255,255)', padding: 0 }}
              closable={false}
              onClose={this.menuToggle}
              placement="left"
              style={{ padding: 0 }}
              visible={!this.state.collapsed}
              width={256}
            >
              {/* show trigger inside drawer when menu sidebar is expanded */}
              {!this.state.collapsed ?
                <div className="menu-trigger-wrapper expanded">
                  <Icon
                    className="trigger expanded"
                    onClick={this.menuToggle}
                    type="close"
                  />
                </div> : null}
              <Sidebar
                  {...this.props}
                collapsed={this.state.collapsed}
                currentPractice={currentPractice}
                gblUserId={user.id}
                gblUserIsGlobalAdmin={user.isGlobalAdmin}
                gblUserIsPracticeAdmin={user.isPracticeAdmin}
                gblUserIsPortalAdmin={user.isPortalAdmin}
                gblUserRole={user.role[0].id}
                isMobile
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                showPracticeList={showPracticeList}
              />
            </Drawer>}
          <Layout className={this.state.collapsed ? 'layout collapsed' : 'layout'}>
            <Content>
              <div className="content-wrapper">
                <ScrollToTop>
                  <Router
                    firstLogin={me ? moment(me.firstLogin).startOf('day').format('YYYY-MM-DD') : ''}
                    firstName={me ? me.firstName : ''}
                    currentPractice={currentPractice}
                    gblUserId={user.id}
                    gblUserIsGlobalAdmin={user.isGlobalAdmin}
                    gblUserIsPracticeAdmin={user.isPracticeAdmin}
                    gblUserIsPortalAdmin={user.isPortalAdmin}
                    gblUserRole={user.role[0].id}
                    title={defaultTitle}
                    sidebarCollapsed={this.state.collapsed}
                    userPracticeList={user.practices}
                    userPortals={user.portals}
                  />
                </ScrollToTop>
              </div>
            </Content>
            <Footer>
              {practiceConfig.footerText}
            </Footer>
          </Layout>
        </Layout>
      </ContentWrapper>
    );
  }
}

const currentUserQuery = gql`
query {
    me {
      id
      firstName
      lastName
      permissions
      portalid
      roleId
      firstLogin
      notifications {
        clientDetails {
          id
          dateCollected
          name
          practiceId
          transferredClient
        }
        count
      }
    }
  }
`;

const getUserSubscription = gql`
  query {
    getUserSubscription {
      id
      status
      start_date
      current_period_end
      current_period_start
      
    }
  }
`;

//export default App;

export default compose(
    graphql(currentUserQuery, {
      options: {
        fetchPolicy: 'network-only',
      },
    }),
    graphql(getUserSubscription, {
      name: 'userSubscription'
    })
)(App);
