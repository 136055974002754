import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import decode from 'jwt-decode'
import { Button, Form, Input } from 'antd'
import IntlMessages from '../../../helpers/intlMessages'
import { FormWrapper } from '../../../components/form'
import PublicContent from '../../../layouts/public'

import { AccountContext } from '../../../context/AccountContext'
const FormItem = Form.Item

class LoginForm extends React.Component {
  static contextType = AccountContext

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    /* blank both tokens in local storage to prevent spoofing */
    localStorage.setItem('current_company_id', null)
    localStorage.setItem('current_company', null)
    localStorage.setItem('current_practice_id', null)
    localStorage.setItem('current_practice', null)
    localStorage.setItem('token', null)
    localStorage.setItem('refreshToken', null)
    localStorage.setItem('currentPractice', null)
  }

  handleSubmit = (event) => {
    const userContext = this.context

    event.preventDefault()

    const { form, history, mutate } = this.props

    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })

        const { email, password } = values

        const response = await mutate({
          variables: { email, password },
        })

        const {
          ok,
          token,
          refreshToken,
          errors,
          userPermissions,
        } = response.data.loginUser
        /**
         ** if the user exists store a token & refreshToken in the user's localStorage
         ** if user does not exist send errors to form based on type
         ** if user belongs to more than one practice or is a global admin,
         ** send to practice list else to practice dashboard
         */
        if (ok) {
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          const { user } = decode(token)
          const {
            isGlobalAdmin,
            isPortalAdmin,
            practices,
            portals,
            role,
          } = user
          userContext.setUserPermission(userPermissions)
          userContext.setAccount(user)
          //console.log("user",user);

          if (user.role[0]) {
            if (isGlobalAdmin) {
              history.push('/')
            } else if ([4, 5].includes(user.role[0].id)) {
              localStorage.setItem('current_company_id', portals[0].id)
              localStorage.setItem('current_company', portals[0].slug)
              history.push(`/`)
            } else {
              // assume that there's practices
              if (practices.length > 1) {
                history.push('/where')
              } else {
                localStorage.setItem('current_company_id', portals[0].id)
                localStorage.setItem('current_company', portals[0].slug)
                localStorage.setItem('current_practice_id', practices[0].id)
                localStorage.setItem(
                  'current_practice',
                  practices[0].practiceId
                )
                history.push(`/${practices[0].practiceId}`)
              }
            }
          } else {
            alert('No access found.')
            history.push('/login')
          }

          this.setState({ loading: false })
        } else {
          this.setState({ loading: false })

          /* blank both tokens in local storage to prevent spoofing */
          localStorage.setItem('token', null)
          localStorage.setItem('refreshToken', null)
          localStorage.setItem('currentPractice', null)

          errors.forEach(({ path }) => {
            if (path === 'email') {
              form.setFields({
                email: {
                  value: email,
                  errors: [
                    new Error(
                      "The email you've entered does not match any account."
                    ),
                  ],
                },
              })
            }

            if (path === 'userPending') {
              form.setFields({
                email: {
                  value: email,
                  errors: [new Error('Your account has not been confirmed.')],
                },
              })
            }

            if (path === 'userInactive') {
              form.setFields({
                email: {
                  value: email,
                  errors: [new Error('Your account is not active.')],
                },
              })
            }

            if (path === 'password') {
              form.setFields({
                password: {
                  value: password,
                  errors: [
                    new Error("The password you've entered is incorrect."),
                  ],
                },
              })
            }
          })
        }
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { loading } = this.state

    return (
      <PublicContent className='stretch-content'>
        <div className='public-form-wrapper'>
          <FormWrapper className='public-form'>
            <Form layout='vertical' onSubmit={this.handleSubmit}>
              <FormItem label='Email Address'>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      message: 'Email is required.',
                      required: true,
                    },
                  ],
                })(<Input autoCorrect='off' autoCapitalize='none' />)}
              </FormItem>
              <FormItem
                className='uni-password-label'
                label='Password'
                labelCol={{ span: 12 }}
              >
                {getFieldDecorator('password', {
                  rules: [
                    {
                      message: 'Password is required.',
                      required: true,
                    },
                  ],
                })(<Input type='password' />)}
              </FormItem>
              <FormItem>
                <Button htmlType='submit' loading={loading} type='primary'>
                  <IntlMessages id='login.login' />
                </Button>
              </FormItem>
            </Form>
            <div className='uni-forgot__wrapper'>
              <Link to='/password/forgot'>
                <IntlMessages id='login.forgotPassword' />
              </Link>
            </div>
            <br />
            <div style={{ textAlign: 'left' }}>
              <p>New to Unitifi?</p>
            </div>
            <div className='uni-forgot__wrapper' style={{ padding: 0 }}>
              <Link to='/signup'>Create Account</Link>
            </div>
          </FormWrapper>
        </div>
      </PublicContent>
    )
  }
}

LoginForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
}

const loginUserMutation = gql`
  mutation($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      ok
      token
      userPermissions {
        permissionName
      }
      refreshToken
      errors {
        path
      }
    }
  }
`
export default graphql(loginUserMutation)(Form.create()(LoginForm))
