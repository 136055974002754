import styled from 'styled-components';
import { palette } from 'styled-theme';

const SelfReportedMapWrapper = styled.div`
  display: flex;

  .chart {
    height: 100%;
    width: calc(100% - 32px);
  }

  .top-legend {
    border: 1px solid rgba(238,242,244,1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    position: absolute;
    width: 120px;
    z-index: 2;

    .score-item {
      align-items: center;
      display: flex;
      flex-direction: column;

      &:first-child {
        margin-bottom: 8px;
      }

      .risk-behavior-score {
        color: rgba(0,111,186,1);
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
      }

      .self-reported-score {
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
      }

      .score-label {
        font-size: 14px;
      }
    }
  }

  .bottom-legend {
    display: flex;
    margin-left: 10px;
    width: calc(100% - 20px);

    div {
      align-items: center;
      color: ${palette('base', 0)};
      display: flex;
      justify-content: center;
      line-height: 16px;
      padding: 8px;
      text-align: center;
      width: 20%;

      &:first-child {
        background: #589bcb;
      }

      &:nth-child(2) {
        background: #3485be;
      }

      &:nth-child(3) {
        background: #1072b7;
      }

      &:nth-child(4) {
        background: #075891;
      }

      &:nth-child(5) {
        background: #054471;
      }
    }
  }

  .bottom-wrapper {
    width: 32px;
    div {
      align-items: center;
      background: #054471;
      color: ${palette('base', 0)};
      display: flex;
      height: calc(50% - 40px);
      justify-content: center;
      width: 32px;
      &:last-child {
        background: #589bcb;
        border-top: none;
        height: calc(50% - 46px);
      }
      span {
        transform: rotate(90deg);
      }
    }
  }
`;

export default SelfReportedMapWrapper;
