import styled from 'styled-components';
import { palette } from 'styled-theme';

const PublicContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* position:fixed; */
  text-align: center;
  /* top: 0; */
  width: 100%;
  &.stretch-content {
    height: 100vh;
    overflow: scroll;
  }
  .ant-spin-spinning {
    width: 100%;
  }
  .loading-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    .ant-spin-dot {
      color: rgba(0,111,186,1);
    }
  }
  .public-footer {
    background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
    color: ${palette('text', 2)};
    padding: 0 24px 24px;
    width: 100%;
  }
  .public-logo {
    margin-bottom: 24px;
    img {
      height: 38px;
      /* width: 160px; */
    }
  }
  .public-error-message {
    background: #fff1f0;
    border: 1px solid #ffa39e;
    border-radius: 4px;
    padding: 24px;
    .message {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
  .public-main {
    /* margin-top: -24px; */
    width: 336px;
    .public-form-wrapper {
      background: rgba(255,255,255,1);
      border: 1px solid rgba(238,242,244,1);
      border-radius: 4px;
      padding: 24px;
      .public-form {
        padding: 0;
      }
      .ant-btn {
        width: 100%;
      }
      .has-error {
        .ant-form-explain {
          text-align: left;
        }
      }
      .ant-form-vertical {
        .ant-form-item {
          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0px;
          }
          &:not(.show-required) {
            .ant-form-item-label {
              .ant-form-item-required {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
      .uni-back {
        display: flex;
        justify-content: flex-start;
        padding-top: 32px;
        i.uni-back-icon {
          font-size: 12px;
          margin-right: 8px;
        }
      }
      .uni-forgot__wrapper {
        display: flex;
        justify-content: flex-start;
        padding-top: 32px;
      }
      .uni-password-label {
        .ant-form-item-label {
          width: 50%;
        }
      }
      .public-message {
        margin-bottom: 32px;
        text-align: left;
      }
      .uni-input-password__input-confirm {
        flex: 0 0 100%;
      }
      .input-password-feedback-wrapper {
        flex: 0 0 100%;
      }
    }
  }
  .public-wrapper {
    align-items: center;
    background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 24px;
    width: 100%;
  }
`;

export default PublicContentWrapper;
