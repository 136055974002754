import React, {useContext, useEffect, useState} from 'react';
import gql from 'graphql-tag';
import {Steps, Icon, Row, Col, Layout, Card, Form, Input, Progress, Divider} from 'antd';
import { useQuery } from 'react-apollo';
import _, { stubFalse } from 'lodash';
import ContentWrapper from './index.style';
import HeaderBar from './HeaderBar';
import SignupForm from './steps/signupform'
import FullSignupForm from './steps/fullsignupform'
import Assessment from './steps/assessment';
import Done from './steps/done';
import logo from '../../../assets/img/unitifi.svg';

const { Content, Footer } = Layout;

export default Form.create()(AssessmentLink);

function AssessmentLink (props) {
  const {practiceId, userId} = props.match.params
  localStorage.setItem('token', null);
  localStorage.setItem('refreshToken', null);
  
  const [state,setState] = useState({
      email: ''
  })
  const [pages,setPages] = useState([
    {
      title : 'Registration',
      icon : <Icon type="user" />,
      status : false
    },
    {
      title : 'Assessment',
      icon : <Icon type="solution" />,
      status : false
    },
    {
      title : 'Done',
      icon : <Icon type="smile-o" />,
      status : false
    }
  ])
  const [activePage,setActivePage] = useState('signup')
  const { Step } = Steps;

  return (
      <>
      {
        (activePage === 'signup' && (
          <ContentWrapper >
          <div className={'center'}>
            <Card style={{marginTop:100}}>
                <SignupForm setActivePage={setActivePage} setState={setState}/>
            </Card>
          </div>
          </ContentWrapper>
        ))
      }

      {
        (activePage !== 'signup' && activePage !== 'assessment' &&(
          <ContentWrapper>
          <div className={'center-md'}>
                    <Card  bordered={false}  style={{marginTop:100}}>
                    <Col span={24} >
                            <img src={logo} />
                            <Divider />
                    </Col>
                      {
                        (activePage === 'fullSignup' && (
                          <FullSignupForm
                            practiceId={practiceId}
                            userId={userId}
                            pages={pages}
                            setPages={setPages}
                            setActivePage={setActivePage}
                            state={state}
                            setState={setState}/>
                        ))
                      }
                      {
                        (activePage === 'done' && (
                          <Done
                            practiceId={practiceId}
                            userId={userId}
                            pages={pages}
                            setPages={setPages}
                            setPages={setPages}
                            setActivePage={setActivePage}
                            state={state}
                            setState={setState}/>
                        ))
                      }
                    </Card>
          </div>
          </ContentWrapper>
        ))
      }

      {
        (activePage !== 'signup' && activePage === 'assessment' && (
          <ContentWrapper >
          <div className={'center-lg'}>
            <Card style={{marginTop:20}}>
              <Col span={24} >
                <div className={'logo-signup'}>
                <img src={logo} style={{height:'46px'}}/>
                </div>
              </Col>
              <Assessment
                  practiceId={practiceId}
                  userId={userId}
                  pages={pages}
                  setPages={setPages}
                  setPages={setPages}
                  setActivePage={setActivePage}
                  state={state}
                  setState={setState}/>
            </Card>
          </div>
          </ContentWrapper>
        ))
      }
      </>
  )
}