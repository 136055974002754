import React from 'react';
import gql from 'graphql-tag';
import {  graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { Icon, notification, Spin } from 'antd';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import PracticeForm from './form';

class PracticesEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      redirectTo: null,
      pageTitle: `Edit Practice Mailer Template`,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  toggleLoading(boolean) {
    this.setState({
      loading: boolean,
    });
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      id: this.props.data.getPractice.id,
    };

   // console.log('submit form');


    this.props.editPracticeMailer({ variables })
      .then(({ data }) => {
        if (data.editPracticeMailer.ok && !data.editPracticeMailer.errors) {
          this.handleSuccess(`${data.editPracticeMailer.practice.name} has been successfully updated.`);
          return;
        }

        this.handleError(data.editPracticeMailer.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: 'Practice Updated',
      description,
    });

    // this.setState({ redirectTo: '/practices' });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {
    const { data: { loading, error, getPractice } } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText={this.state.pageTitle}
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText={this.state.pageTitle}
          />
          <div className="loading-wrapper">
            <p>Whoopsie - practice didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText={this.state.pageTitle}
        />
        <LayoutContentWrapper>
          <LayoutContent>
            <PracticeForm
              loading={this.state.loading}
              onDelete={this.handleDeleteConfirm}
              onSubmit={this.handleSubmit}
              practice={getPractice}
              toggleLoading={this.toggleLoading}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={this.state.redirectTo} />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

PracticesEdit.propTypes = {
  editPracticeMailer: PropTypes.func.isRequired,
};

const getPracticeQuery = gql`
  query($id: Int!) {
    getPractice(id: $id) {
      id
      name
      clientAssessmentTitle
      clientAssessmentBody
      userInviteTitle
      userInviteBody
    }
  }
`;

const editPracticeMailerMutation = gql`
  mutation editPracticeMailer(
    $id: Int!
    $clientAssessmentTitle: String,
    $clientAssessmentBody: String,
    $userInviteTitle: String,
    $userInviteBody: String
  ) {
    editPracticeMailer(
      id: $id
      clientAssessmentTitle: $clientAssessmentTitle,
      clientAssessmentBody: $clientAssessmentBody,
      userInviteTitle: $userInviteTitle,
      userInviteBody: $userInviteBody

    ) {
      practice {
        name
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getPracticeQuery, {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  }),
  graphql(editPracticeMailerMutation, { name: 'editPracticeMailer' }),
)(PracticesEdit);
