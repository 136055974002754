import styled from 'styled-components';
import { palette } from 'styled-theme';

const PassionLogicMapWrapper = styled.div`
  background: ${palette('base', 0)};
  .top-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: -6px;
    .anticon-info-circle {
      font-size: 12px;
      margin-left: 4px;
      margin-top: 4px;
    }
  }
  .chart-wrapper {
    align-items: center;
    background: ${palette('base', 0)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      div {
        align-items: center;
        background: #054471;
        color: ${palette('base', 0)};
        display: flex;
        justify-content: center;
        line-height: 16px;
        padding: 8px;
        width: 50%;
        &:last-child {
          background: #589bcb;
        }
      }
    }
  }
`;

export default PassionLogicMapWrapper;
