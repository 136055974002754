import React from 'react';
import gql from 'graphql-tag';
import {  graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { Icon, notification, Spin } from 'antd';
import { Redirect } from 'react-router';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import AdvisorTemplateForm from './form';

class AdvisorsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      redirectTo: null,
      pageTitle: `Edit Assessment Template`,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  toggleLoading(boolean) {
    this.setState({
      loading: boolean,
    });
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      id: this.props.gblUserId,
    };

    this.props.editAdvisorMailer({ variables })
      .then(({ data }) => {
        if (data.editAdvisorMailer.ok && !data.editAdvisorMailer.errors) {
          this.handleSuccess(`Your client assessment email template has been successfully updated.`);
          return;
        }

        this.handleError(data.editAdvisorMailer.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: 'Advisor Updated',
      description,
    });

    // this.setState({ redirectTo: '/practices' });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {
    const { practice, advisor } = this.props;

    if (practice.loading || advisor.loading) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText={this.state.pageTitle}
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (practice.error || advisor.error) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText={this.state.pageTitle}
          />
          <div className="loading-wrapper">
            <p>Whoopsie - practice didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText={this.state.pageTitle}
        />
        <LayoutContentWrapper>
          <LayoutContent>
            <AdvisorTemplateForm
              loading={this.state.loading}
              onDelete={this.handleDeleteConfirm}
              onSubmit={this.handleSubmit}
              practice={practice.getPracticeDetails}
              advisor={advisor.getAdvisor}
              toggleLoading={this.toggleLoading}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={this.state.redirectTo} />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}


const getPracticeDetailsQuery = gql`
  query($id: String!) {
    getPracticeDetails(practiceId:$id) {
      name
      clientAssessmentTitle
      clientAssessmentBody
    }
  }
`;

const getAdvisorQuery = gql`
  query($id: Int!) {
    getAdvisor(id:$id) {
      clientAssessmentTitle
      clientAssessmentBody
      assessmentNotification
    }
  }
`;

const editAdvisorMailerMutation = gql`
  mutation editAdvisoreMailer(
    $id: Int!
    $clientAssessmentTitle: String,
    $clientAssessmentBody: String,
    $assessmentNotification: Boolean,
  ) {
    editAdvisorMailer(
      id: $id
      clientAssessmentTitle: $clientAssessmentTitle,
      clientAssessmentBody: $clientAssessmentBody,
      assessmentNotification: $assessmentNotification
    ) {
      advisor {
        id
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getPracticeDetailsQuery, {
    name: 'practice',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        id: props.match.params.practiceId,
      },
    }),
  }),
  graphql(getAdvisorQuery, {
    name: 'advisor',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        id: Number(props.gblUserId),
      },
    }),
  }),
  graphql(editAdvisorMailerMutation, { name: 'editAdvisorMailer' }),
)(AdvisorsForm);
