import React from 'react';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {Link} from 'react-router-dom';
import {Badge, Button, Form, Icon, Input, Spin} from 'antd';
import {practiceConfig} from '../../../config';
import {Table} from '../../../elements';
import {Titlebar} from '../../../components';
import GenerateBadge from '../../../helpers/generateBadge';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import _ from 'lodash'
import {AccountContext} from "../../../context/AccountContext";
import CustomButton from "../../../components/commons/CustomButton";


const FormItem = Form.Item;
const InputSearch = Input.Search;

class UserList extends React.Component {

  static contextType = AccountContext

  constructor(props) {
    super(props);

    this.state = {
      limit: practiceConfig.rowsPerPage,
      loading: false,
      order: null,
      orderColumn: 'firstName',
      page: 1,
      search: null,
      totalCount: null,
      data:[]
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }


  handleSearch(searchParams) {
    const { type } = searchParams;
    let search = searchParams;

    if (type === 'clear') {
      search = null;

      this.props.form.resetFields();
    }

    this.fetchData(
      this.state.page,
      this.state.limit,
      this.state.order,
      this.state.orderColumn,
      search,
    );

    this.setState({ search });
  }

  handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === 'descend' ? 'DESC' : 'ASC';

    this.fetchData(
      !pagination.current ? this.state.page : pagination.current,
      !pagination.pageSize ? this.state.limit : pagination.pageSize,
      sorter ? sorterOrder : this.state.order,
      sorter ? sorter.field : this.state.orderColumn,
      this.state.search,
    );

    this.setState({
      limit: !pagination.pageSize ? this.state.limit : pagination.pageSize,
      page: !pagination.current ? this.state.page : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
    });
  }

  fetchData(page, limit, order, orderColumn, search) {
    this.setState({ loading: true });

    const offset = (page - 1) * limit;
    this.props.data.fetchMore({
      variables: {
        limit,
        offset,
        order,
        orderColumn,
        search,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({ loading: false });
        if (!fetchMoreResult) {
          return previousResult;
        }

        this.setState({
          totalCount: fetchMoreResult.getAllUsers.pagination.totalCount,
          data:fetchMoreResult.getAllUsers.users
        });

        return fetchMoreResult;
      },
    });
  }

  redirectEdit(user){
    return()=>{
        this.props.history.push(`${this.props.currentPractice && this.props.currentPractice !== 'users' ? `/${this.props.currentPractice}/users` : "/users"}/edit/${user.id}`)
      }
  }

  redirectUser(user){
    return()=>{
      if(this.props.match.path === '/:company/users'){
        this.props.history.push(`${this.props.location.pathname}/view/${user.id}`)
      }
      else{this.props.history.push(`/users/view/${user.id}`)}

    }

  }

  render() {

    const { limit, page } = this.state;
    const {
      data: { loading, error, getAllUsers = null },
      form: { getFieldDecorator },
    } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Users"
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Users"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - users didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        dataIndex: 'firstName',
        render: (text, row) => <Button onClick={this.redirectUser(row)} type={'link'} className={row.status === 'Inactive' ? 'inactive' : null}>{row.firstName} {row.lastName}</Button>,
        sorter: true,
        title: 'User',
      },
      {
        dataIndex: 'status',
        render: text => <Badge status={GenerateBadge(text)} text={text} />,
        sorter: true,
        title: 'Status',
      },
      {
        dataIndex: 'role',
        sorter: true,
        title: 'Role',
      },
      {
        className: 'action-column',
        fixed: 'right',
        //render: user => <Link to={'/users/edit/' + user.id}><Icon type="edit" /></Link>,
        render: user => {
          if(user.roleId !== this.props.gblUserRole || (user.roleId === this.props.gblUserRole && user.id === this.props.gblUserId)){
            
            return <Button type={'link'} icon={'edit'} onClick={this.redirectEdit(user)}/>
          }
        }
      },
    ];


    const { users, pagination: { totalCount = 0 } } = getAllUsers ? getAllUsers : {users : [], pagination : { totalCount : 0 }};
    const tableCount = this.state.totalCount > 0 ? this.state.totalCount : totalCount;
    let tablePagination = false;

    if (tableCount > practiceConfig.rowsPerPage) {
      tablePagination = {
        current: page,
        defaultPageSize: practiceConfig.rowsPerPage,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: tableCount,
      };
    }

    const countStart = ((page - 1) * limit) + 1;
    const countEnd = page * limit > tableCount ? tableCount : page * limit;
    const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : 'No Records';

  const {userPermission} =this.context;
    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText="Users"
        />
        <div className="button-bar-wrapper">
          <CustomButton
              rights={['superAdmin','CreateUser']}
              icon="plus"
              // href={"/users/add"}
              onClick={()=>this.props.history.push(`${this.props.currentPractice && this.props.currentPractice !== 'users' ? `/${this.props.currentPractice}/users` : "/users"}/add`)}
              text="Add New"
              type={'primary'}
          >Add New</CustomButton>
          {/*<ButtonBar*/}
            {/*icon="plus"*/}
            {/*link="/users/add"*/}
            {/*text="Add New"*/}
            {/*disabled={!_.includes(userPermission, 'createPracticeAdm' ||'editPracticeSup')}*/}
          {/*/>*/}
        </div>
        <LayoutContentWrapper>
          <div className="display-bar with-search">
            {display}
          </div>
          <div className="search-bar">
            <Form>
              <FormItem className="oka-list-search">
                {getFieldDecorator('search')(
                  <InputSearch
                    autoComplete="off"
                    className={this.state.search ? 'has-search' : null}
                    enterButton
                    onSearch={this.handleSearch}
                    placeholder="Search..."
                    suffix={this.state.search
                      ? ( <Icon key="icon" onClick={() => this.handleSearch({ type: 'clear' })} style={{ color: '#bebebe' }} type="close-circle-o" />)
                      : <span />
                    }
                  />)}
              </FormItem>
            </Form>
          </div>
          <LayoutContent>
            <Table
              columns={columns}
              dataSource={_.get(getAllUsers, 'users', [])}
              loading={this.state.loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }}
              locale={{ emptyText: 'No users found.' }}
              onChange={this.handleTableChange}
              pagination={tablePagination ? { ...tablePagination } : false}
              // rowKey="id"
              scroll={{ x: columns.length * 150 }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const allUsersQuery = gql`
  query (
    $limit: Int
    $offset: Int
    $order: String
    $orderColumn: String
    $practiceId: String
    $portalId: String
    $search: String
  ){
    getAllUsers(
      limit: $limit
      offset: $offset
      order: $order
      orderColumn: $orderColumn
      practiceId: $practiceId
      portalId:$portalId
      search: $search
    ){
      users {
        id
        city
        email
        firstName
        lastName
        phoneNumber
        practice
        role
        roleId
        state
        status
        portalid
        roleId
      }
      pagination {
        totalCount
      }
    }
  }
`;

export default graphql(allUsersQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      limit: practiceConfig.rowsPerPage,
      practiceId:props.currentPractice ? props.currentPractice !== "users" ? props.currentPractice : '' : '',
      // portalId: _.get(props,'userPortals[0].id','').toString()
      portalId: _.get(props,'userPortals[0].slug',props.currentPractice === "users"? "" : "")
    },
  }),
})(Form.create()(UserList));
