
import {AddButtons, EditButtons, FormWrapper} from '../../../../components/form';
import StateList from '../../../../helpers/statesDataList';
import {Typography, Row, Col, AutoComplete, Progress, Form, Input, Button} from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import { useMutation,useLazyQuery } from 'react-apollo';
import gql from 'graphql-tag';
import _, { round } from 'lodash';
import StepsQuestions from './component/questions';

const { Title, Paragraph, Text } = Typography;

function Assessment (props) {
    const [question,setQuestion] = useState({
        list : [],
        totalpage : 0,
    })
    const [current,setCurrent] = useState(-1)
    const [percent,setPercent] = useState(0)
    const [pageable,setPageable] = useState([])
    const { state, form, setState, pages, practiceId, userId} = props

    const [getAssessment,{ loading,error, data }] = useLazyQuery(
        gql`
        query($practiceId: String!) {
            getClientAssessmentPerPractice(practiceId: $practiceId) {
                practice{
                    id
                }
                assessment{
                    name
                    questions
                }
            }
        },
        ` ,
        {
            fetchPolicy: 'network-only',
            onCompleted : (data) => {
                if(data.getClientAssessmentPerPractice)
                {
                    const {getClientAssessmentPerPractice} = data
                    const {practice, assessment} = getClientAssessmentPerPractice
                    setState({...state,practice:{...practice}})
                    setQuestion({
                        list :assessment.questions,
                        totalpage : Math.ceil(assessment.questions.length / 4),
                    })
                    setCurrent(0)
                }
            }
        }
    );

    const [submitClientAssessment, {data:mutData , loading:loadingMutation}] = useMutation(gql`
        mutation submitClientAssessment(
            $advisorId: Int!
            $clientId: Int!
            $practiceId: Int!
            $responses: JSON!
        ) {
            submitClientAssessment(
            advisorId:$advisorId
            clientId:$clientId
            practiceId:$practiceId
            responses:$responses
            ) {
                ok
                client {
                    id
                    firstName
                    lastName
                }
                errors {
                    message
                    path
                }
            }
        }
    `,
    {
        onCompleted : (data) => {
            if (data.submitClientAssessment.ok && !data.submitClientAssessment.errors) {
                
                var thisPage = pages
                thisPage[1].status = true
                props.setPages(thisPage)
              
                props.setActivePage('done')
            }
        }
    })
   

    const begin = () => {
        getAssessment({
            variables : {
                practiceId : 'muzics-moneymakers'
            }
        })
    }


    useEffect(()=>{
        paging(current)
        getPercentage()
    },[current,question])


    function paging (current) {
        var data_c = _.clone(question.list)
        const last_p = 
        setPageable(_.remove(data_c, function(o) { 
            return o.page == current 
        }))
    }

    const getPercentage = () => {

        if(question.list.length > 0)
        {
            const totalPages = question.list[question.list.length-1].page
            const currentPages = current
            var percentage = 0
            percentage =  currentPages / (totalPages + 1)
            percentage = round(percentage * 100)
            setPercent(percentage)
        }
    }

    const  handleSubmitNext = (event) =>{
        event.preventDefault();
        const newList = _.clone(question.list)
        props.form.validateFields((err, values) => {
            if(!err){
            var result = Object.keys(values).map((key) => [Number(key), values[key]]);
            result.map((items,i)=>{
                const ind = _.findIndex(question.list, function(o) { return o.id == items[0] })
                const toUpd = newList[ind]
                toUpd.answer = items[1]
            })
                setCurrent(current+1)
            }
        })
    }

    const handleSubmitEnd = (event) => {
        event.preventDefault();
        var responses = []
        const newList = _.clone(question.list)

        props.form.validateFields((err, values) => {
            if(!err){
                var result = Object.keys(values).map((key) => [Number(key), values[key]]);
                result.map((items,i)=>{
                    const ind = _.findIndex(question.list, function(o) { return o.id == items[0] })
                    const toUpd = newList[ind]
                    toUpd.answer = items[1]
                })

                question.list.map((qa,i)=>{
                    if(qa.type !== "Page Title")
                    {
                        responses.push({
                            id: qa.id,
                            answer: qa.answer
                        })
                    }
                })
                const variables = {
                    advisorId: parseInt(userId),
                    clientId: state.client.id,
                    practiceId: state.practice.id,
                    responses
                }
                submitClientAssessment({
                    variables
                })
            }
        })


    }

    return (
        <>
        <Form >
        {((current < 0) && (<>
        <Row>
            <Col span={24}>
            </Col>
            <Col span={24} >
            <Typography>
                <Paragraph>
                At Unitifi Direct, we fully understand that as human beings, we each have unique values, 
                goals and perspectives. We know that you are more than just a set of numbers based on your age, appetite for risk and economic status.
                </Paragraph>
                <Paragraph>
                The assessment which you are about to take will not only help us understand your unique tolerance for risk,
                but it will also help us to see <Text strong>HOW</Text> you go about making financial decisions. This will aid us in understanding your desired communication 
                style as well as guiding us toward portfolios that will be personalized for your goals and comfort level.
                </Paragraph>
                <Paragraph>
                Thank you in advance for taking the time to help us understand who you are as an individual. We hope you find the assessment beneficial!
                </Paragraph>
                <Paragraph>
                Sincerely,
                </Paragraph>
                <Paragraph>
                Unitifi Rep
                </Paragraph>
            </Typography>
            </Col>
        </Row>
        <Row style={{marginTop:10}} style={{marginTop:20}}>
            <Button type="primary"  onClick={begin}>
                Begin
            </Button>
        </Row></>))}

        {((question.list.length > 0 && current >= 0) && (
        <>
            <Row>
            <Progress strokeLinecap="square" percent={percent} style={{marginBottom:18,marginTop:18}}/>

                {
                pageable.map((items,i)=>
                    {
                        return <StepsQuestions setQuestion={setQuestion} items={items} {...props} question={question}/>
                    }
                )
                }
            </Row>
            <Row style={{marginTop:10}} style={{marginTop:20}}>
                <Button type="default"   style={{marginRight:10}} onClick={()=> setCurrent(current-1)} loading={loadingMutation}>
                    Previous
                </Button>
                {(question.list[question.list.length-1].page === current && (
                    <Button type="primary"   onClick={handleSubmitEnd} loading={loadingMutation}>
                        Finish
                    </Button>
                ))}

                {(question.list[question.list.length-1].page !== current && (
                <Button type="primary"  onClick={handleSubmitNext}>
                    Next
                </Button>
                ))}
            </Row>
        </>
        ))}
        </Form>
        </>
    )
}

export default Form.create()(Assessment);
