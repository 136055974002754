import React, { useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import AccessControl from "../../context/AccessControl";
import * as PropTypes from "prop-types";

CustomButton.propTypes = {
	...Button.propTypes,
	rights: PropTypes.arrayOf(PropTypes.string),
};

CustomButton.defaultProps = {
	rights: [],
};

export default function CustomButton(props) {

	function noAccess() {
		return(
		<Button {...props} disabled>{props.children}</Button>
		)
	}
	return (
		<AccessControl rights={props.rights} renderNoAccess={noAccess()}>
			<Div themeColor={props.themeColor || 'normal'} textColor={props.textColor || 'normal'}>
				<Button  {...props}>{props.children}</Button>
			</Div>
		</AccessControl>
	);
}

const Div = styled.span`
	.ant-btn {
		background-color: ${props => props.themeColor};
		color: ${props => props.textColor};
		border-color: ${props => props.themeColor};
	}
`;
