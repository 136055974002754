import styled from 'styled-components';
// import { palette } from 'styled-theme';

const SidebarWrapper = styled.div`
  padding-top: 64px;
  position: fixed;
  /* @media only screen and (max-width: 767px) { */
  @media only screen and (max-width: 1023px) {
    padding-top: 0;
  }

        /* sidebar menu divider start */
  .oka-menu-sidebar__divider {
    box-shadow: inset 0 -8px 0 0 rgba(238,242,244,1);

    &.ant-menu-item {
      height: 52px!important;
    }

    /* &.ant-menu-submenu {
      padding-bottom: 8px;
    }

    &.ant-menu-submenu-open {
      padding-bottom: 0;
    } */
  }

  .return-item {
    padding-left: 16px!important;
    text-align: center;
    a {
      span {
        font-size: 12px;
      }
    }
  }

  /* .ant-menu-inline {
    .ant-menu-item {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  .ant-menu-inline-collapsed {
    .oka-menu-sidebar__divider {
      &.ant-menu-submenu-open {
        padding-bottom: 8px;
      }
    }
  } */
  /* sidebar menu divider end */

  .ant-layout-sider {
    /* box-shadow: 2px 0 6px rgba(0,21,41,.35); */

  background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
  color: rgba(101,122,133,1);
  min-height: 100vh;

  .ant-menu {
    /* background: rgba(249,250,252,1);
    background: rgba(240,242,245,1); */
    background: rgba(255,255,255,1);
    color: rgba(0,111,186,1);

    font-weight: 500;
    /* letter-spacing: 0.4px; */

    &.ant-menu-inline {
        border-right: none;
      }

    .ant-menu-item {
      /* font-size: 13px; */
      height: 32px;
      line-height: 32px;

      .anticon svg {
        stroke: rgba(101,122,133,1);
        /* stroke-width: 30px; */
        transition: all .1s;
      }

        &> a {
          color: rgba(101,122,133,1);
          transition: all .1s;
          span {
            transition: all .1s;
          }
          i {
            font-size: 16px;
            transition: all .1s;
          }

          &:hover {

            color: rgba(0,111,186,1);

            .anticon svg {
              stroke: rgba(0,111,186,1);
              transition: all .1s;
            }

            span {

              color: rgba(0,111,186,1);
            }
            i {

              color: rgba(0,111,186,1);
            }
          }

        }

      .menu-item-bullet {
      display: inline-block;
      height: 10px;
      width: 12px;
    }
    .menu-item-bullet-inner {
        background: transparent;
        border-radius: 100%;
        height: 4px;
        margin:2px;
        width: 4px;
        transition: all .1s;
      }

      &.ant-menu-item-selected {
        background: none;
        &:after {
          display: none;
        }
        .menu-item-bullet-inner {
          background: rgba(0,111,186,1);
        }

        &> a {
          i {
            transition: all .1s;
            color: rgba(0,111,186,1);
          }
          .anticon svg {
            transition: all .1s;
              stroke: rgba(0,111,186,1);
            }
          span {
            transition: all .1s;
            color: rgba(0,111,186,1);
          }
        }
      }
    }

    .menu-label-wrapper {
      color: rgba(101,122,133,1);
      cursor: default;
      font-size: 10px;
      line-height: 14px;
      padding: 14px 0 10px 12px;
      text-transform: uppercase;
    }

    /* .ant-menu-item {
        margin-bottom: 8px;
        margin-top: 8px;

        &> a {
          color: rgba(101,122,133,1);
          &:hover {
            color: rgba(0,0,0,.85);
          }
        }
      }

      .ant-menu-submenu-title {
        color: rgba(101,122,133,1);
        &:hover {
          color: rgba(0,0,0,.85);
        }

        .ant-menu-submenu-arrow {
          opacity: 1;
          &:after,
          &:before {
            background: rgba(101,122,133,1);
          }
          &:hover {
              background: rgba(0,0,0,.85);
            }
        }
      }

      .ant-menu-submenu-selected {
        color: rgba(101,122,133,1);

        .ant-menu-submenu-arrow {
          &:after,
          &:before {
            background: rgba(101,122,133,1);
          }
        }
      }



    }
    .ant-menu-item-selected {
      background: none;
      &> a {
        color: rgba(0,0,0,.85);
      }
    } */
  }


  }
  .logo {
    align-items: center;
    background: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(238,242,244,1);
    display: flex;
    height: 64px;
    /* justify-content: center; */
    padding-left: 24px;
    img {
      height: 32px;
    }
  }
`;

export default SidebarWrapper;
