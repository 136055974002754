const ENV = process.env.REACT_APP_ENV;
let merged = {};

const defaultConfig = {
  apiUrl: 'http://gql.local.com', // api
  apiUrlExport: 'http://gql.local.com/export/pdf', // api
  envUrl: 'http://localhost:3000/', // app
  assessmentUrl: 'http://localhost:3001', // assessment
  language: 'english',
  maxSize: 524288000, // 500MB,
  practiceConfig: {
    footerText: `Copyright © ${(new Date()).getFullYear()} Unitifi`,
    practiceName: 'Unitifi',
    rowsPerPage: 20,
  },
  themeConfig: {
    layout: 'themedefault',
    sidebar: 'themedefault',
    theme: 'themedefault',
    topbar: 'themedefault',
  },
  validDocTypes: [
    'doc',
    'dot',
    'docx',
    'docm',
    'docb',
    'csv',
    'xlsx',
    'xlsm',
    'xltx',
    'xltm',
    'ppt',
    'pot',
    'pps',
    'pptx',
    'potm',
    'ppam',
    'ppsx',
    'ppsm',
    'sldx',
    'sldm',
    'pages',
    'pdf',
    'key',
    'numbers',
    'txt',
    'heic',
    'jpg',
    'jpeg',
    'png',
    'gif',
  ],
  validMediaTypes: [
    'heic',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'mov',
    'mp4',
    'mkv',
    'webm',
    'm4v',
  ],
};

if (ENV === 'staging') {
  merged = Object.assign({}, defaultConfig, {
    apiUrl: 'https://api-staging.unitifi.com',
    apiUrlExport: 'https://api-staging.unitifi.com/export/pdf',
    envUrl: 'https://staging.app.unitifi.com',
    assessmentUrl: 'http://assessment-staging.unitifi.com',
  });
} else if (ENV === 'production') {
  merged = Object.assign({}, defaultConfig, {
    apiUrl: 'https://gql.unitifi.com',
    apiUrlExport: 'https://gql.unitifi.com/export/pdf',
    envUrl: 'https://app.unitifi.com',
    assessmentUrl: 'https://ucit.unitifi.com',
  });
} else {
  merged = defaultConfig;
}

export const {
  apiUrl,
  apiUrlExport,
  envUrl,
  assessmentUrl,
  language,
  maxSize,
  practiceConfig,
  themeConfig,
  validDocTypes,
  validMediaTypes,
} = merged;
