import moment from 'moment';

const datePresets = [
  {
    dateRange: {
      endDate: moment()
        .utc()
        .format('YYYY-MM-DD'),
      startDate: moment()
        .utc()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
    },
    key: 'Last 30 Days',
  },
  {
    dateRange: {
      endDate: moment()
        .utc()
        .format('YYYY-MM-DD'),
      startDate: moment()
        .utc()
        .startOf('month')
        .format('YYYY-MM-DD'),
    },
    key: 'Month to Date',
  },
  {
    dateRange: {
      endDate: moment()
        .utc()
        .format('YYYY-MM-DD'),
      startDate: moment()
        .utc()
        .startOf('quarter')
        .format('YYYY-MM-DD'),
    },
    key: 'Quarter to Date',
  },
  {
    dateRange: {
      endDate: moment()
        .utc()
        .format('YYYY-MM-DD'),
      startDate: moment()
        .utc()
        .startOf('year')
        .format('YYYY-MM-DD'),
    },
    key: 'Year to Date',
  },
  {
    dateRange: {
      endDate: moment()
        .utc()
        .subtract(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD'),
      startDate: moment()
        .utc()
        .subtract(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD'),
    },
    key: 'Last Month',
  },
  {
    dateRange: {
      endDate: moment()
        .utc()
        .subtract(1, 'quarter')
        .endOf('quarter')
        .format('YYYY-MM-DD'),
      startDate: moment()
        .utc()
        .subtract(1, 'quarter')
        .startOf('quarter')
        .format('YYYY-MM-DD'),
    },
    key: 'Last Quarter',
  },
  {
    dateRange: {
      endDate: moment()
        .utc()
        .subtract(1, 'year')
        .endOf('year')
        .format('YYYY-MM-DD'),
      startDate: moment()
        .utc()
        .subtract(1, 'year')
        .startOf('year')
        .format('YYYY-MM-DD'),
    },
    key: 'Last Year',
  },
  {
    dateRange: {
      endDate: null,
      startDate: null,
    },
    key: 'All Time',
  },
];

export default datePresets;
