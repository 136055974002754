
import {AddButtons, EditButtons, FormWrapper} from '../../../../components/form';
import {Steps, Icon, Row, Col, Divider, Card, Form, Input, Button} from 'antd';
import React, {useContext, useEffect} from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from 'react-apollo';
import logo from '../../../../assets/img/unitifi.svg';

const FormItem = Form.Item;

function SignupForm (props) {
    const { getFieldError, getFieldProps, getFieldDecorator } = props.form;

    const [checkExistingClient,{ loading,error, data }] = useLazyQuery(
        gql`
        query($email: String!) {
            checkExistingClient(email: $email) 
        },
        ` ,
        {
            fetchPolicy: 'network-only',
            onCompleted : (data) => {

                if(!data.checkExistingClient) {
                    props.setActivePage('fullSignup')
                }
                else {
                    alert('Account already exist')
                    props.setState('')
                }
            }
        }
    );


    function handleSubmit (event) {
        event.preventDefault();

        props.form.validateFields((err, values) => {
            if (!err) {
                const {email} = values
                props.setState({email:email})
                checkExistingClient({
                    variables :{
                        email
                    }
                })
            }
        });
    }

    return (
        <Row >
        <Col span={24} >
            {/* <div className={'logo-signup'}> */}
                <img src={logo} />
            {/* </div> */}
        </Col>
        <Col span={24} style={{marginTop:30}}>
        <FormWrapper>
            <Form layout="vertical" onSubmit={handleSubmit}>
            <FormItem
                label="Email"
                style={{marginBottom:0}}
            >
                {getFieldDecorator('email', {
                    initialValue:  null,
                    rules: [{
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                    }],
                })(
                    <Input
                    type={'email'}
                    placeholder="Enter your email"
                    size={'large'}
                    />)}
            </FormItem>
            <Button type="primary" className="login-form-button" size={'large'} style={{width:'100%'}} onClick={handleSubmit}>
                Continue <Icon type="arrow-right" />
            </Button>
            </Form>
        </FormWrapper>
        <Divider />
        </Col>
        <Col span={24}>
            <div className={'logo-signup'}>
            <Button type="link" href={'https://unitifi.com/'} block>
            Learn more about Unitifi
            </Button>
            </div>
        </Col>
        </Row>
    )
}

export default Form.create()(SignupForm);
