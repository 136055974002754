import React from 'react';
import {AutoComplete, Badge, Form, Input, Select} from 'antd';
import {AddButtons, EditButtons, FormWrapper} from '../../../components/form';
import StateList from '../../../helpers/statesDataList';
import {useQuery} from "react-apollo";
import _ from 'lodash';
import gql from 'graphql-tag';

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;


export default Form.create()(AdvisorsForm);

function AdvisorsForm (props) {

    const { getFieldError, getFieldProps, getFieldDecorator } = props.form;
    const { loading,error, data, refetch } = useQuery(
        gql`
		    query (
                $limit: Int
                $offset: Int
                $slug: String
                $search: String
            ){
              getPracticeSupport(
                  limit:$limit
                  offset:$offset
                  slug:$slug
                  search:$search
            ){
                users {
                  id
                  city
                  email
                  firstName
                  lastName
                  phoneNumber
                  practice
                  role
                  state
                  status
                }
                pagination {
                  totalCount
                }
              }
          }
		`,
        {
            variables: {
                limit:20,
                slug: props.currentPractice,
                search:""
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        }
    );

    function handleSubmit(event) {
        event.preventDefault();

        props.form.validateFields((err, values) => {
            if (!err) {
                let payload = _.clone(values)
                payload.roleId = 6
                props.onSubmit(payload);
            }
        });
    }

        const supports = _.get(data,'getPracticeSupport.users',[]);

        return (
            <FormWrapper>
                <Form layout="vertical" onSubmit={handleSubmit}>
                    <FormItem
                        label="Support"
                    >
                        {getFieldDecorator('supportId', {
                            initialValue: props.advisor ? props.advisor.supports : undefined,
                            rules: [{
                                message: 'A user role is required.',
                                required: false,
                            }],
                        })(<Select
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            // onChange={roleId => onRoleChange(roleId)}
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder="Support"
                            showSearch
                        >
                            {(supports).map(sup => (
                                <Option key={sup.id} value={sup.id}>{`${sup.firstName} ${sup.lastName}`}</Option>
                            ))}
                        </Select>)}
                    </FormItem>
                    <FormItem
                        label="Advisor Email"
                    >
                        {getFieldDecorator('email', {
                            initialValue: props.advisor ? props.advisor.email : null,
                            rules: [{
                                message: 'An advisor email is required.',
                                required: true,
                            }],
                        })(<Input placeholder="Advisor Email" />)}
                    </FormItem>
                    <FormItem
                        className="input-small"
                        label="First Name"
                    >
                        {getFieldDecorator('firstName', {
                            initialValue: props.advisor ? props.advisor.firstName : null,
                            rules: [{
                                message: 'An advisor first name is required.',
                                required: true,
                            }],
                        })(
                            <Input
                                placeholder="First Name"
                            />)}
                    </FormItem>
                    <FormItem
                        className="input-small"
                        label="Last Name"
                    >
                        {getFieldDecorator('lastName', {
                            initialValue: props.advisor ? props.advisor.lastName : null,
                            rules: [{
                                message: 'An advisor last name is required.',
                                required: true,
                            }],
                        })(
                            <Input
                                placeholder="Last Name"
                            />)}
                    </FormItem>
                    {props.advisor ?
                        <FormItem
                            className="input-small"
                            label="Status"
                        >
                            {getFieldDecorator('status', {
                                initialValue: props.advisor.status,
                                rules: [{
                                    message: 'An advisor status is required.',
                                    required: true,
                                }],
                            })(
                                <Select
                                    placeholder="Status"
                                >
                                    <Option value="Active">
                                        <Badge status="success" text="Active" />
                                    </Option>
                                    <Option value="Inactive">
                                        <Badge status="error" text="Inactive" />
                                    </Option>
                                </Select>)}
                        </FormItem> :
                        null
                    }
                    {props.advisor ?
                        <FormItem
                            className="input-small"
                            label="Assessment Completed Notification"
                        >
                            {getFieldDecorator('assessmentNotification', {
                                initialValue: props.advisor.assessmentNotification || false,
                            })(
                                <Select
                                    placeholder="Notification"
                                >
                                    <Option value={true}>
                                        <Badge status="success" text="Yes, send me notification" />
                                    </Option>
                                    <Option value={false}>
                                        <Badge status="error" text="No, don't send me notification" />
                                    </Option>
                                </Select>)}
                        </FormItem> :
                        null
                    }
                    {/* <FormItem
              className="input-small"
              label="Practice Admin"
            >
              {getFieldDecorator('practiceAdmin', {
                initialValue: props.advisor.roleId === 3? false : true,
                rules: [{
                  message: 'An advisor status is required.',
                  required: true,
                }],
              })(
                <Select
                  placeholder="Status"
                >
                  <Option value={false}>
                    <Badge status="error" text="Advisor" />
                  </Option>
                  <Option value={true}>
                    <Badge status="success" text="Practice Administrator" />
                  </Option>
                </Select>)}
            </FormItem> */}
                    <FormItem
                        className="input-small"
                        label="Address"
                    >
                        {getFieldDecorator('address', {
                            initialValue: props.advisor ? props.advisor.address : null,
                        })(<Input placeholder="Address" />)}
                    </FormItem>
                    <FormItem
                        className="input-small"
                        label="City"
                    >
                        {getFieldDecorator('city', {
                            initialValue: props.advisor ? props.advisor.city : null,
                        })(<Input placeholder="City" />)}
                    </FormItem>
                    <FormItem
                        className="input-xsmall"
                        label="State"
                    >
                        {getFieldDecorator('state', {
                            initialValue: props.advisor && props.advisor.state ? props.advisor.state : undefined,
                        })(<AutoComplete
                            placeholder="Select a State"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {(StateList || []).map(state => (
                                <AutoCompleteOption key={state.abbr} value={state.abbr}>{state.name}</AutoCompleteOption>
                            ))}
                        </AutoComplete>)}
                    </FormItem>
                    <FormItem
                        className="input-xsmall"
                        label="Zip Code"
                    >
                        {getFieldDecorator('zipCode', {
                            initialValue: props.advisor ? props.advisor.zipCode : null,
                        })(<Input placeholder="Zip Code" />)}
                    </FormItem>
                    <FormItem
                        className="input-phone__wrapper"
                        label="Phone Number"
                    >
                        <InputGroup>{getFieldDecorator('primaryContactPhoneNumber', {
                            initialValue: props.advisor ? props.advisor.phoneNumber : null,
                        })(
                            <div className="input-phone__input">
                                {getFieldDecorator('p1', {
                                    initialValue: props.advisor && props.advisor.phoneNumber ? props.advisor.phoneNumber.substring(0, 3) : null,
                                })(<Input maxLength={3} placeholder="867" />)}
                                <span>&#8211;</span>
                                {getFieldDecorator('p2', {
                                    initialValue: props.advisor && props.advisor.phoneNumber ? props.advisor.phoneNumber.substring(3, 6) : null,
                                })(<Input maxLength={3} placeholder="530" />)}
                                <span>&#8211;</span>
                                {getFieldDecorator('p3', {
                                    initialValue: props.advisor && props.advisor.phoneNumber ? props.advisor.phoneNumber.substring(6, 10) : null,
                                })(<Input maxLength={4} placeholder="9999" />)}
                            </div>)}
                        </InputGroup>
                    </FormItem>

                    <div className="form-toolbar">
                        {props.advisor ?
                            <EditButtons
                                deleteRights={'deleteAdviser'}
                                cancelUrl={props.cancelUrl}
                                loading={props.loading}
                                onDelete={props.onDelete}
                                onSubmit={handleSubmit}
                                rights={['superAdmin','editAdviser']}
                            /> :
                            <AddButtons
                                cancelUrl={props.cancelUrl}
                                createAnother={props.createAnother}
                                loading={props.loading}
                                onCreateAnother={props.onCreateAnother}
                                onSubmit={handleSubmit}
                                rights={['superAdmin','createAdviser']}
                            />}
                    </div>
                </Form>
            </FormWrapper>
        );

}


// export default compose(graphql(getPracticeSupport, {
//     options: props => ({
//         fetchPolicy: 'network-only',
//         variables: {
//             limit:20,
//             slug: props.currentPractice,
//             search:""
//         },
//     }),
// }))(Form.create()(AdvisorForm));


