import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PracticesAdd, PracticesEdit, PracticesList, PracticesMailer } from '../../../containers/practices';
import Dashboard from "../../../containers/dashboard";

class PracticeRoutes extends React.Component {
  render() {
    const {
      gblUserId, gblUserIsPracticeAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice,
      gblUserIsPortalAdmin,sidebarCollapsed,firstLogin
    } = this.props;

    return (
      <Switch>
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect
          exact
          from="/practices/"
          to="/practices"
          strict
        />
        <Redirect
          exact
          from="/practices/add/:poid/"
          to="/practices/add/:poid"
          strict
        />
        <Redirect
          exact
          from="/practices/edit/:id/:poid/"
          to="/practices/edit/:id/:poid"
          strict
        />
        <Redirect
          exact
          from="/practices/mailer/edit/:id/"
          to="/practices/mailer/edit/:id"
          strict
        />
          <Redirect
              exact
              from="/:company/practices/edit/:id/:poid/"
              to="/:company/practices/edit/:id/:poid"
              strict
          />
          <Redirect
              exact
              from="/:company/practices/"
              to="/:company/practices"
              strict
          />

          
        {/* admin routes */}
          <Route
              exact
              path="/:company/practices"
              render={props => (
                  <PracticesList
                      {...props}
                      currentPractice={currentPractice}
                      gblUserId={gblUserId}
                      gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}

                      gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                      gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                      gblUserRole={gblUserRole}
                  />
              )}
          />
        {/* {gblUserIsGlobalAdmin || gblUserIsPortalAdmin ? */}
          <Route
            exact
            path="/practices"
            render={props => (
              <PracticesList
                {...props}
                currentPractice={currentPractice}
                gblUserId={gblUserId}
                gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}

                gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                gblUserRole={gblUserRole}
              />
            )}
          />
          {/* : null} */}
        {/* <Redirect from="/practices" to={`/${userPracticeList[0].practiceId}`} /> */}
        <Route
          exact
          path="/practices/add/:poid"
          render={props => (
            <PracticesAdd
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/practices/edit/:poid/:id"
          render={props => (
            <PracticesEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/:company/practices/edit/:poid/:id"
          render={props => (
            <PracticesEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/practices/mailer/edit/:id"
          render={props => (
            <PracticesMailer
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
          <Route
              exact
              path="/practices/:practiceId"
              render={props => (
                  <Dashboard
                      {...props}
                      firstLogin={firstLogin}
                      currentPractice={currentPractice}
                      gblUserId={gblUserId}
                      gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                      gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                      gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
                      gblUserRole={gblUserRole}
                      sidebarCollapsed={sidebarCollapsed}
                  />
              )}
          />
      </Switch>
    );
  }
}

export default PracticeRoutes;
