import React from 'react';
import { Skeleton } from 'antd';

const defaults = {
  Energizer: {
    count: 0,
    dates: {},
    styles: {},
    type: 'Energizer',
  },
  Liberator: {
    count: 0,
    dates: {},
    styles: {},
    type: 'Liberator',
  },
  Protector: {
    count: 0,
    dates: {},
    styles: {},
    type: 'Protector',
  },
  Observer: {
    count: 0,
    dates: {},
    styles: {},
    type: 'Observer',
  },
};

// const defaultStyleOrder = ['Logical', 'Passionate', 'Balanced'];

// const defaultStyles = [
//   { percent: '0%', style: 'Logical' },
//   { percent: '0%', style: 'Passionate' },
//   { percent: '0%', style: 'Balanced' },
// ];

const NormalizeStyle = (style) => {
  if (['Somewhat Logical', 'Moderately Logical', 'Highly Logical'].includes(style)) {
    return 'Logical';
  }

  if (['Somewhat Passionate', 'Moderately Passionate', 'Highly Passionate'].includes(style)) {
    return 'Passionate';
  }

  return 'Balanced';
};

const ToPercent = (arr) => {
  const total = arr.reduce((a, c) => a + c.count, 0);

  return arr.map(({ count, ...props }) =>
    ({
      ...props,
      angle: Math.round((count * 100) / total),
      percent: Math.round((count * 100) / total) + '%',
    }));
};

/* eslint-disable */
const GenerateCounts = data => data.map(c => ({
  dateCollected: c.dateCollected,
  style: NormalizeStyle(c.insights.personality.decisionMakingStyle),
  type: c.insights.personality.type,
}))
  .reduce((a, c) => {
    /* type */
    a[c.type] = a[c.type]
      || {
        count: 0,
        dates: {},
        styles: {},
        type: c.type,
      };
    a[c.type].count += 1;
    /* dates */
    a[c.type].dates[c.dateCollected] = a[c.type].dates[c.dateCollected]
      || {
        count: 0,
        dateCollected: c.dateCollected,
      };
    a[c.type].dates[c.dateCollected].count += 1;
    /* styles */
    a[c.type].styles[c.style] = a[c.type].styles[c.style]
      || {
        count: 0,
        style: c.style,
      };
    a[c.type].styles[c.style].count += 1;

    return a;
  }, {});
/* eslint-enable */

// const MergeDefaults = (order, items, key) => order
//   .filter(o => !items.find(i => o[key] === i[key])).concat(items);

const NormalizeData = data => ToPercent(Object.values({
  ...defaults,
  ...GenerateCounts(data),
}))
  .map(({
    dates, type, styles, ...properties
  }) => ({
    ...properties,
    dates: Object.values(dates),
    styles: ToPercent(Object.values(styles)),
    type,
  }))
  .sort((a, b) => a.type.localeCompare(b.type))
  .reverse();

const ClientInfoBar = props => (
  props.loading ?
    <div className="dashboard-info-wrapper">
      <div className="dashboard-info-item">
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>
      <div className="dashboard-info-item">
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>
      <div className="dashboard-info-item">
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>
      <div className="dashboard-info-item">
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>
    </div> :
    <div className="dashboard-info-wrapper">
      {NormalizeData(props.data)
        .map(d => (
          ['0%', 'NaN%'].includes(d.percent) ?
            <div className="dashboard-info-item dimmed" key={d.type}>
              <div>
                <div className="dashboard-info-title">{d.type}</div>
                <div className="dashboard-info-detail">0%</div>
                {/* {MergeDefaults(defaultStyles, d.styles, 'style')
                  .sort((a, b) => (
                    defaultStyleOrder.indexOf(a.style) - defaultStyleOrder.indexOf(b.style)))
                  .map(a => (
                    <div
                      className="dashboard-info-average dimmed"
                      key={a.style}
                    >
                      {a.style} - {a.percent}
                    </div>
                  ))} */}
              </div>
            </div> :
            <div className="dashboard-info-item" key={d.type}>
              <div className="dashboard-info-title">{d.type}</div>
              <div className="dashboard-info-detail">{d.percent}</div>
              {/* {MergeDefaults(defaultStyles, d.styles, 'style')
                .sort((a, b) => (
                  defaultStyleOrder.indexOf(a.style) - defaultStyleOrder.indexOf(b.style)))
                .map(a => (
                  <div
                    className={`dashboard-info-average ${a.percent === '0%' ? 'dimmed' : ''}`}
                    key={a.style}
                  >
                    {a.style} - {a.percent}
                  </div>
                ))} */}
            </div>
        ))}
    </div>
);

export default ClientInfoBar;
