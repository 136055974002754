import styled from 'styled-components';
import { palette } from 'styled-theme';

const AntTable = ComponentName => styled(ComponentName)`
  .ant-spin-nested-loading {
    >div {
      >.ant-spin {
        max-height: 100%;
      }
    }
    .ant-spin-blur {
      filter: none;
    }
  }
  .ant-spin-dot i {
    background: transparent;
    color: rgba(0,111,186,1);
  }

  .ant-table-fixed-left, .ant-table-fixed-left table {
    border-radius: 0;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: none;
  }

  .ant-table-tbody {
    >tr {
      >td {
        /* max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
        a {
          font-weight: 600;
          transition: all .2s;
          &:hover {
            text-decoration: underline;
          }
        }
        &.pulseit {
          animation: pulse linear .5s;
        }
        @keyframes pulse {
          0% {
            transform: scale(1);
            }
          50% {
            color: rgba(0,111,186,1);
            transform: scale(1.3);
            }
          100% {
            transform: scale(1);
            }
        }
      }
    }
  }
  .ant-table-placeholder {
    border-bottom: none;
    padding: 24px 24px 0;
  }
  .ant-table-tbody {
    >tr {
      >td {
        &.action-column {
          text-align: right;
          width: 48px;
        }
      }
    }
  }
  .inactive {
    color: ${palette('text', 3)};
  }
`;

export default AntTable;
