import {
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  Checkbox,
  Button,
  Modal,
  message,
} from 'antd'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { graphql } from 'react-apollo'
import ReCAPTCHA from 'react-google-recaptcha'
import logo from '../../../assets/img/unitifi.svg'

const { Title, Text, Paragraph } = Typography
const { Header, Content } = Layout

const slugGenerator = (value) =>
  value
    .trim()
    .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, '')
    .replace(/\s{2,}/g, ' ')
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/_+$/, '')
    .toLowerCase()

const Signup = (props) => {
  const recaptchaRef = React.createRef()
  const [state, setState] = useState({
    visible: false,
    accept: false,
    verify: false,
  })

  const [error, setError] = useState({
    email: false,
  })

  const { form, mutate, history } = props
  const { getFieldProps, getFieldDecorator } = form

  const handleOk = () => {
    setState({ ...state, visible: false, accept: true })
  }

  const handleCancel = () => {
    setState({ ...state, visible: false, accept: false })
  }

  const onVerify = (recaptchaResponse) => {
    if (recaptchaResponse) {
      setState({ ...state, verify: true, visible: true })
    } else {
      setState({ ...state, verify: false })
    }
  }

  const onChecked = () => {
    setState({ ...state, visible: true })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch(`https://www.google.com/recaptcha/api/siteverify`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `secret=${process.env.REACT_APP_RECHAPTCHA_SECRETKEY}&response=${recaptchaValue}`,
      mode: 'no-cors',
    }).then(function(result) {
      // Handle server response (see Step 4)
      // result.json().then(function(json) {
      //   handleServerResponse(json);
      // })
    })

    form.validateFields(async (err, values) => {
      if (!err) {
        const slug = slugGenerator(values.companyName)
        await mutate({
          variables: { ...values, slug },
        }).then(function(result) {
          const {
            createUserSignup: { id, userExisted, token, refreshToken },
          } = result.data || {
            createUserSignup: {
              id: null,
              userExisted: false,
              token: '',
              refreshToken: '',
            },
          }

          if (id) {
            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)
            history.push(`/subscription`)
          }

          if (userExisted) {
            message
              .error('Email already exists.')
              .then(() => message.loading('Redirected to Login page.'))
              .then(() => history.push(`/login`))
            // setError({ ...error, email: true })
          }
        })
      }
    })
  }

  return (
    <Layout className='layout'>
      <Header style={{ background: 'rgba(240,242,245,1)' }}>
        <div
          className={'logo-signup'}
          style={{ textAlign: 'center', background: 'rgba(240,242,245,1)' }}
        >
          <img src={logo} style={{ height: '47px' }} />
        </div>
      </Header>
      <Content
        style={{
          background: 'rgba(240,242,245,1)',
          padding: '30px 10px 30px 10px',
        }}
      >
        <Row gutter={[{ lg: 8, xs: 0 }]}>
          <Title style={{ textAlign: 'center', color: '#657A85' }} level={2}>
            Create Your Account
          </Title>
          <Col
            xs={{ span: 24, offset: 0 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 9, offset: 8 }}
            xl={{ span: 6, offset: 9 }}
          >
            <Card bordered={false}>
              <Form layout='vertical' onSubmit={handleSubmit}>
                <Form.Item
                  hasFeedback
                  label={'Company Name'}
                  style={{ color: 'rgba(0, 0, 0, 0.85)', marginBottom: '10px' }}
                >
                  {getFieldDecorator('companyName', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Company Name is required!',
                      },
                    ],
                  })(<Input placeholder='Company Name' type={'text'} />)}
                </Form.Item>
                <Form.Item
                  hasFeedback
                  validateStatus={error.email ? 'error' : ''}
                  label={'Email'}
                  style={{
                    color: 'rgba(0, 0, 0, 0.85)',
                    marginBottom: '10px',
                  }}
                  help={error.email ? 'Email already exist.' : ''}
                >
                  {getFieldDecorator('email', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                    ],
                  })(<Input placeholder='Email' type={'email'} />)}
                </Form.Item>
                <Form.Item
                  hasFeedback
                  label={'First Name'}
                  style={{
                    color: 'rgba(0, 0, 0, 0.85)',
                    marginBottom: '10px',
                  }}
                >
                  {getFieldDecorator('firstName', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'First Name is required!',
                      },
                    ],
                  })(<Input placeholder='First Name' type={'text'} />)}
                </Form.Item>
                <Form.Item
                  hasFeedback
                  label={'Last Name'}
                  style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                >
                  {getFieldDecorator('lastName', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Last Name is required!',
                      },
                    ],
                  })(<Input placeholder='Last Name' type={'text'} />)}
                </Form.Item>
                <Form.Item>
                  <ReCAPTCHA
                    size='compact'
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECHAPTCHA_SITEKEY}
                    onChange={onVerify}
                  />
                </Form.Item>
                <Form.Item>
                  <Title level={4}>
                    <Checkbox
                      onChange={() => onChecked()}
                      checked={state.accept}
                    >
                      {' '}
                      I agree to{' '}
                      <Text underline={true}> terms and conditions </Text>.
                    </Checkbox>
                  </Title>
                </Form.Item>
                <Button
                  block
                  type='primary'
                  htmlType='submit'
                  size='large'
                  style={{ height: '32px' }}
                  disabled={!(state.accept && state.verify)}
                >
                  Create Account
                </Button>
                <Button
                  block
                  style={{ marginTop: 10, height: '32px' }}
                  size='large'
                  onClick={() => props.history.push('/login')}
                >
                  Cancel
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
        <Modal
          title='Terms and Conditions'
          visible={state.visible}
          onOk={() => handleOk()}
          onCancel={() => handleCancel()}
          closable={false}
          maskClosable={false}
          okText={'Accept'}
          cancelText={'Decline'}
        >
          <Paragraph style={{ textAlign: 'center' }}>
            <b>
              FINANCIAL INDUSTRY INFORMATION AND RATINGS ORGANIZATION, INC.{' '}
              <br /> TERMS OF SERVICE - FINANCIAL ADVISORS
            </b>
          </Paragraph>
          <Paragraph>
            These terms of service (these "Terms") govern the relationship
            between FINANCIAL INDUSTRY INFORMATION AND RATING ORGANIZATION, INC.
            dba UNITIFI ("UNITIFI", “us”, or “we”) and companies and individuals
            that wish to engage with UNITIFI to obtain information pertaining to
            their clients, prospective clients who utilize UNITIFI’s electronic
            assessments and other materials utilized to obtain data and other
            information necessary for UNITIFI to provide the services requested
            by you. All of the products and/or services described in these
            Terms, as well as any other products or services offered by UNITIFI
            to such companies, at any time, shall be referred to herein as a
            “Service” or “Services”. To use the Service, you, the company (“You”
            or “Your”), must read and accept all of the terms and conditions in
            these Terms. BY SIGNING THE LAST PAGE OF THIS DOCUMENT, YOU
            ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREED TO BE BOUND BY
            ALL OF THE TERMS, CONDITIONS, AND NOTICES CONTAINED IN THESE TERMS,
            JUST AS IF YOU HAD SIGNED THESE TERMS.
          </Paragraph>
          <Paragraph>
            <b>I. SERVICES</b>
          </Paragraph>
          <Paragraph>
            <b>Services</b>. Utilizing UNITIFI’s electronic assessments, e-mail
            requests or other medium provided to you, U ITIFI will provide
            Services to assist You by collecting, aggregating, summarizing
            consumer behavior intelligence information submitted by your clients
            or prospective clients in response to UNITIFI’s assessments and
            providing You with summary reports for each such client or
            prospective client. Your clients and prospective clients must submit
            information responsive to UNITIFI’s assessments and questions
            designed to obtain a better understanding of each client's true
            financial behavior intelligence.{' '}
          </Paragraph>
          <Paragraph>
            The information pertaining to your clients will be aggregated and
            electronically available to You via the UNITIFI app. Such reports
            may assist You in assessing your clients reported financial consumer
            behavior when it comes to investing, planning and communicating for
            their financial futures. The reports will be provided to You on a
            client-by-client basis, as well as a comprehensive book of business
            view. You agree that you will not to circumvent, reverse-engineer,
            or otherwise alter the materials used by UNITIFI to obtain related
            information from Your clients.{' '}
          </Paragraph>
          <Paragraph>
            As part of the Service, You may have the opportunity to enter
            information specific to Your company or your clients who utilize
            UNITIFI’s electronic assessments or other tools designed to obtain
            financial behavior intelligence information from Your clients. Your
            submission of such company information is strictly voluntary.
            However, you may be unable to use some of the Services without
            inputting the requested information. When submitting any
            information, you must provide UNITIFI with complete and accurate
            information as prompted by e-mails, UNITIFI’s app, or other medium.
          </Paragraph>
          <Paragraph>
            <b>II. AGREEMENT AND PAYMENT</b>
          </Paragraph>
          <Paragraph>
            <b>Agreement</b>. In order to utilize the Services offered by
            UNITIFI through its electronic assessments, website or other medium,
            You agree to the fees to be charged by completing the appropriate
            order form(s).{' '}
          </Paragraph>
          <Paragraph>
            <b>Payment</b>. The payment terms and amount due by You to UNITIFI
            in exchange for the Services will be communicated to You and agreed
            upon in the order form. You will receive a monthly invoice detailing
            the fees charged to You for Services rendered during the preceding
            month. You will make payment in full on each invoice by the
            fifteenth (15th) of the month following receipt of services from
            UNITIFI. UNITIFI will charge and You will pay interest on all late
            payments thirty (30) days beyond due date. Such interest shall
            accrue on any outstanding balance at the lower of (a) 1.5% per
            month, or (2) the highest rate allowed under applicable law. All
            charges for the Services will be net of applicable taxes.
          </Paragraph>
          <Paragraph>
            In addition to the Service charges and fees, You shall also be
            responsible for all taxes assessed in connection with the Services,
            including any foreign, federal, state, or local taxes and charges
            assessed in connection with a Service, including, without
            limitation, all governmental excise, use, sales, value-added, and
            occupational taxes and other fees, or other similar surcharges and
            levies (including, without limitation, universal service fund
            charges or equivalents), but excluding any taxes based on a party's
            net income ("Taxes"). You agree that You are solely responsible for
            collection and payment of any and all Taxes that You are required to
            pay to any taxing authority in connection with Your sale or use of
            the Services, and You shall indemnify us for any losses,
            liabilities, costs, and expenses incurred as a result of a breach by
            You of this section.
          </Paragraph>
          <Paragraph>
            <b>III. PROPRIETARY RIGHTS</b>
          </Paragraph>
          <Paragraph>
            Except for the limited license expressly granted herein, UNITIFI
            expressly reserves all right, title and interest in and to the
            Services, the data content provided in response to UNITIFI’s
            assessments, the aggregated data provided from all sources, and all
            processing, analytics, data and other software and technology used
            by UNITIFI in the provision of the Services ("Technology"),
            including, without limitation, any derivatives, improvements,
            enhancements or extensions of the Technology conceived, reduced to
            practice or otherwise developed by or on behalf of UNITIFI, all of
            which are valuable assets of UNITIFI, together with any copyright,
            patent or trademark or other intellectual property right, or federal
            or state right, pertaining thereto.
          </Paragraph>
          <Paragraph>
            You shall not: (i) use, or allow the use of, the Services or any
            Technology, except pursuant to the limited rights expressly granted
            in these Terms; (ii) use the Services in any manner that is
            inconsistent with user documentation, if any, supplied to You by
            UNITIFI; (iii) attempt to reverse-engineer, or compromise any aspect
            of the Services or Technology, or attempt to access data of any
            other customer of UNITIFI; (iv) remove, obscure or alter any legal
            notices, including notices of intellectual property rights appearing
            in or on any materials delivered to You by UNITIFI; or (v) use,
            post, transmit or introduce any device, software or routine which
            interferes or attempts to interfere with the operation of UNITIFI’s
            Services.
          </Paragraph>
          <Paragraph>
            You shall not use, directly or indirectly, in whole or in part, any
            signature, monogram, logo, trademark, service mark, or trade name
            that is now or may hereafter be owned, licensed or used by UNITIFI
            (a " Mark") except in the manner and to the extent approved in
            writing by UNITIFI prior to any such use, which approval may be
            withheld in UNITIFI’s sole discretion. Any permitted use of a Mark
            shall be immediately discontinued upon the termination or expiration
            of these Terms. You hereby expressly disclaim any and all right,
            title, and interest in and to any Mark whether or not used by You.
            Your covenants and disclaimers in this section shall survive the
            termination or expiration of these Terms. You recognize and
            acknowledge the validity and ownership of the Marks in the United
            States and other countries. You agree not to contest the ownership
            of such marks. Except for the limited rights granted above, you gain
            no license or proprietary rights in the Marks and will not infringe
            upon, dilute, or harm FIIRO's rights with respect to the Marks.
          </Paragraph>
          <Paragraph>
            <b>IV. USER-GENERATED/THIRD-PARTY CONTENT</b>
          </Paragraph>
          <Paragraph>
            By utilizing the Service, You automatically grant, and warrant and
            represent that You have the right to grant, UNITIFI a perpetual,
            irrevocable, royalty-free, worldwide non-exclusive, assignable,
            sublicensable, transferable license to use, copy, perform, display,
            and distribute the information needed by UNITIFI to perform its
            Services for You, and to prepare derivative works of, or incorporate
            into other works for any purpose. Though FIIRO retains this license
            interest in the information provided to UNITIFI to perform the
            Services called for herein, You and your clients will retain all
            ownership rights to the summary reports prepared for You on a
            client-by-client basis. UNITIFI will not use the summary reports
            prepared for You for any purpose. UNITIFI will retain a perpetual,
            irrevocable, royalty-free, worldwide non-exclusive, assignable,
            sublicensable, transferable license to use only the aggregate
            information provided to UNITIFI in the operation of UNITIFI’s
            business.
          </Paragraph>
          <Paragraph>
            <b>Rules of Conduct</b>. In addition to all other obligations and
            terms stated in these Terms, you agree to abide by any rules of
            conduct or community guidelines posted on UNITIFI’s website. UNITIFI
            reserves the right to exclude or suspend any user who violates these
            rules of conduct, and reserves the right, but does not undertake an
            affirmative obligation, to remove, screen or edit any content which
            is contrary to these rules without prior notice. Posted Content does
            not necessarily reflect the opinion of UNITIFI.
          </Paragraph>
          <Paragraph>
            <b>Information Storage and Access.</b> UNITIFI does not guarantee
            that it will store any information You or your clients submit for
            any certain period of time. UNITIFI reserves the right, in its sole
            discretion, to determine whether and how long to store user data.
            UNITIFI also reserves the right, in its sole discretion, with or
            without notice, to remove or delete user data, reviews, content, or
            inactive accounts.{' '}
          </Paragraph>
          <Paragraph>
            <b>V. REPRESENTATIONS, WARRANTIES, OR DISCLAIMERS</b>
          </Paragraph>
          <Paragraph>
            THE SERVICES ARE PROVIDED “AS IS” AND THERE ARE NO WARRANTIES,
            CLAIMS OR REPRESENTATIONS MADE BY FIIRO EITHER EXPRESS, IMPLIED, OR
            STATUTORY, WITH RESPECT TO THE SERVICE, INCLUDING WARRANTIES OF
            QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS
            FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY
            COURSE OF DEALING, COURSE OF PERFORMANCE, OR TRADE USAGE. UNITIFI
            DOES NOT WARRANT THAT THE SERVICE WILL MEET YOUR NEEDS OR BE FREE
            FROM ERRORS. THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN
            ESSENTIAL PART OF THESE TERMS AND FORMED THE BASIS FOR DETERMINING
            THE PRICE CHARGED FOR THE SERVICES. SOME STATES DO NOT ALLOW
            EXCLUSION OF AN IMPLIED WARRANTY, SO THIS DISCLAIMER MAY NOT APPLY
            TO YOU.
          </Paragraph>
          <Paragraph>
            <b>VI. USE / COMPLIANCE</b>
          </Paragraph>
          <Paragraph>
            You will not use the Services in any way or for any purpose that
            would violate, or would have the effect of violating, any applicable
            laws, rules or regulations or any rights of any third-parties,
            including without limitation, any law or right regarding any
            copyright, patent, trademark, trade secret, music, image, or other
            proprietary or property right, false advertising, unfair
            competition, defamation, invasion of privacy or rights of celebrity.{' '}
          </Paragraph>
          <Paragraph>
            <b>VII. LIMITATIONS OF LIABILITY</b>
          </Paragraph>
          <Paragraph>
            UNITIFI WILL NOT BE LIABLE TO YOU OR ANY THIRD-PARTY FOR ANY (A)
            INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR INCIDENTAL DAMAGES,
            (B) LOSS OF PROFITS, (C) LOSS OF DATA OR INFORMATION, OR (D)
            REPUTATIONAL HARM, WHETHER BASED ON A CLAIM OR ACTION OF CONTRACT,
            WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR OTHER TORT, BREACH OF ANY
            STATUTORY DUTY, INDEMNITY OR CONTRIBUTION, OR OTHERWISE, EVEN IF
            FIIRO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
            STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
            INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE LIMITATIONS OR
            EXCLUSIONS IN THIS PARAGRAPH MAY NOT APPLY TO YOU.
          </Paragraph>
          <Paragraph>
            NOTWITHSTANDING ANYTHING SET FORTH IN THESE TERMS TO THE CONTRARY,
            UNITIFI’S CUMULATIVE LIABILITY TO YOU OR ANY OTHER PARTY FOR ANY
            LOSS OR DAMAGES RESULTING FROM ANY CLAIMS, DEMANDS, OR ACTIONS
            ARISING OUT OF OR RELATING TO THESE TERMS OR USE OF THE SERVICE BY
            YOU OR YOUR PERSONNEL SHALL NOT EXCEED THE AMOUNT PAID BY YOU FOR
            THE SERVICES DURING THE PRECEDING SIX (6) MONTHS. YOUR SOLE AND
            EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE
            SERVICE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR
            TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SERVICE.
          </Paragraph>
          <Paragraph>
            <b>VIII. TERM AND TERMINATION</b>
          </Paragraph>
          <Paragraph>
            These Terms shall be in place for a term of one (1) year (the
            “Initial Term”) unless terminated by either party upon thirty (30)
            days’ written notice. At the conclusion of the Initial Term, these
            Terms will automatically renew for successive one (1) year terms
            unless either party terminates these Terms by providing thirty (30)
            days’ notice to the other party.{' '}
          </Paragraph>
          <Paragraph>
            <b>IX. APPLICABLE LAW AND VENUE</b>
          </Paragraph>
          <Paragraph>
            These Terms shall be governed by and construed under the laws of the
            state of Nebraska without reference to its conflicts of law
            principles. In the event of any conflicts between foreign law,
            rules, and regulations, and Nebraska law, rules, and regulations,
            Nebraska law, rules and regulations shall prevail and govern. Each
            party agrees to submit to the exclusive and personal jurisdiction of
            the courts located in the State of Nebraska, Douglas County. Any
            notices to UNITIFI must be sent to:
          </Paragraph>
          <Paragraph style={{ marginLeft: 40 }}>
            {' '}
            Fraser Stryker PC LLO <br />
            500 Energy Plaza <br />
            409 South 17th Street <br />
            Omaha, NE 68102 <br />
            Attn: Paul Shotkoski
          </Paragraph>
          <Paragraph>
            via first class or air mail or overnight courier and are deemed
            given upon receipt. A waiver of any default is not a waiver of any
            subsequent default. The relationship between UNITIFI and You is not
            one of a legal partnership relationship but is one of independent
            contractors. These Terms shall be binding upon and inure to the
            benefit of the respective successors and assigns of the parties
            hereto.
          </Paragraph>
          <Paragraph>
            <b>X. MISCELLANEOUS</b>
          </Paragraph>
          <Paragraph>
            These Terms (including any amendment agreed upon by the parties in
            writing) represent the complete agreement between us concerning its
            subject matter and supersedes all prior agreements and
            representations between the parties. If any provision of these Terms
            is held to be unenforceable for any reason, such provision shall be
            reformed to the extent necessary to make it enforceable to the
            maximum extent permissible so as to affect the intent of the
            parties, and the remainder of these Terms shall continue in full
            force and effect. A waiver of any default is not a waiver of any
            subsequent default. These Terms shall be binding upon and inure to
            the benefit of the respective successors and assigns of the parties
            hereto.
          </Paragraph>
        </Modal>
      </Content>
    </Layout>
  )
}

const loginUserMutation = gql`
  mutation(
    $address: String
    $city: String
    $email: String!
    $firstName: String
    $lastName: String
    $password: String
    $phoneNumber: String
    $state: String
    $zipCode: String
    $companyName: String
    $slug: String
  ) {
    createUserSignup(
      address: $address
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      state: $state
      zipCode: $zipCode
      companyName: $companyName
      slug: $slug
    ) {
      ok
      id
      token
      refreshToken
      userExisted
      errors {
        path
        message
      }
    }
  }
`
export default graphql(loginUserMutation)(Form.create()(Signup))
