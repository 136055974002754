import React from "react";
import { Layout, Menu, Icon, Button } from 'antd';
import { Link } from 'react-router-dom';
import MenuWrapper from './menu.style';

const MenuComp = ({
                    collapsed,
                    openKeys,
                    onOpenChange,
                    selectedKeys,
                    menuItems,
                    type,
                    gblUserRole,
                    
}) => {
    return (
        <MenuWrapper >
        <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            selectedKeys={selectedKeys}
            theme="light"
        >
            <Menu.Item key="label">
                <div className="menu-label-wrapper">
                <span>Menu</span>
                </div>
            </Menu.Item>
            {
                menuItems.map((items,i)=>
                    (items.type.includes(type) && items.roles.includes(gblUserRole) && 
                    <Menu.Item key={items.key} className={items.className} disabled={items.disabled}>
                        <Button className={'menuItem'} style={{textAlign:'left'}} block onClick={()=> items.reset ? localStorage.setItem('currentPractice',null): {}}>
                        <Link to={items.path[type]}>
                        <div className="menu-item-bullet">
                            <div className="menu-item-bullet-inner"/>
                        </div>
                        <Icon type={items.icon}/>
                        <span>{items.label}</span>
                        </Link>
                        </Button>
                    </Menu.Item>)
                )
            }
            </Menu>
            </MenuWrapper>
    )
}

export default MenuComp;