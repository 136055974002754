import React from 'react';
import gql from 'graphql-tag';
import {  graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { Icon, Modal, notification, Spin } from 'antd';
import { Redirect } from 'react-router';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import PortalsForm from '../form';

const { confirm } = Modal;

class PortalsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect
        ? this.props.location.state.redirect
        : `/companies`,
      loading: false,
      redirectTo: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.removePortal = this.removePortal.bind(this);
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      id: this.props.data.getPortal.id,
      practiceId: this.props.currentPractice,
    };

    this.props.editPortal({ variables })
      .then(({ data }) => {
        if (data.editPortal.ok && !data.editPortal.errors) {
          this.handleSuccess(`${data.editPortal.portal.name} has been successfully updated.`);
          return;
        }

        this.handleError(data.editPortal.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleDeleteConfirm() {
    confirm({
      title: 'Delete Portal',
      content: `Are you sure you want to permanently delete ${this.props.data.getPortal.name}? This action cannot be undone.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: 'close-circle',
      onOk: this.removePortal,
      width: '680px',
    });
  }

  removePortal() {
    this.props.deletePortal({ variables: { id: this.props.data.getPortal.id } })
      .then(({ data }) => {
        if (data.deletePortal.ok && !data.deletePortal.errors) {
          this.handleSuccess(`${this.props.data.getPortal.name} has been successfully deleted.`, 'deleted');
          return;
        }

        this.handleError(data.deletePortal.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: action === 'deleted' ? 'Portal Deleted' : 'Portal Updated',
      description,
    });

    this.setState({ redirectTo: `/companies` });
    // this.setState({ redirectTo: `/portals/${this.props.data.getPortal.slug}` });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {
    const { data: { loading, error, getPortal } } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            displayText="Edit Company"
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            displayText="Edit Company"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - portal didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Titlebar
          displayText="Edit Company"
        />
        <LayoutContentWrapper>
          <LayoutContent>
            <PortalsForm
              cancelUrl={this.state.cancelUrl}
              portal={getPortal}
              gblUserRole={this.props.gblUserRole}
              gblUserIsGlobalAdmin={this.props.gblUserIsGlobalAdmin}
              loading={this.state.loading}
              onDelete={this.handleDeleteConfirm}
              onSubmit={this.handleSubmit}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={this.state.redirectTo} />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getPortalQuery = gql`
  query($slug: String) {
    getPortal(slug: $slug) {
      id
      name
      slug
      description
      status
      siteLogo
      email
      siteLogoS3Token
      primaryContactAddress
      primaryContactZipCode
      primaryContactCity
      primaryContactCountry
      primaryContactState
      primaryContactSuite
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
    }
  }
`;

const editPortalMutation = gql`
mutation editPortal($id: Int!, $name: String!, $slug: String!, $description: String,
      $status: String
       $siteLogo: String
      $siteLogoS3Token: String
      $primaryContactAddress: String
      $primaryContactZipCode: String
      $primaryContactCity: String
      $primaryContactCountry: String
      $primaryContactState: String
      $primaryContactSuite: String
      $primaryContactEmail: String
      $primaryContactFirstName: String
      $primaryContactLastName: String
){
  editPortal(id: $id, 
      name: $name, 
      slug: $slug, 
      description: $description
      status: $status
       siteLogo: $siteLogo
      siteLogoS3Token: $siteLogoS3Token
      primaryContactAddress: $primaryContactAddress
      primaryContactZipCode: $primaryContactZipCode
      primaryContactCity: $primaryContactCity
      primaryContactCountry: $primaryContactCountry
      primaryContactState: $primaryContactState
      primaryContactSuite: $primaryContactSuite
      primaryContactEmail: $primaryContactEmail
      primaryContactFirstName: $primaryContactFirstName
      primaryContactLastName: $primaryContactLastName
   ){
    ok
    portal {
      id
      name
      slug
      description
      status
      email
      primaryContactAddress
      primaryContactZipCode
      primaryContactCity
      primaryContactCountry
      primaryContactState
      primaryContactSuite
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
    }
  }
}
`;

const deletePortalMutation = gql`
  mutation deletePortal($id: Int! ) {
    deletePortal(id: $id) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getPortalQuery, {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        slug: props.match.params.slug,
      },
    }),
  }),
  graphql(editPortalMutation, { name: 'editPortal' }),
  graphql(deletePortalMutation, { name: 'deletePortal' }),
)(PortalsEdit);
