import theme from './defaultTheme';

const globalstyle = String.raw`
#root, body {
  background: rgba(240,242,245,1)!important
}

.ant-badge-count {
  background: rgba(253,82,82,1);
  font-weight: 600;
  top: -3px;
}

.user-notification-menu {
  .ant-dropdown-menu-item {
    padding: 16px;
    &:not(:last-child){
      border-bottom: 1px solid rgba(238,242,244,1);
    }
    &:last-child {
      font-size: 12px;
      padding: 8px 0;
      text-align: center;
    }
    &:hover {
      background: none;
    }
    .menu-item-wrapper {
      display: flex;
      justify-content: space-between;
      width: 200px;
      a {
        color: ${theme.palette.text[1]};
        &:focus {
          text-decoration: none;
        }
      }
      .note {
        font-size: 12px;
      }
    }
  }
}

.dropdown-download {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item-disabled {
      color: rgba(0,111,186,1);
      cursor: default;
      font-weight: 600;
      &:hover {
        background: none;
      }
    }
  }
}

.ant-popover {
  width: 250px;
}

.ant-back-top {
  right: 24px;
}

.menu-drawer {
  .ant-drawer-header {
    align-items: center;
    border-bottom: none;
    display: flex;
    height: 116px;
    padding: 12px 12px 12px 24px;
    .ant-drawer-title {
      color: ${theme.palette.text[1]};
      font-size: 32px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  .ant-drawer-close-x {
    color: ${theme.palette.text[1]};
    font-size: 24px;
    font-weight: 700;
    height: 116px;
    line-height: 52px;
    width: 64px;
  }
}

.ant-drawer-body {
  .menu-trigger-wrapper {
    &.expanded {
      background: rgba(255,255,255,1);
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
      box-shadow: 5px 0px 8px rgba(0, 0, 0, 0.15);
      left: 256px;
      margin-left: -1px;
      position: absolute;
      top: 136px;
      width: 40px;
      z-index: 1001;
      i {
        color: rgba(101,122,133,1);
        cursor: pointer;
        padding: 13px 9px;
      }
    }
  }
}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  ol,
  ul,
  dl,
  li,
  input,
  input[type="number"],
  input[type="password"],
  input[type="text"],
  textarea,
  span,
  div,
  label,
  button,
  table,
  html,
  body {
    font-family: ${theme.fonts.primary};
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-form,
  .ant-form-item,
  .ant-badge-status-text,
  .ant-checkbox-wrapper {
    font-family: ${theme.fonts.primary};
  }

  a {
    color: rgba(0,111,186,1);
    &:hover,
    &:focus {
      color: rgba(0,111,186,1);
    }
  }

  ::selection {
    background: rgba(0,111,186,1);
  }

  ::placeholder {
    color: rgba(188,198,218,1);
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .caption,
  .display-1,
  .display-2,
  .display-3,
  .headline,
  .title {
    color: rgba(0,0,0,.65);
  }

  .caption {
    font-size: 12px;
    line-height: 20px;
  }

  .caption-error {
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: ${theme.palette.error[0]};
  }

  .display-1 {
    font-size: 24px;
    line-height: 32px;
  }

  .display-2 {
    font-size: 34px;
    line-height: 40px;
  }

  .display-3 {
    font-size: 45px;
    line-height: 48px;
  }

  .headline {
    font-size: 20px;
    line-height: 30px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
  }

  .ant-badge-status-default {
    background: ${theme.palette.base[5]};
  }

  .ant-drawer {
    &.dashboard-filter-sidebar {
      .ant-drawer-content-wrapper {
        top: 64px;
        border: 1px solid rgba(238,242,244,1);
        border-top: none;
        box-shadow: -5px 0px 5px -5px rgba(134,134,123,0.16);
        .ant-drawer-header {
          background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
          border-bottom: 1px solid rgba(238,242,244,1);
        }
        .ant-drawer-content {
          background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
        }
        .ant-drawer-body {
          padding-top: 8px;
          .ant-form-item {
            margin-bottom: 8px;
          }
          .ant-form-item-label {
            label {
              &:after {
                display: none;
              }
            }
          }
        }
        .ant-drawer-close {
          &:active,
          &:focus,
          &:hover {
            color: rgba(0,0,0,.45);
          }
          .ant-drawer-close-x {
            font-size: 14px;
            height: 55px;
            line-height: 55px;
            width: 55px;
          }
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        button {
          :first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .ant-select-auto-complete {
    &.ant-select {
      .ant-input {
        &:focus,
        &:hover {
          border-color: ${theme.palette.primary[0]};
        }
      }

      .ant-input-affix-wrapper {
        .anticon-search {
          color: ${theme.palette.text[3]};
        }
      }
    }
  }

  .ant-pagination {

    .ant-pagination-item {

      :focus, :hover {
        border-color: rgba(0,111,186,1);
        a {
          color: rgba(0,111,186,1);
        }
      }

    }

    .ant-pagination-item-active {
      border-color: rgba(0,111,186,1);

      a {
        color: rgba(0,111,186,1);
      }

      &:hover,
      &:focus {
        border-color: rgba(0,111,186,1)

        a {
          &:hover,
          &:focus {
            color: rgba(0,111,186,1)
          }
        }
      }
    }
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: rgba(0,111,186,1);
    color: rgba(0,111,186,1);
  }

  .ant-select-selection {
    &:active,
    &:focus,
    &:hover {
      border-color: rgba(0,111,186,1);
    }
  }

  .ant-select-dropdown-menu-item {
    &:active,
    &:focus,
    &:hover {
      background: rgba(101,122,133,0.05);
    }
  }

  .ant-dropdown-menu-item-selected {
    background: rgba(101,122,133,0.05);
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
  }

  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item-selected {
    background: rgba(101,122,133,0.05);
  }

  .ant-pagination-options-quick-jumper input {
    &:active,
    &:focus,
    &:hover {
      border-color: rgba(0,111,186,1);
    }
  }

  .ant-radio-button-wrapper-checked {
    background: transparent;
    border-color: ${theme.palette.primary[0]};
    color: ${theme.palette.primary[0]};

    &:first-child {
      border-color: ${theme.palette.primary[0]};
    }
  }

  .ant-radio-button-wrapper {
    &:hover,
    &:focus {
      color: ${theme.palette.primary[0]};
    }
  }

  .ant-form-explain {
    color: ${theme.palette.error[0]};
  }

  &.uni-modal {
    .ant-modal-close-x {
      font-size: 14px;
      font-weight: 400;
    }

    &.uni-widget-modal {
      .ant-modal-body {
        background: ${theme.palette.base[4]};

        .widget-list-card {
          background: ${theme.palette.base[0]};
          border-radius: 2px;
          box-shadow: ${theme.palette.shadow[3]};
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 16px;
          padding: 16px 24px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .widget-list-name {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;

            > div {
              align-items: flex-end;
              color: ${theme.palette.text[0]};
              display: flex;
              flex-direction: row;
              font-size: 14px;
              line-height: 22px;

              span {
                color: ${theme.palette.text[2]};
                font-size: 12px;
                line-height: 20px;
                margin-left: 8px;
              }
            }
          }

          .widget-list-details {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;

            > div {
              align-items: flex-end;
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .list-text {
                color: ${theme.palette.text[1]};
                font-size: 12px;
                line-height: 20px;
                margin-right: 16px;
                max-width: 75%;
              }
            }
          }

          &.disabled {
            background: ${theme.palette.base[4]};

            .widget-list-name {
              > div {
                color: ${theme.palette.text[2]};
              }
            }

            .widget-list-details {
              > div {
                .list-text {
                  color: ${theme.palette.text[2]};
                }
              }
            }

          }

        }
      }
    }
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: ${theme.palette.base[3]} !important;
    }
  }

  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: ${theme.palette.primary[0]};
        }
      }
    }
  }

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: rgba(0,111,186,1);
      }
    }
  }

  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: rgba(0,111,186,1);
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: rgba(0,111,186,1);
      }
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: rgba(0,111,186,1);
      border-color: rgba(0,111,186,1);

      &:after {
        border: 1px solid rgba(255,255,255,1);
        border-top: 0;
        border-left: 0;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &:after {
        background-color: rgba(0,111,186,1);
      }
    }
  }

  .ant-input {
    font-family: ${theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-input-affix-wrapper {
    &:hover {
      .ant-input {
        &:not(.ant-input-disabled) {
          border-color: ${theme.palette.primary[0]};
        }
      }
    }
  }

  // &.ant-select {
  //   .ant-select-selection {
  //     &:hover {
  //       border-color: ${theme.palette.primary[0]};
  //     }
  //   }

  //   &.ant-select-focused {
  //     .ant-select-selection {
  //       &:focus,
  //       &:active {
  //         border-color: ${theme.palette.primary[0]};
  //       }
  //     }
  //   }

  //   &.ant-select-open {
  //     .ant-select-selection {
  //       border-color: ${theme.palette.primary[0]};
  //       box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
  //     }
  //   }

  //   &.ant-select-disabled {
  //     .ant-select-selection {
  //       &:hover,
  //       &:focus,
  //       &:active {
  //         border-color: ${theme.palette.primary[0]};
  //       }
  //     }

  //     span.ant-badge {
  //       opacity: .4;
  //     }
  //   }
  // }

  .ant-select-focused {
    .ant-select-selection {
      border-color: rgba(0,111,186,1);
    }
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    color: rgba(0,111,186,1);
  }

  // .ant-select-selection {
  //   &:focus {
  //     border-color: ${theme.palette.primary[0]};
  //     box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
  //   }
  //   &:active {
  //     border-color: ${theme.palette.primary[0]};
  //     box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
  //   }
  // }

  // .ant-select-dropdown-menu-item {
  //   &:hover {
  //     background-color: ${theme.palette.primary[10]};
  //   }
  // }

  // .ant-select-dropdown-menu-item-active {
  //   background-color: ${theme.palette.primary[10]};
  // }

  .ant-dropdown-menu-item {
    &:hover {
      background-color: ${theme.palette.primary[10]};
    }
    a {
      &:hover {
        color: ${theme.palette.text[1]};
      }
      &:focus {
        color: ${theme.palette.text[1]};
      }
    }

  }
  .ant-dropdown-menu-submenu-title {
    &:hover {
      background-color: ${theme.palette.primary[10]};
    }
    a {
      &:hover {
        color: ${theme.palette.text[1]};
      }
      &:focus {
        color: ${theme.palette.text[1]};
      }
    }

  }



  .ant-tag {
    font-family: ${theme.fonts.primary};
  }

  .has-error {
    .ant-input {
      border-color: ${theme.palette.error[0]};

      &:hover {
        border-color: ${theme.palette.error[0]};
      }
    }

    .ant-form-explain {
      border-color: ${theme.palette.error[0]};
    }

    .ant-form-split {
      border-color: ${theme.palette.error[0]};
    }
  }

  .uni-confirm-delete {
    i.anticon.anticon-close-circle {
      color: ${theme.palette.error[0]};
    }
  }

  .uni-confirm-modal {
    .ant-confirm-content {
      margin-left: 38px;
      margin-top: 12px;

      .uni-yes-confirm {
        margin: 24px 0 8px 0;
      }

      .ant-form-item {
        margin: 0;
      }
    }

    .ant-modal-footer {
      border-top: none;
      padding: 12px 24px 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    .uni-body {
      height: 100%;
      overflow: scroll;
      position: fixed;
      width: 100%;
    }
  }
`;

export default globalstyle;
