import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Badge, Form, Input, notification, Select, Alert, Row, Col, AutoComplete } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AddButtons, EditButtons, FormWrapper } from '../../../components/form';
import { SiteLogo } from '../components';
import StateList from '../../../helpers/statesDataList';
import _ from 'lodash';


const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

const cleanPracticeId = value => value
  .trim()
  .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, '')
  .replace(/\s{2,}/g, ' ')
  .replace(/[^a-zA-Z0-9]/g, '-')
  .replace(/_+$/, '')
  .toLowerCase();

class PracticeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      folder: null,
      practiceIdEdited: false,
      siteLogo: this.props.practice && this.props.practice.siteLogo
        ? this.props.practice.siteLogo
        : null,
      siteLogoS3Token: this.props.practice && this.props.practice.siteLogoS3Token
        ? this.props.practice.siteLogoS3Token
        : null,
      newPracticeEmailRecipient : '',
      newPracticeEmailTitle : 'Welcome',
      newPracticeEmailBody: `Hello,\n\nWe’re honored to have you as part of the community of financial professionals utilizing Unitifi!\n\n[[SYS_GENERATED_LINK]]\n\nOnce logged in, your dedicated Customer Success Representative will be in touch.\nWe're excited to help transform the authentic manner in which you connect with and help your clients succeed.\n\nThank you,\nUnitifi`,
    };

    this.handleUploadParams = this.handleUploadParams.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPracticeIdBlur = this.onPracticeIdBlur.bind(this);
    this.onPracticeIdChange = this.onPracticeIdChange.bind(this);
    this.onPracticeNameChange = this.onPracticeNameChange.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);


  }

  onPracticeIdBlur(event) {
    if (event.target.value === '') {
      this.setState({ practiceIdEdited: false });
    } else {
      const practiceId = cleanPracticeId(event.target.value);
      this.props.form.setFieldsValue({ practiceId });
    }
  }

  onPracticeIdChange(event) {
    const practiceId = cleanPracticeId(event.target.value);
    this.props.form.setFieldsValue({ practiceId });
  }

  onPracticeNameChange(event) {
    if (!this.state.practiceIdEdited && !this.props.practiceId) {
      const practiceId = cleanPracticeId(event.target.value);
      this.props.form.setFieldsValue({ practiceId });
    }
  }

  handleUploadParams(uploadParams) {
    const { file, folder } = uploadParams;

    this.setState({
      file,
      folder,
    });
  }

  handleSubmit(event) {

    event.preventDefault();

    this.props.toggleLoading(true);

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        this.props.toggleLoading(false);
      }

      if (!err) {
        let payload = _.clone(values)
            payload.practiceId = this.props.companyId+"-"+values.practiceId
        const avatarUrl = this.state.file ? await this.uploadAvatar() : null;
        this.props.onSubmit({
          ...payload,
          siteLogo: avatarUrl && avatarUrl.url ? avatarUrl.url : this.state.siteLogo,
          siteLogoS3Token: avatarUrl && avatarUrl.s3Token
            ? avatarUrl.s3Token
            : this.state.siteLogoS3Token,
        });
      }
    });
  }

  async uploadAvatar() {
    const upload = await this.props.uploadFile({
      variables: {
        file: this.state.file,
        folder: this.state.folder,
      },
    });

    const { ok } = upload.data.uploadFile;

    if (!ok) {
      notification.error({
        message: 'An Error Has Occurred',
        description: 'Our team has already been notified and will address the issue as quickly as possible.',
      });
    }

    const { s3Token, url } = upload.data.uploadFile.response;
    return { s3Token, url };
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const { gblUserRole } = this.props;
    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem label="Name">
            {getFieldDecorator('name', {
              initialValue: this.props.practice ? this.props.practice.name : null,
              rules: [{
                message: 'A practice name is required.',
                required: true,
              }],
            })(
              <Input
                onChange={this.onPracticeNameChange}
                placeholder="Name"
              />)}
          </FormItem>
          <FormItem className="input-small" label="Practice Identifier" style={{ display: 'none' }}>
            {getFieldDecorator('practiceId', {
              initialValue: this.props.practice ? this.props.practice.practiceId : null,
              rules: [{
                message: 'A practice identifier is required.',
                required: true,
              }],
            })(
              <Input
                disabled
                onBlur={this.onPracticeIdBlur}
                onChange={this.onPracticeIdChange}
                placeholder="Practice ID"
              />)}
          </FormItem>

          { /* ADDITIONAL INFO */ }
          <FormItem label="Street Address">
            {getFieldDecorator('primaryContactAddress', {
              initialValue: this.props.practice ? this.props.practice.primaryContactAddress: null,
            })(
              <Input placeholder="Address" />)}
          </FormItem>
          <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Suite">
                  {getFieldDecorator('primaryContactSuite', {
                    initialValue: this.props.practice ? this.props.practice.primaryContactSuite : null,
                  })(
                    <Input placeholder="Suite" />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Postal Code">
                  {getFieldDecorator('primaryContactZipCode', {
                    initialValue: this.props.practice ? this.props.practice.primaryContactZipCode : null,
                  })(
                    <Input placeholder="Postal Code" />)}
                </FormItem>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Country">
                  {getFieldDecorator('primaryContactCountry', {
                    initialValue: this.props.practice ? this.props.practice.primaryContactCountry : null,
                  })(
                    <Input placeholder="Country" />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="City">
                  {getFieldDecorator('primaryContactCity', {
                    initialValue: this.props.practice ? this.props.practice.primaryContactCity : null,
                  })(
                    <Input placeholder="City" />)}
                </FormItem>
              </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem
                className="input-xsmall"
                label="State"
              >
                {getFieldDecorator('primaryContactState', {
                  initialValue: this.props.practice ? this.props.practice.primaryContactState : null
                })(<AutoComplete
                  placeholder="Select a State"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {(StateList || []).map(state => (
                    <AutoCompleteOption key={state.abbr} value={state.abbr}>{state.name}</AutoCompleteOption>
                  ))}
                </AutoComplete>)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem label="Contact Email">
                {getFieldDecorator('primaryContactEmail', {
                  initialValue: this.props.practice ? this.props.practice.primaryContactEmail : null,
                  rules: [{
                    message: 'A Contact Email is required.',
                  }],
                })(<Input readOnly={![1,4,5].includes(gblUserRole)} />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Contact First name">
                {getFieldDecorator('primaryContactFirstName', {
                  initialValue: this.props.practice ? this.props.practice.primaryContactFirstName : null,
                  rules: [{
                    message: 'A Contact First name is required.',
                  }],
                })(<Input readOnly={![1,4,5].includes(gblUserRole)} />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Contact Last name">
                {getFieldDecorator('primaryContactLastName', {
                  initialValue: this.props.practice ? this.props.practice.primaryContactLastName : null,
                  rules: [{
                    message: 'A Contact Last name is required.',
                  }],
                })(<Input readOnly={![1,4,5].includes(gblUserRole)} />)}
              </FormItem>
            </Col>
          </Row>
          { /* ADDITIONAL INFO */ }

          {this.props.practice ?
            <FormItem className="input-small" label="Status">
              {getFieldDecorator('status', {
                initialValue: this.props.practice.status,
                rules: [{
                  message: 'A practice status is required.',
                  required: true,
                }],
              })(
                <Select placeholder="Status">
                  <Option value="Active">
                    <Badge status="success" text="Active" />
                  </Option>
                  <Option value="Inactive">
                    <Badge status="default" text="Inactive" />
                  </Option>
                </Select>)}
            </FormItem> :
            null
          }
          <FormItem>
            <SiteLogo
              handleUploadParams={this.handleUploadParams}
              practice={this.props.practice ? this.props.practice : null}
            />
          </FormItem>
          { this.props.practice  === null ?
           // new practice email
            <span>
              <h3>Customize Practice Welcome Message</h3>
              <FormItem label="Subject Line">
                {getFieldDecorator('newPracticeEmailTitle', {
                  initialValue: this.state.newPracticeEmailTitle,
                  rules: [{
                    message: 'New Practice email subject line is required.',
                    required: true,
                  }],
                })(<Input />)}
              </FormItem>
              <FormItem label="New User Message Body: (1000 character limit)" style={{marginBottom: '0.5rem'}}>
                {getFieldDecorator('newPracticeEmailBody', {
                  initialValue: this.state.newPracticeEmailBody,
                  rules: [{
                    message: 'New Practice email body is required.',
                    required: true,
                  }],
                })(<TextArea rows={10} />)}
              </FormItem>
              <Alert type="info" message="Params: [[SYS_GENERATED_LINK]]" />
            </span>
            // show client and user email template button
          : <Link to={`/practices/mailer/edit/${this.props.practice.id}`} style={ {marginBottom: "2rem", display: "block"} }>
              Edit Practice Email Templates
            </Link>
          }
          <FormItem label="Notes">
            {getFieldDecorator('notes', {
              initialValue: this.props.practice ? this.props.practice.notes : null,
            })(<TextArea rows={4} />)}
          </FormItem>
          <div className="form-toolbar">
            {this.props.practice ?
              <EditButtons
                deleteRights={'deletePractice'}
                cancelUrl="/practices"
                loading={this.props.loading}
                onDelete={this.props.onDelete}
                onSubmit={this.handleSubmit}
                rights={['superAdmin','createPractice','editPractice']}
              /> :
              <AddButtons
                cancelUrl="/practices"
                createAnother={this.props.createAnother}
                loading={this.props.loading}
                onCreateAnother={this.props.onCreateAnother}
                onSubmit={this.handleSubmit}
                rights={['superAdmin','createPractice','editPractice']}
              />}
          </div>
        </Form>
      </FormWrapper>
    );
  }
}

PracticeForm.propTypes = {
  createAnother: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onCreateAnother: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  practice: PropTypes.shape({
    name: PropTypes.string.isRequired,
    notes: PropTypes.string,
    practiceId: PropTypes.string,
    siteLogo: PropTypes.string,
    siteLogoS3Token: PropTypes.string,
    status: PropTypes.oneOf(['Active', 'Inactive']).isRequired,
  }),
};

PracticeForm.defaultProps = {
  createAnother: false,
  onCreateAnother: null,
  onDelete: null,
  practice: null,
};

const uploadFileMutation = gql`
  mutation uploadFile($file: Upload!, $folder: String!) {
    uploadFile(file: $file, folder: $folder) {
      ok
      response {
        s3Token
        url
      }
    }
  }
`;

export default graphql(
  uploadFileMutation,
  { name: 'uploadFile' },
)(Form.create()(PracticeForm));
