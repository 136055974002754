import React from 'react';
import {Alert, AutoComplete, Badge, Checkbox, Col, Form, Icon, Input, notification, Row, Select, Spin} from 'antd';
import PropTypes from 'prop-types';
import { AddButtons, EditButtons, FormWrapper } from '../../../components/form';
import StateList from "../../../helpers/statesDataList";
import {SiteLogo} from "../../practices/components";
import {graphql} from "react-apollo";
import gql from 'graphql-tag';
import {Link} from "react-router-dom";

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

const slugGenerator = value => value
  .trim()
  .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, '')
  .replace(/\s{2,}/g, ' ')
  .replace(/[^a-zA-Z0-9]/g, '-')
  .replace(/_+$/, '')
  .toLowerCase();

class PortalsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      folder: null,
      practiceIdEdited: false,
      siteLogo: this.props.practice && this.props.practice.siteLogo
          ? this.props.practice.siteLogo
          : null,
      siteLogoS3Token: this.props.practice && this.props.practice.siteLogoS3Token
          ? this.props.practice.siteLogoS3Token
          : null,
      newPracticeEmailRecipient : '',
      newPracticeEmailTitle : 'Welcome',
      newPracticeEmailBody: `Hello,\n\nWe’re honored to have you as part of the community of financial professionals utilizing Unitifi!\n\n[[SYS_GENERATED_LINK]]\n\nOnce logged in, your dedicated Customer Success Representative will be in touch.\nWe're excited to help transform the authentic manner in which you connect with and help your clients succeed.\n\nThank you,\nUnitifi`,
      createPractice:false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadParams = this.handleUploadParams.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);

  }


  handleUploadParams(uploadParams) {
    const { file, folder } = uploadParams;

    this.setState({
      file,
      folder,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields( async(err, values) => {
      const slug = slugGenerator(values.name)
      if (!err) {
        const avatarUrl = this.state.file ? await this.uploadAvatar() : null;
          const toSubmit = {...values, slug,
              siteLogo: avatarUrl && avatarUrl.url ? avatarUrl.url : this.state.siteLogo,
              siteLogoS3Token: avatarUrl && avatarUrl.s3Token
              ? avatarUrl.s3Token
              : this.state.siteLogoS3Token,
          }
        this.props.onSubmit(toSubmit);
      }

    });
  }

  async uploadAvatar() {
    const upload = await this.props.uploadFile({
      variables: {
        file: this.state.file,
        folder: this.state.folder,
      },
    });

    const { ok } = upload.data.uploadFile;

    if (!ok) {
      notification.error({
        message: 'An Error Has Occurred',
        description: 'Our team has already been notified and will address the issue as quickly as possible.',
      });
    }

    const { s3Token, url } = upload.data.uploadFile.response;
    return { s3Token, url };
  }

  handleCreatePractice =()=>{
    this.setState({createPractice:!this.state.createPractice})
  }
  render() {
    
    const { getFieldDecorator ,getFieldValue} = this.props.form;
    const { loading } = this.props.form;
    const { gblUserRole } = this.props;
    
    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem
            label="Company Name"
          >
            {getFieldDecorator('name', {
              initialValue: this.props.portal ? this.props.portal.name : '',
              rules: [{
                message: 'A Company name is required.',
                required: true,
              }],
            })(<Input placeholder="Name" />)}
          </FormItem>
          <FormItem
            label="Company Description"
          >
            {getFieldDecorator('description', {
              initialValue: this.props.portal ? this.props.portal.description : '',
            })(<TextArea placeholder="Description ...." />)}
          </FormItem>
          <FormItem label="Street Address">
            {getFieldDecorator('primaryContactAddress', {
              initialValue: this.props.portal ? this.props.portal.primaryContactAddress: null,
            })(
                <Input placeholder="Address" />)}
          </FormItem>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Suite">
                {getFieldDecorator('primaryContactSuite', {
                  initialValue: this.props.portal ? this.props.portal.primaryContactSuite : null,
                })(
                    <Input placeholder="Suite" />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Postal Code">
                {getFieldDecorator('primaryContactZipCode', {
                  initialValue: this.props.portal ? this.props.portal.primaryContactZipCode : null,
                })(
                    <Input placeholder="Postal Code" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Country">
                {getFieldDecorator('primaryContactCountry', {
                  initialValue: this.props.portal ? this.props.portal.primaryContactCountry : null,
                })(
                    <Input placeholder="Country" />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="City">
                {getFieldDecorator('primaryContactCity', {
                  initialValue: this.props.portal ? this.props.portal.primaryContactCity : null,
                })(
                    <Input placeholder="City" />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem
                  className="input-xsmall"
                  label="State"
              >
                {getFieldDecorator('primaryContactState', {
                  initialValue: this.props.portal ? this.props.portal.primaryContactState : null
                })(<AutoComplete
                    placeholder="Select a State"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {(StateList || []).map(state => (
                      <AutoCompleteOption key={state.abbr} value={state.abbr}>{state.name}</AutoCompleteOption>
                  ))}
                </AutoComplete>)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem label="Contact Email">
                {getFieldDecorator('primaryContactEmail', {
                  initialValue: this.props.portal ? this.props.portal.primaryContactEmail : null,
                  rules: [{
                    message: 'A Contact Email is required.',
                    required: true,
                  }],
                })(<Input  readOnly={![1].includes(gblUserRole)}/>)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Contact First name">
                {getFieldDecorator('primaryContactFirstName', {
                  initialValue: this.props.portal ? this.props.portal.primaryContactFirstName : null,
                  rules: [{
                    message: 'A Contact First name is required.',
                    required: true,
                  }],
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Contact Last name">
                {getFieldDecorator('primaryContactLastName', {
                  initialValue: this.props.portal ? this.props.portal.primaryContactLastName : null,
                  rules: [{
                    message: 'A Contact Last name is required.',
                    required: true,
                  }],
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
          { /* ADDITIONAL INFO */ }

          {this.props.practice ?
              <FormItem className="input-small" label="Status">
                {getFieldDecorator('status', {
                  initialValue: this.props.portal.status,
                  rules: [{
                    message: 'A practice status is required.',
                    required: true,
                  }],
                })(
                    <Select placeholder="Status">
                      <Option value="Active">
                        <Badge status="success" text="Active" />
                      </Option>
                      <Option value="Inactive">
                        <Badge status="default" text="Inactive" />
                      </Option>
                    </Select>)}
              </FormItem> :
              null
          }
          <FormItem>
            <SiteLogo
                handleUploadParams={this.handleUploadParams}
                practice={this.props.portal ? this.props.portal : null}
            />
          </FormItem>
          <br/>
          {/*<Checkbox onChange={this.handleCreatePractice} checked={this.state.createPractice}>Yes, Create Practice with same Company Contact info and welcome message. </Checkbox>*/}
          <br/><br/>
              {this.state.createPractice?
              <span>
              <h3>Customize {getFieldValue('name')} Welcome Message</h3>
              <FormItem label="Subject Line">
                {getFieldDecorator('newPracticeEmailTitle', {
                  initialValue: this.state.newPracticeEmailTitle,
                  rules: [{
                    message: 'New Practice email subject line is required.',
                    required: true,
                  }],
                })(<Input />)}
              </FormItem>
              <FormItem label="New User Message Body: (1000 character limit)" style={{marginBottom: '0.5rem'}}>
                {getFieldDecorator('newPracticeEmailBody', {
                  initialValue: this.state.newPracticeEmailBody,
                  rules: [{
                    message: 'New Practice email body is required.',
                    required: true,
                  }],
                })(<TextArea rows={10} />)}
              </FormItem>
              <Alert type="info" message="Params: [[SYS_GENERATED_LINK]]" />

            <FormItem label="Notes">
              {getFieldDecorator('notes', {
                initialValue: this.props.practice ? this.props.practice.notes : null,
              })(<TextArea rows={4} />)}
            </FormItem>
             </span>
                : null }
          <div className="form-toolbar">
            { this.props.portal ?
              <EditButtons
                cancelUrl={this.props.cancelUrl}
                loading={this.props.loading}
                onDelete={this.props.gblUserIsGlobalAdmin? this.props.onDelete : null}
                onSubmit={this.handleSubmit}
                rights={['superAdmin','createPortalAdm','createPortalSup']}
                deleteRights={this.props.gblUserRole ? this.props.gblUserRole : ""}
              /> :
              <AddButtons
                cancelUrl={this.props.cancelUrl}
                createAnother={this.props.createAnother}
                loading={this.props.loading}
                onCreateAnother={this.props.onCreateAnother}
                onSubmit={this.handleSubmit}
                rights={['superAdmin','createPortalAdm','createPortalSup']}
              />}
          </div>
        </Form>
      </FormWrapper>
    );
  }
}

PortalsForm.propTypes = {
  createAnother: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onCreateAnother: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

PortalsForm.defaultProps = {
  createAnother: false,
  onCreateAnother: null,
  onDelete: null,
};

const uploadFileMutation = gql`
  mutation uploadFile($file: Upload!, $folder: String!) {
    uploadFile(file: $file, folder: $folder) {
      ok
      response {
        s3Token
        url
      }
    }
  }
`;

export default graphql(
    uploadFileMutation,
    { name: 'uploadFile' },
)(Form.create()(PortalsForm));


//export default (Form.create()(PortalsForm));
