
import {AddButtons, EditButtons, FormWrapper} from '../../../../components/form';
import StateList from '../../../../helpers/statesDataList';
import {Divider, Row, Col, AutoComplete, Select, Form, Input, Button} from 'antd';
import React, {useContext, useEffect} from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { notification } from 'antd';

const FormItem = Form.Item;
const InputGroup = Input.Group;
const AutoCompleteOption = AutoComplete.Option;

function FullSignupForm (props) {
    const { state, form, setState, pages, practiceId, userId } = props
    const { getFieldDecorator } = form;

    const [addClient, {data, loading:loadingMutate}] = useMutation(gql`
        mutation createClient(
            $address: String
            $advisorId: Int
            $city: String
            $email: String!
            $firstName: String
            $lastName: String
            $phoneNumber: String
            $practiceId: String
            $state: String
            $zipCode: String
            $addClientToTeam: Boolean
        ) {
            createClient(
            address: $address
            advisorId: $advisorId
            city: $city
            email: $email
            firstName: $firstName
            lastName: $lastName
            phoneNumber: $phoneNumber
            practiceId: $practiceId
            state: $state
            zipCode: $zipCode
            addClientToTeam:$addClientToTeam
            ) {
            ok
            client {
                id
                firstName
                lastName
            }
            errors {
                message
                path
            }
            }
        }
    `,
    {
        onCompleted : (data) => {

            if (data.createClient.ok && !data.createClient.errors) {
                const { createClient } = data
                const { client, assessment, advisorClient } = createClient
                setState({...state,
                    client: {
                        ...client
                    }
                })
                
                var thisPage = pages
                thisPage[0].status = true
                props.setPages(thisPage)
                props.setActivePage('assessment')

            }
            else {
                handleError(data.createClient.errors)
            }
        }
    })

    const handleError = (errors) => {
        errors.forEach((err) => {
          notification.error({
            message: 'Error',
            description: err.message,
          });
        });
    }
    
    const  handleSubmit = (event) =>{
        event.preventDefault();

        props.form.validateFields((err, values) => {
            if (!err) {
                
                const variables = {
                ...values,
                phoneNumber: `${values.p1}${values.p2}${values.p3}`,
                practiceId,
                addClientToTeam: false,
                advisorId: parseInt(userId),
                email: state.email
                };
                
                addClient({
                    variables
                })
                // props.onSubmit(values);
            }
        });
    }

    const back = () => {
        props.setActivePage('signup')
        props.setState({email:''})
    }

    return (
        <Row>
        <Col span={24} >
        <FormWrapper>
            <Form layout="vertical" >
            <Row>
                <Col span={24} >
                    <FormItem
                        label="First Name"
                        style={{marginBottom:0}}
                    >
                        {getFieldDecorator('firstName', {
                            initialValue: props.client ? props.client.advisorId : undefined,
                            rules: [{
                                message: 'First name is required.',
                                required: true,
                            }],
                        })(
                            <Input size={'large'}
                            placeholder="First Name"
                            />)}
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        label="Last Name"
                        style={{marginBottom:0}}
                    >
                        {getFieldDecorator('lastName', {
                            initialValue: props.client ? props.client.advisorId : undefined,
                            rules: [{
                                message: 'Last name is required.',
                                required: true,
                            }],
                        })(
                            <Input size={'large'}
                            placeholder="Last Name"
                            />)}
                    </FormItem>
                </Col>
            </Row>
                
            <Row style={{marginTop:10}}>
                <Col span={24} >
                    <FormItem
                        label="Address"
                        style={{marginBottom:0}}
                    >
                        {getFieldDecorator('address', {
                            initialValue: props.client ? props.client.advisorId : undefined,
                            rules: [{
                                message: 'Address is required.',
                                required: true,
                            }],
                        })(
                            <Input size={'large'}
                            placeholder="Address"
                            />)}
                    </FormItem>
                </Col>
            </Row>

            <Row style={{marginTop:10}}>
                <Col span={11} >
                    <FormItem
                        label="State"
                        style={{marginBottom:0}}
                    >
                        {getFieldDecorator('state', {
                            initialValue: props.user && props.user.state ? props.user.state : undefined,
                        })(<AutoComplete
                            size={'large'}
                            placeholder="Select a State"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {(StateList || []).map(state => (
                                <AutoCompleteOption key={state.abbr} value={state.abbr}>{state.name}</AutoCompleteOption>
                            ))}
                        </AutoComplete>)}
                    </FormItem>
                </Col>
                <Col offset={2} span={11}>
                    <FormItem
                        label="City"
                        style={{marginBottom:0}}
                    >
                        {getFieldDecorator('city', {
                            initialValue: props.client ? props.client.advisorId : undefined,
                            rules: [{
                                message: 'City is required.',
                                required: true,
                            }],
                        })(
                            <Input size={'large'}
                            placeholder="City"
                            />)}
                    </FormItem>
                </Col>
            </Row>

            <Row style={{marginTop:10}}>
                <Col span={11} >
                    <FormItem
                        className="input-phone__wrapper"
                        label="Phone Number"
                        style={{marginBottom:0}}
                    >
                       <InputGroup>{getFieldDecorator('phoneNumber', {
                            initialValue: props.client ? props.client.phoneNumber : null,
                        })(
                            <div className="input-phone__input">
                                {getFieldDecorator('p1', {
                                    initialValue: props.client && props.client.phoneNumber ? props.client.phoneNumber.substring(0, 3) : null,
                                })(<Input size={'large'} maxLength={3} placeholder="867" />)}
                                <span>&#8211;</span>
                                {getFieldDecorator('p2', {
                                    initialValue: props.client && props.client.phoneNumber ? props.client.phoneNumber.substring(3, 6) : null,
                                })(<Input size={'large'} maxLength={3} placeholder="530" />)}
                                <span>&#8211;</span>
                                {getFieldDecorator('p3', {
                                    initialValue: props.client && props.client.phoneNumber ? props.client.phoneNumber.substring(6, 10) : null,
                                })(<Input size={'large'} maxLength={4} placeholder="9999" />)}
                            </div>)}
                        </InputGroup>
                    </FormItem>
                </Col>
                <Col offset={2} span={11}>
                    <FormItem
                        label="Zip Code"
                        style={{marginBottom:0}}
                    >
                        {getFieldDecorator('zipCode', {
                            initialValue: props.client ? props.client.advisorId : undefined,
                            rules: [{
                                message: 'Zipcode is required.',
                                required: true,
                            }],
                        })(
                            <Input size={'large'}
                            placeholder="Zip Code"
                            />)}
                    </FormItem>
                </Col>
            </Row>

            <Divider />
            <Row style={{marginTop:10}} style={{marginTop:20}}>
            <Button type="default"  size={'large'} style={{marginRight:10}} onClick={back} loading={loadingMutate}>
                Back
            </Button>
            <Button type="primary"  size={'large'} onClick={handleSubmit} loading={loadingMutate}>
                Proceed
            </Button>
            </Row>
            </Form>
        </FormWrapper>
        </Col>
        </Row>
    )
}

export default Form.create()(FullSignupForm);
