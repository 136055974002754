import React from 'react';
import { Alert, Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { FormWrapper } from '../../../components/form';

const FormItem = Form.Item;
const { TextArea } = Input;

// const cleanPracticeId = value => value
//   .trim()
//   .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, '')
//   .replace(/\s{2,}/g, ' ')
//   .replace(/[^a-zA-Z0-9]/g, '-')
//   .replace(/_+$/, '')
//   .toLowerCase();

class PracticeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      folder: null,
      practiceIdEdited: false,
      siteLogo: this.props.practice && this.props.practice.siteLogo
        ? this.props.practice.siteLogo
        : null,
      siteLogoS3Token: this.props.practice && this.props.practice.siteLogoS3Token
        ? this.props.practice.siteLogoS3Token
        : null,
      clientAssessmentTitle: 'Your future with us',
      clientAssessmentBody: `Hello [[CLIENT_FIRST_NAME]],\n\nAt [[PRACTICE_NAME]], we fully understand that as human beings, we each have unique values, goals and perspectives. We know that you are more than just a set of numbers based on your age, appetite for risk and economic status.\nThe assessment which you are about to take will not only help us understand your unique tolerance for risk, but it will also help us to see HOW you go about making financial decisions. This will aid us in understanding your desired communication style as well as guiding us toward portfolios that will be personalized for your goals and comfort level.\n\n[[SYS_GENERATED_LINK]]\n\nThank you in advance for taking the time to help us understand who you are as an individual. We hope you find the assessment beneficial!\n\nSincerely,\n[[ADVISOR_FIRST_NAME]][[ADVISOR_LAST_NAME]]`,
      userInviteTitle: `Welcome - Please Setup Your Account`,
      userInviteBody: `Hello [[USER_FIRST_NAME]],\n\nWe’re honored to have you as part of the community of financial professionals utilizing Unitifi!\n\n[[SYS_GENERATED_LINK]]\n\nOnce logged in, your dedicated Customer Success Representative will be in touch.\nWe're excited to help transform the authentic manner in which you connect with and help your clients succeed.\n\nThank you,\n[[PRACTICE_NAME]]`
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.toggleLoading(true);

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        this.props.toggleLoading(false);
      }

      if (!err) {
        this.props.onSubmit({
          ...values,
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <h2>
            {this.props.practice.name}
          </h2>
          <br />
          <h3>Client Assessment Message </h3>
          <FormItem label="Subject Line">
            {getFieldDecorator('clientAssessmentTitle', {
              initialValue: this.props.practice.clientAssessmentTitle ? this.props.practice.clientAssessmentTitle : this.state.clientAssessmentTitle,
            })(<Input/>)}
          </FormItem>
          <FormItem label="Assessment Invite Body: (1000 character limit)" style={{marginBottom: '0.5rem'}}>
            {getFieldDecorator('clientAssessmentBody', {
              initialValue: this.props.practice.clientAssessmentBody ? this.props.practice.clientAssessmentBody : this.state.clientAssessmentBody,
            })(<TextArea rows={10} />)}
          </FormItem>
          <Alert type="info" message="Params: [[CLIENT_FIRST_NAME]], [[PRACTICE_NAME]], [[SYS_GENERATED_LINK]], [[ADVISOR_FIRST_NAME]], [[ADVISOR_LAST_NAME]]" />

          <hr />
          <br />
          <h3>Practice User Message</h3>
          <FormItem label="Subject Line">
            {getFieldDecorator('userInviteTitle', {
              initialValue: this.props.practice.userInviteTitle ? this.props.practice.userInviteTitle : this.state.userInviteTitle,
            })(<Input />)}
          </FormItem>
          <FormItem label="New User Message Body: (1000 character limit)" style={{marginBottom: '0.5rem'}}>
            {getFieldDecorator('userInviteBody', {
              initialValue: this.props.practice.userInviteBody ? this.props.practice.userInviteBody : this.state.userInviteBody,
            })(<TextArea rows={10} />)}
          </FormItem>
          <Alert type="info" message="Params: [[USER_FIRST_NAME]], [[SYS_GENERATED_LINK]], [[PRACTICE_NAME]]" />

          <br />
          <div className="form-toolbar">
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={this.handleSubmit}
             >
             Save Templates
             </Button>
          </div>
        </Form>
      </FormWrapper>
    );
  }
}

PracticeForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

PracticeForm.defaultProps = {
  practice: null,
};

export default Form.create()(PracticeForm);
// export default graphql(
//   uploadFileMutation,
//   { name: 'uploadFile' },
// )(Form.create()(PracticeForm));
