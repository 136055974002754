import { Form , Input , Select , AutoComplete, Icon, Result, Typography, Row, Col, message} from "antd";
import gql from "graphql-tag";
import React from "react";
import { Titlebar } from "../../components";
import CustomInput from "../../components/customInput";
import { EditButtons, FormWrapper } from "../../components/form";
import { graphql } from 'react-apollo';
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from '../../layouts/app/layoutContent';
import StateList from "../../helpers/statesDataList";
import { Divider } from "rc-menu";
import TextArea from "antd/lib/input/TextArea";
import _, { flowRight as compose } from 'lodash';

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />
const {Title, Paragraph} = Typography

const ProfileForm = (props) => {
  const {form, practice: {loading:portalLoad,getUserPractice,refetch},update} = props
  const { getFieldProps, getFieldDecorator } = form;

  const handleSubmit =  (e) => {
      e.preventDefault();	
      form.validateFields(async(err, values) => {
        if (!err) {
          await update({	
            variables: { ...values },	
          }).then(function(result){
            message.success("Successfully updated.")
            refetch()
          })	
        }
        else {
  
        }
      })
  }
  
  return <FormWrapper>
      <Typography>
      <Title level={4} className={'form-title'}>Company details</Title>
      <Paragraph>
        This information is presented to your clients.
      </Paragraph>
      </Typography>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <FormItem
          label="Company Name"
          validateStatus="validating"
          hasFeedback={portalLoad}
        >
          {getFieldDecorator('name', {
            initialValue: getUserPractice ? getUserPractice.name : "",
            rules: [{
              message: 'A user company is required.',
              required: true,
            }],
          })(<Input placeholder="company" />)}
        </FormItem>
          { /* ADDITIONAL INFO */ }
          <FormItem label="Street Address">
            {getFieldDecorator('primaryContactAddress', {
              initialValue: getUserPractice.primaryContactAddress,
            })(
              <Input placeholder="Address" />)}
          </FormItem>
          <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Suite">
                  {getFieldDecorator('primaryContactSuite', {
                    initialValue: getUserPractice.primaryContactSuite ,
                  })(
                    <Input placeholder="Suite" />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Postal Code">
                  {getFieldDecorator('primaryContactZipCode', {
                    initialValue: getUserPractice.primaryContactZipCode ,
                  })(
                    <Input placeholder="Postal Code" />)}
                </FormItem>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Country">
                  {getFieldDecorator('primaryContactCountry', {
                    initialValue: getUserPractice.primaryContactCountry ,
                  })(
                    <Input placeholder="Country" />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="City">
                  {getFieldDecorator('primaryContactCity', {
                    initialValue: getUserPractice.primaryContactCity ,
                  })(
                    <Input placeholder="City" />)}
                </FormItem>
              </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem
                className="input-xsmall"
                label="State"
              >
                {getFieldDecorator('primaryContactState', {
                  initialValue: getUserPractice.primaryContactState 
                })(<AutoComplete
                  placeholder="Select a State"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {(StateList || []).map(state => (
                    <AutoCompleteOption key={state.abbr} value={state.abbr}>{state.name}</AutoCompleteOption>
                  ))}
                </AutoComplete>)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem label="Contact Email">
                {getFieldDecorator('primaryContactEmail', {
                  initialValue: getUserPractice.email ,
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Contact First name">
                {getFieldDecorator('primaryContactFirstName', {
                  initialValue: getUserPractice.primaryContactFirstName ,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Contact Last name">
                {getFieldDecorator('primaryContactLastName', {
                  initialValue: getUserPractice.primaryContactLastName ,
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        <div className="form-toolbar">
            <EditButtons
              cancelUrl={null}
              loading={portalLoad}
              onSubmit={handleSubmit}
              rights={['superAdmin','editPractice']}
              deleteRights={'NoDelete'}
            />
        </div>
        </Form>
      </FormWrapper>

}

const ProfilePage = (props) => {
    const {form, practice: {loading}} = props
    const { getFieldProps, getFieldDecorator } = form;


 
      return <div>
        <Titlebar
          displayText="General"
        />
        <LayoutContentWrapper>
          <LayoutContent >
            {(loading && 
              <Result
                icon={antIcon}
                title="Loading ..."
              />
            )}
            {!loading && 
              <ProfileForm {...props} />
            }
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    // return 
    
}




const practiceQuery = gql`
  query {
    getUserPractice {
      id:
      portalId
      company
      cslug
      address
      billingAddress
      billingCity
      billingEmail
      billingName
      billingPhoneNumber
      billingState
      billingZipCode
      city
      email
      maxAdminCount
      maxAdvisorCount
      maxClientCount
      name
      notes
      phoneNumber
      practiceId
      primaryContactAddress
      primaryContactCity
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      primaryContactPhoneNumber
      primaryContactState
      primaryContactZipCode
      primaryContactSuite
      primaryContactCountry
      siteLogo
      siteLogoS3Token
      sitePrimaryColor
      siteSecondaryColor
      state
      status
      surveyId
      zipCode
      clientAssessmentTitle
      clientAssessmentBody
      userInviteTitle
      userInviteBody
      newPracticeEmailRecipient
      newPracticeEmailTitle
      newPracticeEmailBody
    }
  }
`
;


const updateGeneral = gql`
  mutation(
    $address: String
    $billingAddress: String
    $billingCity: String
    $billingEmail: String
    $billingName: String
    $billingPhoneNumber: String
    $billingState: String
    $billingZipCode: String
    $city: String
    $email: String
    $maxAdminCount: Int
    $maxAdvisorCount: Int
    $maxClientCount: Int
    $name: String
    $notes: String
    $phoneNumber: String
    $practiceId: String
    $primaryContactAddress: String
    $primaryContactCity: String
    $primaryContactEmail: String
    $primaryContactFirstName: String
    $primaryContactLastName: String
    $primaryContactPhoneNumber: String
    $primaryContactState: String
    $primaryContactZipCode: String
    $primaryContactCountry: String
    $primaryContactSuite: String
    $siteLogo: String
    $siteLogoS3Token: String
    $sitePrimaryColor: String
    $siteSecondaryColor: String
    $state: String
    $zipCode: String
  ){
    editGeneral(
      address:$address
      billingAddress:$billingAddress
      billingCity:$billingCity
      billingEmail:$billingEmail
      billingName:$billingName
      billingPhoneNumber:$billingPhoneNumber
      billingState:$billingState
      billingZipCode:$billingZipCode
      city:$city
      email:$email
      maxAdminCount:$maxAdminCount
      maxAdvisorCount:$maxAdvisorCount
      maxClientCount:$maxClientCount
      name:$name
      notes:$notes
      phoneNumber:$phoneNumber
      practiceId:$practiceId
      primaryContactAddress:$primaryContactAddress
      primaryContactCity:$primaryContactCity
      primaryContactEmail:$primaryContactEmail
      primaryContactFirstName:$primaryContactFirstName
      primaryContactLastName:$primaryContactLastName
      primaryContactPhoneNumber:$primaryContactPhoneNumber
      primaryContactState:$primaryContactState
      primaryContactZipCode:$primaryContactZipCode
      primaryContactCountry:$primaryContactCountry
      primaryContactSuite:$primaryContactSuite
      siteLogo:$siteLogo
      siteLogoS3Token:$siteLogoS3Token
      sitePrimaryColor:$sitePrimaryColor
      siteSecondaryColor:$siteSecondaryColor
      state:$state
      zipCode:$zipCode
    ){
      ok
    }
  }
`
;

export default compose(
  graphql(practiceQuery,{
    name:'practice',
  }),
  graphql(updateGeneral,{
    name:'update',
  })
)(Form.create()(ProfilePage));