export default (decisionMakingStyle, plScore) => {
  // convert plScore to scale
  const x = plScore >= 1
    ? (100 - plScore) // logical
    : ((plScore * -1) + 100); // passionate

  // default to 'Balanced' decision making style
  let obj = {
    fill: '#1072b7',
    x,
    y: 5,
  };

  if (decisionMakingStyle === 'Highly Passionate') {
    obj = {
      fill: '#589bcb',
      x,
      y: 5,
    };
  }

  if (decisionMakingStyle === 'Moderately Passionate') {
    obj = {
      fill: '#589bcb',
      x,
      y: 5,
    };
  }

  if (decisionMakingStyle === 'Somewhat Passionate') {
    obj = {
      fill: '#589bcb',
      x,
      y: 5,
    };
  }

  if (decisionMakingStyle === 'Somewhat Logical') {
    obj = {
      fill: '#054471',
      x,
      y: 5,
    };
  }

  if (decisionMakingStyle === 'Moderately Logical') {
    obj = {
      fill: '#054471',
      x,
      y: 5,
    };
  }

  if (decisionMakingStyle === 'Highly Logical') {
    obj = {
      fill: '#054471',
      x,
      y: 5,
    };
  }

  return obj;
};
