
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Radio, Divider, Form, Input, Slider, Select, Typography, Col, Row} from 'antd';
import _, { round } from 'lodash';

const { Title } = Typography;
const FormItem = Form.Item;
const { Option } = Select
const validation = {
    email : [{
            type: 'email',
            message: 'The input is not valid E-mail!',
        },
        {
            required: true,
            message: 'Please input your E-mail!',
        }]
}

const StepsQuestions = ({question,items,...props}) => {
    const { form, setQuestion } = props
    const {  getFieldDecorator } = form;

    const [state,setState] = useState({
        type:"",
        start:0,
        min:0,
        max:100,
        slider:{},
        visible:true,

    })

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    useEffect(()=>{
        if(items.type === 'Slider'){
            convertMarks(items)
        }
        else{ 

            if(items.condition !== null && items.condition !== undefined){

                items.condition.map((cond,i)=>{
                    const ind = _.findIndex(question.list, function(o) { return o.id == cond.id })
                    if(cond.variable === question.list[ind].answer){
                        items.visible = false
                    }
                    else {
                        items.visible = true
                    }

                })
            }
            setState(items)
        }
    },[items,question])


    const convertMarks = (items) => {
        var { config } = items
        var newObj = {}
        var start = 0
        var min = 0
        var max = 0
        var labels = []
        config.map((items)=>{
            if(items.start){
                start = items.start
            }

            if(items.min){
                min = items.min
            }

            if(items.max){
                max = items.max
            }

            if(items.labels){
                labels = items.labels
            }
        })

        const arr = []
        
        labels.map((items,i)=>{
            if(items.left){
                newObj[min] = {
                    label: items.left,
                    style: {
                        left:'5%',
                    },
                }
                
            }

            if(items.center){
                newObj[start] = items.center
            }

            if(items.right){
                newObj[max] = {
                    label: items.right,
                    style: {
                        width:'100px',
                    },
                }
            }
        })
        
        items.start = start
        items.min = min
        items.max = max
        items.slider = newObj

        setState(items)
    }

    const selectOptions = (config) => {
        var options = []
        var start = 0
        var min = 0
        var max = 0
        var labels = []
        config.map((items)=>{
            if(items.min){
                min = items.min
            }

            if(items.max){
                max = items.max
            }

        })

        for(var x = min ; x<= max ; x++){
            options.push(<Option key={x} value={x}>{x}</Option>)
        }
        return options
    }

    const onChangeRad = (e) => {
        if(state.id === 6){
            const ind = _.findIndex(question.list, function(o) { return o.id == state.id })
            question.list[ind].answer = e.target.value
            setQuestion({...question,list:question.list})
        }
    }

    return <>
    {((state.type === 'Radio') && (
                <FormItem
                    label={state.name}
                    style={{marginBottom:0}}
                    
                >
                {getFieldDecorator(`${state.id}`, {
                    initialValue : (state.answer !== undefined) ? state.answer : '',
                    rules: [{
                        required: state.required,
                        message: 'This question is required. !' 
                    }],
                })(
                    <Radio.Group onChange={onChangeRad}>
                        {
                            state.options.map((items,i) =>
                                <Radio key={i} style={radioStyle} value={items.value}>
                                    {items.text}
                                </Radio>
                            )
                        }
                    </Radio.Group>
                )}
                </FormItem>
    ))}

    {((state.type === 'Text' && !state.visible) && (

                <FormItem
                    label={state.name}
                    style={{marginBottom:0}}
                    hasFeedback
                >
                {getFieldDecorator(`${state.id}`, {
                    initialValue : (state.answer !== undefined) ? state.answer : '',
                    rules: (state.validate === "Email") ? validation.email : [{
                        required: state.required,
                        message: 'This question is required. !' 
                    }],
                    
                })(
                    <Input style={{width:'400px'}}/>
                )}
                </FormItem>
            
    ))}

    {((state.type === 'Slider') && (
                <div  style={{width:700}}>
                <FormItem 
                    label={state.name}
                >
                    {getFieldDecorator(`${state.id}`,{
                        initialValue : (state.answer !== undefined) ? state.answer : state.start,
                        rules: [{
                            required: state.required,
                            message: 'This question is required. !' 
                        }],
                    })(
                        <Slider marks={state.slider} min={state.min} max={state.max}/>
                    )}
                </FormItem>
                </div>
            
    ))}

    {((state.type === 'Select') && (
                <Form.Item label={state.name} hasFeedback>
                    {getFieldDecorator(`${state.id}`,{
                        initialValue : (state.answer !== undefined) ? state.answer : '',
                        rules: [{
                            required: state.required,
                            message: 'This question is required. !' 
                        }],
                    })(
                    <Select placeholder="Please select a country" style={{width:'400px'}}>
                        {
                            selectOptions(state.config).map((items)=>{
                                return items
                            })
                        }
                    </Select>,
                    )}
                </Form.Item>
            
    ))}

    </>
}

export default StepsQuestions;