import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PortalsAdd, PortalsEdit, PortalsList, PortalsView } from '../../../containers/portals';

class PortalRoutes extends React.Component {
  render() {
    const { gblUserId, gblUserIsPortalAdmin, gblUserIsGlobalAdmin, gblUserRole } = this.props;

    return (
      <Switch>
        <Redirect
          exact
          from="/companies/"
          to="/companies"
          strict
        />
        <Redirect
          exact
          from="/companies/add/"
          to="/companies/add"
          strict
        />
        <Redirect
          exact
          from="/companies/:slug/edit/"
          to="/companies/:slug/edit"
          strict
        />
        <Redirect
          exact
          from="/companies/:slug/"
          to="/companies/:slug"
          strict
        />

        <Redirect
          exact
          from="/companies/:slug/"
          to="/companies/:slug"
          strict
        />
        <Route
          exact
          path="/companies"
          render={props => (
            <PortalsList
              {...props}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/companies/add"
          render={props => (
            <PortalsAdd
              {...props}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/companies/:slug/edit"
          render={props => (
            <PortalsEdit
              {...props}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/companies/:slug"
          render={props => (
            <PortalsView
              {...props}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
      </Switch>
    );
  }
}

export default PortalRoutes;
