import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  CircularGridLines,
  XYPlot,
  Hint,
  HorizontalGridLines,
  MarkSeries,
  VerticalGridLines,
} from 'react-vis';
import { isEqual } from 'lodash';
import { SizeMe } from 'react-sizeme';
import TotalClientMapWrapper from './index.style';
import '../../../node_modules/react-vis/dist/style.css';

// style for hint popup
const tipStyle = {
  background: 'rgba(255,255,255,1)',
  border: '1px solid rgba(245,245,245,1)',
  borderRadius: '2px',
  boxShadow: '-3px 16px 30px 0 rgba(134,134,123,0.16)',
  color: 'rgba(0,0,0,.65)',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
};


class TotalClientMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredClient: false,
      redirectTo: null,
    };

    this.onValueClick = this.onValueClick.bind(this);
    this.onValueMouseOut = this.onValueMouseOut.bind(this);
    this.onValueMouseOver = this.onValueMouseOver.bind(this);
  }

  onValueClick({ id, practiceName }) {
    localStorage.setItem('current_practice',practiceName);localStorage.setItem('current_practice_id',id)
    this.setState({
      redirectTo: `/${practiceName}/clients/view/${id}`,
    });
  }

  onValueMouseOut() {
    this.setState({ hoveredClient: false });
  }

  onValueMouseOver(value) {
    const { hoveredClient } = this.state;

    return !isEqual(value, hoveredClient)
      ? this.setState({ hoveredClient: value })
      : false;
  }

  render() {
    const { data } = this.props;
    const { hoveredClient } = this.state;

    // chart config
    const chartHeight = 500;
    const chartMargin = {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    };

    // map client data for chart
    const insights = data.map(d => d);
    const chartData = [];
    insights.forEach((i) => {
      chartData.push({
        id: i.id,
        color: 'rgba(0,111,186,1)',
        currentRetirementVehicle: i.insights.profile.currentRetirementVehicle,
        name: `${i.firstName} ${i.lastName}`,
        practice: i.practice,
        practiceName: i.practiceName,
        x: i.insights.score.riskBehaviorScore,
        y: i.insights.score.plScore,
      });
    });

    return (
      <TotalClientMapWrapper>
        <div className="top-wrapper">
          <div className="top-bar">
            <div>Conservative</div>
            <div>Aggressive</div>
          </div>
          <div className="bottom-bar">
            <div className="bottom-item">
              <div className="inner">
                <div>Protector</div>
                <div>Observer</div>
              </div>
            </div>
            <div className="bottom-item">
              <div className="inner">
                <div>Liberator</div>
                <div>Energizer</div>
              </div>
            </div>
          </div>
        </div>
        <div className="chart-wrapper">
          <SizeMe>
            {({ size }) => (
              <div className="chart">
                <XYPlot
                  height={chartHeight}
                  width={size.width}
                  margin={chartMargin}
                  xDomain={[0, 100]}
                  yDomain={[-100, 100]}
                >
                  <CircularGridLines
                    centerX={50}
                    tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
                  />
                  <VerticalGridLines
                    style={{ strokeDasharray: '4' }}
                    tickValues={[25, 50, 75]}
                  />
                  <HorizontalGridLines
                    style={{ strokeDasharray: '4' }}
                    tickValues={[0]}
                  />
                  <MarkSeries
                    animation
                    colorType="literal"
                    data={chartData}
                    onValueClick={this.onValueClick}
                    onValueMouseOut={this.onValueMouseOut}
                    onValueMouseOver={this.onValueMouseOver}
                    sizeRange={[5, 15]}
                    strokeWidth={0}
                    style={{ cursor: 'pointer' }}
                  />
                  {hoveredClient ? (
                    <Hint value={hoveredClient}>
                      <div style={tipStyle}>
                        <div style={{ fontWeight: '600' }}>{hoveredClient.name}</div>
                        <div>{hoveredClient.practice}</div>
                        <div>{hoveredClient.currentRetirementVehicle}</div>
                      </div>
                    </Hint>) : null}
                </XYPlot>
              </div>
            )}
          </SizeMe>
          <div className="label-wrapper">
            <div><span>Logical</span></div>
            <div><span>Passionate</span></div>
          </div>
        </div>
        <div className="bottom-wrapper">
          <div className="item">Conservative</div>
          <div className="item">Moderately Conservative</div>
          <div className="item">Moderate</div>
          <div className="item">Moderately Aggressive</div>
          <div className="item">Aggressive</div>
        </div>
        {this.state.redirectTo !== null && (
          <Redirect push to={this.state.redirectTo} />
        )}
      </TotalClientMapWrapper>
    );
  }
}

export default TotalClientMap;
